app.controller('TarjetaExtractoCtrl', ['$scope', 'MainFactory', '$state', '$sessionStorage', '$localStorage', '$rootScope', '$location', 'NgTableParams', '$filter',
    function ($scope, MainFactory, $state, $sessionStorage, $localStorage, $rootScope, $location, NgTableParams, $filter) {

        $localStorage.menuActive = 1;
        $scope.tarjetaExtracto;
        $scope.selected = {};
        $scope.selected.tarjetaCabecera = $localStorage.nroTarjeta;
        $scope.fechas = generarFechas();
        //$scope.selected = {};
        $scope.selected.fecha = $scope.fechas[0];

        $scope.cargarTabla = function () {

            parametros = {
                operacion: "74",
                producto: "18",
                entidad: "3",
                cliente: "1",
                presentador: "1",
                entrada: {
                    iIdSesion: $localStorage.identificadorSesion,
                    iTipExtracto: "TCR",
                    iNroProducto: $scope.selected.tarjetaCabecera.wbNumero,
                    anho: getAnhoExtracto($scope.selected.fecha.mes),
                    mes: getMesExtracto($scope.selected.fecha.mes),
                    iCantidad: 20,
                    iRegInicial: 0
                }
            };

            blockUI();
            MainFactory.doRequest(parametros)
                    .then(function (response) {

                        if (response.data.estado == 0) {

                            $scope.tarjetaExtracto = response.data.data.oMovExtracto;
                            $scope.cabecera = $scope.tarjetaExtracto[0];

                            $scope.tableParams = new NgTableParams({
                                page: 1,
                                count: 8,
                                sorting: {
                                    movFecOperacion: 'desc'
                                }
                            }, {
                                counts: [],
                                getData: function (params) {
                                    var data = $scope.tarjetaExtracto;
                                    data = params.filter() ? $filter('filter')(data, params.filter()) : data;
                                    data = params.orderBy() ? $filter('orderBy')(data, params.orderBy()) : data;
                                    params.total(data.length);
                                    data = data.slice((params.page() - 1) * params.count(), params.page() * params.count());
                                    return data;
                                }
                                /*counts:[],
                                 total: $scope.tarjetaExtracto.length,
                                 getData: function (params) {
                                 var orderedData = params.sorting() ? $filter('orderBy')($scope.tarjetaExtracto, params.orderBy()) : $scope.tarjetaExtracto;
                                 return (orderedData.slice((params.page() - 1) * params.count(), params.page() * params.count()));*/
                            });
                            unBlockUI();
                        } else {

                            showNotification('alerta', response.data.mensaje);
                            unBlockUI();
                        }
                    });

        };

        $scope.cargarTabla();

        $scope.update = function () {
            $scope.cargarTabla();
        };

        $scope.descargarPdf = function () {
            parametros = {
                operacion: "74",
                producto: "18",
                entidad: "3",
                cliente: "1",
                presentador: "1",
                entrada: {
                    iIdSesion: $localStorage.identificadorSesion,
                    iTipExtracto: "TCR",
                    iNroProducto: $scope.selected.tarjetaCabecera.wbNumero,
                    anho: getAnhoExtracto($scope.selected.fecha.mes),
                    mes: getMesExtracto($scope.selected.fecha.mes),
                    iCantidad: 20,
                    iRegInicial: 0
                }
            };

            blockUI();
            MainFactory.doRequest(parametros).then(function (response) {
                if (response.data.estado == 0 && response.data.data.oMovExtracto.length > 0) {
                    parametros = {
                        operacion: "92",
                        producto: "18",
                        entidad: "3",
                        cliente: "1",
                        presentador: "1",
                        entrada: {
                            iIdSesion: $localStorage.identificadorSesion,
                            iTrcNroTarjeta: response.data.data.oMovExtracto[0].nroUsuario,
                            iTrcFecha: getAnhoExtractoAA($scope.selected.fecha.mes).toString() + getMesExtractoMM($scope.selected.fecha.mes).toString()
                        }
                    };

                    MainFactory.doRequest(parametros).then(function (response) {
                        if (response.data.estado == 0) {
                            MainFactory.getPDFExtracto(response.data.data).then(function (response) {
                                unBlockUI();
                                if (response.data.estado == 0) {
                                    window.location.replace('/SwitcherSanCristobal-web/ExtractoDeTarjeta');
                                    unBlockUI();
                                } else {
                                    showNotification('alerta', response.data.mensaje);
                                    unBlockUI();
                                }
                            });

                        } else {
                            showNotification('alerta', response.data.mensaje);
                            unBlockUI();
                        }
                    });

                } else {
                    if (response.data.data.oMovExtracto.length == 0) {
                        showNotification('alerta', 'No dispone de extracto en el periodo solicitado');
                    } else {
                        showNotification('alerta', response.data.mensaje);
                    }
                    unBlockUI();
                }
            });
        };

    }]);