app.factory('MainFactory', ['$http', function ($http) {
    return {
        login: function (parametros) {
            return $http.post('/SwitcherSanCristobal-web/webresources/sesion', parametros);
        },
        activarCuenta: function (parametros) {
            return $http.post('/SwitcherSanCristobal-web/webresources/pin', parametros);
        },
        doRequest: function (parametros) {
            return $http.post('/SwitcherSanCristobal-web/webresources/productos', parametros);
        },
        getPdf: function (parametros) {
            return $http(parametros);
        },
        obtenerImagen: function () {
            return $http.get('/SwitcherSanCristobal-web/webresources/Images/carrusel');
        },
        obtenerImagenNavBar: function () {
            return $http.get('/SwitcherSanCristobal-web/webresources/Images/navbar');
        },
        obtenerImagenPopUp: function () {
            return $http.get('/SwitcherSanCristobal-web/webresources/Images/popUp');
        },
        getPDFExtracto: function (data) {
            return $http.post('/SwitcherSanCristobal-web/ExtractoDeTarjeta', data);
        },
        getParametro: function (data) {
            return $http.get('/SwitcherSanCristobal-web/webresources/altav2?tipo=' + data);
        },
        alta: function (data) {
            return $http.post('/SwitcherSanCristobal-web/webresources/altav2', data);
        },
        verifica: function (data) {
            return $http.post('/SwitcherSanCristobal-web/webresources/altav2/cedula', data);
        },
        verifyOtp: function () {
            return $http.get('/SwitcherSanCristobal-web/webresources/otp/make-challenge');
        },
        getValidacion: function (data) {
            return $http.get('/SwitcherSanCristobal-web/webresources/altav2/validar-admision?validarDocumento=' + data);
        },
        getDatosOtroSocio: function (idSesion, tipo, ciOtrSocio) {
            let obj = {
                operacion: "73",
                producto: "18",
                entidad: "3",
                cliente: "1",
                presentador: "1",
                entrada: {
                    iIdSesion: idSesion,
                    iTipSaldo: tipo,
                    iCiOtrSocio: ciOtrSocio
                }
            };
            return $http.post('/SwitcherSanCristobal-web/webresources/productos', obj);
        },
        getComprobanteSipap: function (data) {
            return $http.post('/SwitcherSanCristobal-web/ComprobanteSipap', data);
        },
        cargarDatosSocio: function (data) {
            return $http.post('/SwitcherSanCristobal-web/webresources/cargar-datos', data);
        },
        getDatosPadron: function () {
            return $http.get('/SwitcherSanCristobal-web/webresources/datos-socio');
        },
        obtenerBalance: function () {
            return $http.get('/SwitcherSanCristobal-web/webresources/memorias-balances', {responseType: 'arraybuffer'});
        },
        obtenerRuedasAhorro: function () {
            return $http.get('/SwitcherSanCristobal-web/webresources/ahorro-circulo/listar');
        },
        obtenerAhorroDisp: function (data) {
            return $http.post('/SwitcherSanCristobal-web/webresources/ahorro-circulo/obtener-ahorro-nro-disp', data);
        },
        crearAhorroCirculo: function (data) {
            return $http.post('/SwitcherSanCristobal-web/webresources/ahorro-circulo/crear', data);
        },
        getHabilitacionCirculo: function () {
            return $http.get('/SwitcherSanCristobal-web/webresources/ahorro-circulo/habilitacion-circulo');
        },
        getParametrosSimuladorAhorro: function () {
            return $http.get('/SwitcherSanCristobal-web/webresources/simulador-ahorros');
        },
        simuladorAhorros: function (json) {
            return $http.post('/SwitcherSanCristobal-web/webresources/simulador-ahorros', json);
        },
        conceptosManifestacionBienes: function (json) {
            return $http.post('/SwitcherSanCristobal-web/webresources/declaracion-bienes', json);
        },
        actualizarManifestacionBienes: function (json) {
            return $http.post('/SwitcherSanCristobal-web/webresources/declaracion-bienes/actualizar', json);
        },
        obtenerPlanesAhorroProgramado: function () {
            return $http.get('/SwitcherSanCristobal-web/webresources/ahorro-programado');
        },
        crearAhorroProgramado: function (data) {
            return $http.post('/SwitcherSanCristobal-web/webresources/ahorro-programado', data);
        },
        detalleAhorro: function (data) {
            return $http.post('/SwitcherSanCristobal-web/webresources/detalle-ahorro', data);
        },
        obtenerOtrosPendientes: function (){
            return $http.get('/SwitcherSanCristobal-web/webresources/otros-pagos');
        },
        pagarDeudaPendiente: function (data){
            return $http.post('/SwitcherSanCristobal-web/webresources/otros-pagos', data);
        }
    };
}]);

app.factory('MenuFactory', function () {
    return {
        active: function (opcion) {
            var opciones = [false, false, false, false, false];
            for (var i = 0; i < opciones.length; i++) {
                if (i == opcion) {
                    opciones[i] = true;
                } else {
                    opciones[i] = false;
                }
            }
            return opciones;
        }
    };
});

app.factory('PagoServicioFactory', ['$http', function ($http) {

    var paramsListar = {
        "tipoOrigen": "MOVIL"
    };

    return {
        listarServicio: function () {
            //return $http.post('/ProxyRedes-web/webresources/servicios/listar-servicios',paramsListar);
            return $http.post('/ProxyRedes-web/webresources/servicios/listar-servicios-categorizados', paramsListar);
        },
        parametrosServicio: function (obj) {
            var params = {
                "origen": {
                    "tipoOrigen": "MOVIL"
                },
                "servicio": {
                    "descripcion": obj.descripcion,
                    "codigoServicio": obj.codigoServicio,
                    "flgCategoria": obj.flgCategoria,
                    "idServicio": obj.idServicio
                }
            };
            return $http.post('/ProxyRedes-web/webresources/servicios/parametros-servicios', params);
        },
        facturas: function (obj1, obj2) {
            var params = {
                "origen": {
                    "tipoOrigen": "MOVIL"
                },
                "servicio": {
                    "descripcion": obj1.descripcion,
                    "codigoServicio": obj1.codigoServicio,
                    "flgCategoria": obj1.flgCategoria,
                    "idServicio": obj1.idServicio
                },
                "idRed": obj2.idRed,
                "datos": obj2.datos,
                "parametros": obj2.parametros
            };
            return $http.post('/ProxyRedes-web/webresources/servicios/facturas', params);
        },
        pagar: function (params) {
            return $http.post('/SwitcherSanCristobal-web/webresources/productos', params);
        }
    };
}]);


app.factory('SolicitudFactory', ['$http', function ($http) {
    return {
        listarSolicitudes: function () {
            return $http.get('/SwitcherSanCristobal-web/webresources/solicitudes');
        },
        listarParametrosSolicitud: function (idSolicitud) {
            return $http.get('/SwitcherSanCristobal-web/webresources/solicitudes/parametros?idSolicitud=' + idSolicitud);
        },
        realizarSolicitud: function (solicitud) {
            return $http.post('/SwitcherSanCristobal-web/webresources/solicitudes', solicitud);
        },
        solicitarImpresion: function (idSolicitud) {
            var parametros = {
                "idIdentificador": idSolicitud
            };
            return $http.post('/SwitcherSanCristobal-web/webresources/solicitud-impresion', parametros);
        },
        imprimirSolicitud: function (params) {
            return $http.post('/SwitcherSanCristobal-web/solicitid-impresion-servlet', params);
        },
    };
}]);


app.factory('TransferenciaExternaFactory', ['$http', function ($http) {
    return {
        enviar: function (datos) {
            return $http.post('/SwitcherSanCristobal-web/webresources/sipap/enviar', datos);
        },
        listarDocumentos: function () {
            return $http.get('/SwitcherSanCristobal-web/webresources/sipap/listar-documentos');
        },
        listarValores: function (id) {
            let obj = {
                "lista": id
            }
            return $http.post('/SwitcherSanCristobal-web/webresources/sipap/listar-valores', obj);
        },
        generarCodigo: function (idFactor) {
            let obj = {
                "idFactor": idFactor
            }
            return $http.post('/SwitcherSanCristobal-web/webresources/sipap/generar-pin', obj);
        },
        listarSipap: function (params) {
            let obj = {
                "fechaInicio": params.fechaInicio,
                "fechaFin": params.fechaFin,
                "cantidad": 100,
                "regInicial": 1
            }
            return $http.post('/SwitcherSanCristobal-web/webresources/sipap/listar-transferencias', obj);
        }
    };
}]);

app.factory('CargarDatosFactory', ['$http', function ($http) {
    return {
        cargarDatos: function (datos) {
            return $http.post('/SwitcherSanCristobal-web/webresources/cargar-datos', datos);
        }
    };
}]);

app.factory('ValidarConvenioFactory', ['$http', function ($http) {
    return {
        validarConvenio: function (datos) {
            return $http.post('/SwitcherSanCristobal-web/webresources/validar-convenio', datos);
        }
    };
}]);
