app.controller('OtrosPagosConfirmacionCtrl', ['$scope', 'MainFactory', '$state', '$sessionStorage', '$localStorage',
    function ($scope, MainFactory, $state, $sessionStorage, $localStorage) {

        $scope.confirmacion = $localStorage.datosConfirmacion;
        $scope.request = $localStorage.datosRequest;
        $scope.pin;
        $scope.tipo;

        unBlockUI();

        $scope.onSubmit = function (form) {
            blockUI();
            if (!form.$valid) {
                return;
            }

            if ($scope.tipo) {
                if ($scope.pin === '') {
                    showNotification('alerta', 'Ingrese su código de verificación');
                    unBlockUI();
                } else {
                    if ($scope.tipo === "PIN") {
                        $scope.realizarPago();
                    } else {
                        $scope.verificarOtp();
                    }
                }
            } else {
                showNotification('alerta', 'Seleccione un método de verificación');
                unBlockUI();
            }
        }

        $scope.setTipoVerificacion = function (tipo) {
            $scope.pin = '';
            $scope.tipo = tipo;
        }

        $scope.verificarOtp = function () {
            blockUI();
            MainFactory.verifyOtp().then(
                function (response) {
                    if (response.data.estado === 0) {
                        $scope.realizarPago();
                    } else {
                        unBlockUI();
                        showNotification('alerta', response.data.mensaje);
                    }
                },
                function (response) {
                    unBlockUI();
                    showNotification('alerta', "No se pudo realizar el pago");
                });
        }

        $scope.realizarPago = function () {
            let tipoAuth = {
                "tipo": $scope.tipo,
                "valor": $scope.pin
            };
            $scope.request.tipoAuth = tipoAuth;
            $scope.request.idSocio = $localStorage.usuario;

            MainFactory.pagarDeudaPendiente($scope.request).then(
                function (response) {
                    if (response.data.estado === 0) {
                        unBlockUI();
                        $localStorage.comprobante = response;
                        $state.go('app.pagos.otrosPagosComprobante');
                    } else {
                        unBlockUI();
                        $state.go('app.pagos.otrosPagos');
                        showNotification('alerta', response.data.mensaje);
                    }
                },
                function (response) {
                    unBlockUI();
                    showNotification('alerta', "No se pudo realizar la operacion");
                });
        };

        $scope.cancelar = function () {
            $state.go('app.pagos.otrosPagos');
        }

    }]);
