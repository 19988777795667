app.controller('AporteCtrl', ['$scope', 'MainFactory', '$state', '$sessionStorage', '$localStorage', '$rootScope', '$location', 'NgTableParams', '$filter', 'MenuFactory',
    function ($scope, MainFactory, $state, $sessionStorage, $localStorage, $rootScope, $location, NgTableParams, $filter, MenuFactory) {

        $scope.aportes;
        $localStorage.menuActive = 1;

        parametros = {
            operacion: "73",
            producto: "18",
            entidad: "3",
            cliente: "1",
            presentador: "1",
            entrada: {
                iIdSesion: $localStorage.identificadorSesion,
                iTipSaldo: "APO"
            }
        };

        blockUI();
        MainFactory.doRequest(parametros)
            .then(function (response) {

                if (response.data.estado == 0) {

                    $scope.aportes = response.data.data.oDatProducto;

                    $scope.tableParams = new NgTableParams({
                        page: 1,
                        count: 10,
                        sorting: {
                            name: 'tipo'
                        }
                    }, {
                            counts: [],
                            total: $scope.aportes.length,
                            getData: function (params) {
                                var orderedData = params.sorting() ? $filter('orderBy')($scope.aportes, params.orderBy()) : $scope.aportes;
                                return (orderedData.slice((params.page() - 1) * params.count(), params.page() * params.count()));
                            }
                        });
                    unBlockUI();
                } else {

                    showNotification('alerta', response.data.mensaje);
                    unBlockUI();
                }
            });

        $scope.irExtractoAporte = function (cab) {
            $localStorage.cabecera = cab;
            $state.go('app.consultas.aportesExtracto');
            $rootScope.options = MenuFactory.active($localStorage.menuActive);
        };

        $scope.irPagoAporte = function (aporte) {
            $localStorage.cabAporte = aporte;
            $rootScope.options = MenuFactory.active(4);
            $state.go('app.pagos.aportes');
        };

    }]);