app.controller('OtrosPagosCtrl', ['$scope', '$localStorage', 'MainFactory', '$state',
    function ($scope, $localStorage, MainFactory, $state) {

        $scope.otrosPagos = [];
        $scope.datos = {};
        $scope.nroSocio = $localStorage.datosUsuario.nroSocio;
        $scope.cajasAhorro = [];
        $scope.otroPagoSelected = {};

        var cuentasAhorro = {
            operacion: "73",
            producto: "18",
            entidad: "3",
            cliente: "1",
            presentador: "1",
            entrada: {
                iIdSesion: $localStorage.identificadorSesion,
                iTipSaldo: "AHR"
            }
        };

        blockUI();
        MainFactory.doRequest(cuentasAhorro).then(function (response) {
                if (response.data.estado === 0) {
                    $scope.cajasAhorro = response.data.data.oDatProducto;
                } else {
                    showNotification('alerta', response.data.mensaje);
                }
                unBlockUI();
            },
            function (response) {
                unBlockUI();
                showNotification('alerta', "No se pudo obtener Cuentas de Ahorro");
            });

        blockUI();
        MainFactory.obtenerOtrosPendientes().then(function (response) {
            if (response.data.estado === 0) {
                $scope.otrosPagos = response.data.data;
            } else {
                showNotification('alerta', response.data.mensaje);
            }
            unBlockUI();
        });

        $scope.confirmar = function (form) {
            if (!form.$valid) {
                return;
            }

            blockUI();
            if ($scope.datos.cuenta && $scope.otroPagoSelected) {
                if (isNumber(formatoMonto($scope.otroPagoSelected.importeString))) {

                    const request = {
                        "idSesion": $localStorage.identificadorSesion,
                        "cuentaDebito": $scope.datos.cuenta.wbNumero,
                        "item": $scope.otroPagoSelected.item,
                        "codConcepto": $scope.otroPagoSelected.codConcepto,
                        "monto": formatoMonto($scope.otroPagoSelected.importeString),
                        "sistema": $scope.otroPagoSelected.sistema
                    };
                    $localStorage.datosConfirmacion = {
                        otroPago: $scope.otroPagoSelected,
                        cuentaDebito: $scope.datos.cuenta,
                        datos: $scope.datos,
                    };
                    $localStorage.datosRequest = request;
                    $state.go('app.pagos.otrosPagosConfirmacion');
                } else {
                    unBlockUI();
                    showNotification('alerta', "El monto no tiene el formato correcto.");
                }
            } else {
                unBlockUI();
                showNotification('alerta', "Seleccione todos los campos");
            }
        }

        $scope.limpiarCampos = function () {
            $scope.otrosPagosSelected = {};
            $scope.datos = {};
        };

    }]
);
