app.controller('AhorroExtractoCtrl', ['$scope', 'MainFactory', '$state', '$sessionStorage', '$localStorage', '$rootScope', '$location', 'NgTableParams', '$filter',
    function ($scope, MainFactory, $state, $sessionStorage, $localStorage, $rootScope, $location, NgTableParams, $filter) {

        $localStorage.menuActive = 1;
        $scope.ahorroExtracto;
        $scope.fechas = generarFechas();
        $scope.selected = {};
        $scope.selected.fecha = $scope.fechas[0];
        $scope.selected.ahorroCabecera = $localStorage.nroAhorro;

        $scope.cargarTabla = function () {

            parametros = {
                operacion: "74",
                producto: "18",
                entidad: "3",
                cliente: "1",
                presentador: "1",
                entrada: {
                    iIdSesion: $localStorage.identificadorSesion,
                    iTipExtracto: "AHR",
                    iNroProducto: $scope.selected.ahorroCabecera.wbNumero,
                    anho: getAnhoExtracto($scope.selected.fecha.mes),
                    mes: getMesExtracto($scope.selected.fecha.mes),
                    iCantidad: 20,
                    iRegInicial: 0
                }
            };

            blockUI();
            MainFactory.doRequest(parametros)
                    .then(function (response) {

                        if (response.data.estado == 0) {

                            $scope.ahorroExtracto = response.data.data.oMovExtracto;

                            $scope.tableParams = new NgTableParams({
                                page: 1,
                                count: 8
                            }, {
                                counts: [],
                                getData: function (params) {
                                    var data = $scope.ahorroExtracto;
                                    data = params.filter() ? $filter('filter')(data, params.filter()) : data;
                                    data = params.orderBy() ? $filter('orderBy')(data, params.orderBy()) : data;
                                    params.total(data.length);
                                    data = data.slice((params.page() - 1) * params.count(), params.page() * params.count());
                                    return data;
                                }
                                /*getData: function (params) {
                                 var orderedData = params.sorting() ? $filter('orderBy')($scope.ahorroExtracto, params.orderBy()) : $scope.ahorroExtracto;
                                 return (orderedData.slice((params.page() - 1) * params.count(), params.page() * params.count()));
                                 }*/
                            });
                            unBlockUI();
                        } else {

                            showNotification('alerta', response.data.mensaje);
                            unBlockUI();
                        }
                    });
        };

        $scope.cargarTabla();

        $scope.update = function () {
            $scope.cargarTabla();
        };

        $scope.descargarPdf = function () {

            parametros = {
                operacion: "94",
                producto: "18",
                entidad: "3",
                cliente: "1",
                presentador: "1",
                entrada: {
                    iIdSesion: $localStorage.identificadorSesion,
                    iNroProducto: $scope.selected.ahorroCabecera.wbNumero,
                    anho: getAnhoExtracto($scope.selected.fecha.mes),
                    mes: getMesExtracto($scope.selected.fecha.mes)
                }
            };

            blockUI();
            MainFactory.doRequest(parametros)
                    .then(function (response) {
                        var detalle = {

                        };
                        var i = 0;
                        $scope.totalSaldo = 0;
                        $scope.totalCredito = 0;
                        $scope.totalDebito = 0;
                        var detalle = [];
                        var credito = 0;
                        var debito = 0;

                        $scope.ahorroExtracto.forEach(function (data) {

                            if (data.wbExtOperacion == 'CREDITO') {
                                credito = data.wbExtSalActual;
                            } else {
                                debito = data.wbExtSalActual;
                            }

                            var nuevoDetalle = {
                                "fechaMov": '' + data.wbExtFecha,
                                "suc": "",
                                "nroComprobante": "" + data.wbExtSerie + " " + data.wbExtComprobante,
                                "descripcion": data.wbExtConcepto,
                                "importeDebe": "" + debito,
                                "importeHaber": "" + credito,
                                "saldo": "" + data.wbExtSalActual,
                                "capital": data.wbExtCapital,
                                "interes": $filter('number')(data.wbExtInteres, 0)
                            }
                            $scope.totalSaldo = $scope.totalSaldo + data.wbExtSalActual;
                            detalle[i] = nuevoDetalle;
                            i++;
                        });

                        var json = {
                            "nroDeCuenta": $localStorage.datosUsuario.nroSocio + '-' + $scope.selected.ahorroCabecera.wbCodigo,
                            "moneda": "Guaranies",
                            "titular": $localStorage.datosUsuario.nombre,
                            "rucCi": $localStorage.usuario,
                            "saldoAnterior": "" + response.data.data.monto,
                            "saldoContable": "",
                            "saldoBloqueado": $scope.selected.ahorroCabecera.wbMtoVencido,
                            "saldoDisponible": "" + $scope.selected.ahorroCabecera.wbMtoActual,
                            "promedio": "",
                            "mes": '' + getMesExtracto($scope.selected.fecha.mes),
                            "anho": '' + getAnhoExtracto($scope.selected.fecha.mes),
                            "detalle1": detalle,
                            "wbTasa": $scope.selected.ahorroCabecera.wbTasa,
                            "wbPlazo": $scope.selected.ahorroCabecera.wbPlazo,
                            "wbNroContrato": $scope.selected.ahorroCabecera.wbNroContrato,
                            "nroSocio": $localStorage.datosUsuario.nroSocio,
                            "fecVcto": $scope.selected.ahorroCabecera.wbFecVencimiento,
                            "cuotaProg": $scope.selected.ahorroCabecera.wbMtoCuota,
                            "saldoConfirmado": $scope.selected.ahorroCabecera.wbMtoActual,
                        }

                        var parametros = {
                            url: "/SwitcherSanCristobal-web/extractoCajaAhorro",
                            method: "POST",
                            data: json
                        };


                        blockUI();
                        MainFactory.getPdf(parametros).then(
                                function (response) {
                                    if (response.data.estado == 0) {
                                        window.location.replace('/SwitcherSanCristobal-web/extractoCajaAhorro');
                                        unBlockUI();
                                    } else {
                                        showNotification('alerta', response.data.mensaje);
                                        unBlockUI();
                                    }

                                },
                                function (response) {
                                    unBlockUI();
                                    $scope.mensaje = "Error en la operacion.";
                                });
                        unBlockUI();
                    });
        };


    }]);