app.controller('TarjetaPagoCtrl', ['$scope', 'MainFactory', '$state', '$sessionStorage', '$localStorage', '$rootScope', '$location', 'MenuFactory', 'NgTableParams', '$filter',
    function ($scope, MainFactory, $state, $sessionStorage, $localStorage, $rootScope, $location, MenuFactory, NgTableParams, $filter) {

        $scope.show = false;
        $scope.cajasAhorro = [];
        $scope.datos = {};
        $scope.datos.nroProducto;
        $scope.tarjetas = [];
        $scope.aPagar;
        $scope.flag = false;
        $scope.detalle = [];
        $scope.nroSocio = $localStorage.datosUsuario.nroSocio;
        $scope.datos.opcion = "S";
        $scope.habilitar = true;
        $scope.tarjetasOtrosSocio = [];

        var cuentasAhorro = {
            operacion: "73",
            producto: "18",
            entidad: "3",
            cliente: "1",
            presentador: "1",
            entrada: {
                iIdSesion: $localStorage.identificadorSesion,
                iTipSaldo: "AHR"
            }
        };

        $scope.checkRadio = function (value) {
            $scope.datos.monto = null;
            $scope.datos.nroProducto = null;
            if (value == 'S') {
                $scope.tarjetasOtrosSocio = [];
                $scope.habilitar = true;
            } else if (value == 'N') {
                $scope.habilitar = false;
            }
            $scope.datos.otroSocio = null;
        }

        blockUI();
        MainFactory.doRequest(cuentasAhorro)
            .then(function (response) {
                if (response.data.estado == 0) {
                    $scope.cajasAhorro = response.data.data.oDatProducto;
                } else {
                    showNotification('alerta', response.data.mensaje);
                }
                unBlockUI();
            },
                function (response) {
                    unBlockUI();
                    showNotification('alerta', "No se pudo obtener Cuentas de Ahorro");
                });

        $scope.update = function () {
            $scope.tarjetasOtrosSocio = [];
            $scope.datos.otraCuenta = null;
        }

        $scope.cargarCtaFav = function () {

            parametros = {
                operacion: "75",
                producto: "18",
                entidad: "3",
                cliente: "1",
                presentador: "1",
                entrada: {
                    iIdSesion: $localStorage.identificadorSesion,
                    iOpeCuenta: "OBT",
                    iCtaAlias: "",
                    iCtaNombre: "",
                    iCtaMail: "",
                    iCtaTelefono: "",
                    iCtaCi: "",
                    iCtaNumero: ""
                }
            }

            blockUI();
            MainFactory.doRequest(parametros)
                .then(function (response) {
                    if (response.data.estado == 0) {
                        $scope.cuentasFav = response.data.data.oCtaFavorita;
                    } else {
                        showNotification('alerta', response.data.mensaje);
                    }
                    unBlockUI();
                },
                    function (response) {
                        unBlockUI();
                        showNotification('alerta', "No se pudo obtener Cuentas Favoritas");
                    });
        }

        $scope.cargarCtaFav();

        parametrosCabecera = {
            operacion: "73",
            producto: "18",
            entidad: "3",
            cliente: "1",
            presentador: "1",
            entrada: {
                iIdSesion: $localStorage.identificadorSesion,
                iTipSaldo: "TCR"
            }
        };

        if (typeof $localStorage.cabTarjeta !== 'undefined' && $localStorage.cabTarjeta !== null) {
            $scope.aPagar = $localStorage.cabTarjeta;
            $scope.datos.nroProducto = $scope.aPagar;
            $localStorage.cabTarjeta = null;
            $scope.tarjetas[0] = $scope.aPagar;
            $scope.detalle = $scope.tarjetas;
            //$scope.show = true;
            $scope.tableParams = new NgTableParams({
                page: 1,
                count: 10,
                sorting: {
                    name: 'tipo'
                }
            }, {
                counts: [],
                total: $scope.detalle.length,
                getData: function (params) {
                    var orderedData = params.sorting() ? $filter('orderBy')($scope.detalle, params.orderBy()) : $scope.detalle;
                    return (orderedData.slice((params.page() - 1) * params.count(), params.page() * params.count()));
                }
            });
            unBlockUI();
        } else {
            MainFactory.doRequest(parametrosCabecera).then(
                function (response) {
                    if (response.data.estado == 0) {
                        if (response.data.data.oDatProducto.length > 0) {
                            $scope.show = false;
                            $scope.habilitar = true;
                        } else {
                            $scope.habilitar = false;
                            $scope.show = true;
                            $scope.datos.opcion = "N";
                        }
                        $scope.tarjetas = response.data.data.oDatProducto;
                    } else {
                        showNotification('alerta', response.data.mensaje);
                    }
                    unBlockUI();
                },
                function (response) {
                    unBlockUI();
                });
        }

        $scope.consultarSocio = function () {
            if ($scope.datos.otraCuenta) {
                blockUI();
                $scope.tarjetasOtrosSocio = [];
                let otroSocio;
                if ($scope.datos.otroSocio == '1') {
                    otroSocio = $scope.datos.otraCuenta;
                } else {
                    otroSocio = $scope.datos.otraCuenta.docCredito;
                }
                MainFactory.getDatosOtroSocio($localStorage.identificadorSesion, "TCR", otroSocio)
                    .then(function (response) {
                        if (response.data.estado == 0) {
                            if (response.data.data.oDatProducto.length > 0) {
                                $scope.tarjetasOtrosSocio = response.data.data.oDatProducto;
                            } else {
                                showNotification('alerta', "No posee Tarjeta de Crédito");
                            }
                            unBlockUI();
                        }
                    },
                        function (response) {
                            unBlockUI();
                            showNotification('alerta', "Ha ocurrido un error, intente nuevamente.");
                        });
            } else {
                showNotification('alerta', "Ingrese la cédula del otro socio");
            }
        }

        blockUI();
        $scope.consultarDatos = function () {
            var j = 0;
            $scope.tarjetas.forEach(function (data) {
                if (data.wbNumero == $scope.datos.nroProducto.wbNumero) {
                    $scope.detalle[j] = data;
                    j++;
                }
            });

            $scope.tableParams = new NgTableParams({
                page: 1,
                count: 10,
                sorting: {
                    name: 'tipo'
                }
            }, {
                counts: [],
                total: $scope.detalle.length,
                getData: function (params) {
                    var orderedData = params.sorting() ? $filter('orderBy')($scope.detalle, params.orderBy()) : $scope.detalle;
                    return (orderedData.slice((params.page() - 1) * params.count(), params.page() * params.count()));
                }
            });
            unBlockUI();
        }

        $scope.limpiarCampos = function () {
            $scope.datos.cuenta = null;
            var element = document.getElementById('cuenta_select');
            element.value = "1";
            $scope.datos.monto = null;
            $scope.datos.nroProducto = null;
            $scope.datos.opcion = "S";
            $scope.datos.otraCuenta = null;
            $scope.datos.otroSocio = null;
            $scope.tarjetasOtrosSocio = [];
        };

        $scope.checkMonto = function (value) {
            $scope.datos.monto = number_format(value);
            document.getElementById('monto_pagar').setAttribute("disabled", "disabled");
        }

        $scope.checkOtro = function () {
            document.getElementById('monto_pagar').removeAttribute("disabled");
            $scope.datos.monto = null;
        }

        $scope.confirmar = function (form) {

            if (!form.$valid) {
                return;
            }

            blockUI();
            if (isNumber(formatoMonto($scope.datos.monto)) && formatoMonto($scope.datos.monto) > 0) {
                if ($scope.datos.nroProducto) {
                    $scope.pagoTcr = {};
                    $scope.pagoTcr.cuenta = $scope.datos.cuenta.wbNumero;
                    $scope.pagoTcr.nroCuenta = $scope.nroSocio + '-' + $scope.datos.cuenta.wbCodigo;
                    $scope.pagoTcr.tarjeta = $scope.datos.nroProducto.wbDescripcion;
                    $scope.pagoTcr.monto = formatoMonto($scope.datos.monto);
                    $scope.pagoTcr.cuentaSaldo = $scope.datos.cuenta.wbMtoActual;
                    $scope.pagoTcr.nroTarjeta = $scope.datos.nroProducto.wbNumero;
                    $scope.pagoTcr.fecha = getFecha();
                    $scope.pagoTcr.nombre = $localStorage.datosUsuario.nombre;
                    if ($scope.datos.opcion == "S") {
                        $scope.pagoTcr.beneficiario = $localStorage.datosUsuario.nombre;
                        $localStorage.pagoTcr = $scope.pagoTcr;
                        unBlockUI();
                        $state.go('app.pagos.confirmacionPagoTcr');
                    } else {
                        if ($scope.datos.otroSocio == '0') {
                            $scope.pagoTcr.beneficiario = $scope.datos.otraCuenta.nombre;
                            $scope.pagoTcr.ciOtrSocio = $scope.datos.otraCuenta.docCredito;
                            $localStorage.pagoTcr = $scope.pagoTcr;
                            unBlockUI();
                            $state.go('app.pagos.confirmacionPagoTcr');
                        } else if ($scope.datos.otroSocio == '1') {
                            $scope.pagoTcr.ciOtrSocio = $scope.datos.otraCuenta;
                            var param = {
                                "operacion": "82",
                                "producto": "18",
                                "entidad": "3",
                                "cliente": "1",
                                "presentador": "1",
                                "entrada": {
                                    "iCtaCi": $scope.datos.otraCuenta,
                                    "iCtaNumero": null
                                }
                            };

                            MainFactory.doRequest(param)
                                .then(function (response) {
                                    if (response.data.estado == 0) {
                                        $scope.pagoTcr.beneficiario = response.data.data.oDatCuenta[0].nombre;
                                        $localStorage.pagoTcr = $scope.pagoTcr;
                                        unBlockUI();
                                        $state.go('app.pagos.confirmacionPagoTcr');
                                    } else {
                                        unBlockUI();
                                        showNotification('alerta', response.data.mensaje);
                                    }
                                },
                                    function (response) {
                                        unBlockUI();
                                        showNotification('alerta', "No se pudo consultar beneficiario");
                                    });

                        }
                    }
                } else {
                    showNotification('alerta', "Debe seleccionar la tarjeta");
                    unBlockUI();
                }
            } else {
                unBlockUI();
                showNotification('alerta', "Monto ingresado inválido");
            }
        }

        $scope.ultCuatroTarjeta = function (tarjeta) {
            if (!tarjeta) {
                return "";
            } else {
                var ultCuatro = "";
                ultCuatro = "" + tarjeta.charAt(tarjeta.length - 4) + "" + tarjeta.charAt(tarjeta.length - 3) + "" + tarjeta.charAt(tarjeta.length - 2) + "" + tarjeta.charAt(tarjeta.length - 1);
                return ultCuatro;
            }
        }
    }]);

app.controller('ConfirmacionPagoTcrCtrl', ['$scope', 'MainFactory', '$state', '$sessionStorage', '$localStorage', '$rootScope', '$location',
    function ($scope, MainFactory, $state, $sessionStorage, $localStorage, $rootScope, $location) {

        $scope.confirmacion = $localStorage.pagoTcr;
        $scope.tarjeta = true;
        $scope.pin;
        $scope.tipo;

        $scope.setTipoVerificacion = function (tipo) {
            $scope.pin = '';
            $scope.tipo = tipo;
        }

        $scope.verificarOtp = function () {
            blockUI();
            MainFactory.verifyOtp().then(
                function (response) {
                    if (response.data.estado == 0) {
                        $scope.pagarTarjeta();
                    } else {
                        unBlockUI();
                        showNotification('alerta', response.data.mensaje);
                    }
                },
                function (response) {
                    unBlockUI();
                    showNotification('alerta', "No se pudo realizar el pago");
                });
        }

        $scope.pagar = function (form) {
            if (!form.$valid) {
                return;
            }

            if ($scope.tipo) {
                if ($scope.pin == '') {
                    showNotification('alerta', 'Ingrese su código de verificación');
                } else {
                    if ($scope.tipo == "PIN") {
                        $scope.pagarTarjeta();
                    } else {
                        $scope.verificarOtp();
                    }
                }
            } else {
                showNotification('alerta', 'Seleccione un método de verificación');
            }
        }

        $scope.pagarTarjeta = function () {
            blockUI();
            if ($scope.confirmacion.monto > $scope.confirmacion.cuentaSaldo) {
                showNotification('alerta', 'Saldo Insuficiente para completar la transaccion');
                unBlockUI();
                $state.go('app.pagos.tarjetas');
            } else {
                var parametros = {
                    operacion: "79",
                    producto: "18",
                    entidad: "3",
                    cliente: "1",
                    presentador: "1",
                    entrada: {
                        iIdSesion: $localStorage.identificadorSesion,
                        iPrdPago: "TCR",
                        iCtaDebito: $scope.confirmacion.cuenta,
                        iNroProducto: $scope.confirmacion.nroTarjeta,
                        iMtoPago: $scope.confirmacion.monto,
                        iCanCuota: null,
                        tipoAuth: {
                            tipo: $scope.tipo,
                            valor: $scope.pin
                        },
                        idSocio: $localStorage.usuario
                    }
                }

                if ($scope.confirmacion.ciOtrSocio) {
                    parametros.entrada.iCiOtrSocio = $scope.confirmacion.ciOtrSocio;
                }

                MainFactory.doRequest(parametros).then(
                    function (response) {
                        if (response.data.estado == 0) {
                            unBlockUI();
                            $localStorage.comprobante = response.data.data.oMovPago[0];
                            $localStorage.comprobante.datos = $scope.confirmacion;
                            $state.go('app.pagos.comprobanteTcr');
                        } else {
                            unBlockUI();
                            $state.go('app.pagos.tarjetas');
                            showNotification('alerta', response.data.mensaje);
                        }
                    },
                    function (response) {
                        unBlockUI();
                        showNotification('alerta', "No se pudo realizar el pago");
                    });
            }
        }

        $scope.cancelar = function () {
            $state.go('app.pagos.tarjetas');
        }
    }]);

app.controller('ComprobantePagoTcrCtrl', ['$scope', '$state', '$sessionStorage', '$localStorage', '$rootScope', '$location', 'MainFactory',
    function ($scope, $state, $sessionStorage, $localStorage, $rootScope, $location, MainFactory) {

        $scope.comprobante = $localStorage.comprobante;

        $scope.aceptar = function () {
            $state.go('app.pagos.tarjetas');
        }

        $scope.descargarPdf = function () {

            var json = {
                "pagComprobante": $scope.comprobante.pagComprobante,
                "fecha": $scope.comprobante.fecha,
                "concepto": $scope.comprobante.concepto,
                "nombre": $scope.comprobante.datos.nombre,
                "nroCuenta": $scope.comprobante.datos.nroCuenta,
                "monto": $scope.comprobante.datos.monto,
                "cuentaDestino": $scope.comprobante.datos.cuentaDestino,
                "beneficiario": $scope.comprobante.datos.beneficiario,
                "tarjeta": $scope.comprobante.datos.tarjeta,
                "prestamo": $scope.comprobante.datos.prestamo,
                "nroOperacion": $scope.comprobante.nroOperacion,
                "fecOperacion": $scope.comprobante.fecOperacion,
                "socio": $scope.comprobante.socio,
                "cuentaOrigenDescripcion": $scope.comprobante.cuentaOrigenDescripcion,
                "importe": $scope.comprobante.importe,
                "cuentaDestinoDescripcion": $scope.comprobante.cuentaDestinoDescripcion
            }

            var parametros = {
                url: "/SwitcherSanCristobal-web/comprobante",
                method: "POST",
                data: json
            };

            blockUI();
            MainFactory.getPdf(parametros).then(
                function (response) {
                    if (response.data.estado == 0) {
                        window.location.replace('/SwitcherSanCristobal-web/comprobante');
                        unBlockUI();
                    } else {
                        showNotification('alerta', response.data.mensaje);
                        unBlockUI();
                    }
                },
                function (response) {
                    unBlockUI();
                    $scope.mensaje = "Error en la operacion.";
                });
        };
    }]);