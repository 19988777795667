app.filter('pronetdate', function () {
    return function (value, all) {
        var date = value.substring(0, 2) + '-' + value.substring(2, 4) + '-' + value.substring(4, 6);
        return date;
    };
});
app.controller('DetalleServicioCtrl', ['$scope', 'MainFactory', 'PagoServicioFactory', '$state', '$sessionStorage', '$localStorage', '$rootScope', '$location', 'MenuFactory', 'NgTableParams', '$filter',
    function ($scope, MainFactory, PagoServicioFactory, $state, $sessionStorage, $localStorage, $rootScope, $location, MenuFactory, NgTableParams, $filter) {

        $scope.pin;
        $scope.detalleServicio = $localStorage.detalleServicio.detalleFactura.facturas[0];
        $scope.servicio = $localStorage.detalleServicio;
        var montoServicio;
        $scope.tipo;

        $scope.setTipoVerificacion = function (tipo) {
            $scope.pin = '';
            $scope.tipo = tipo;
        }

        $scope.verificarOtp = function () {
            blockUI();
            MainFactory.verifyOtp().then(
                function (response) {
                    if (response.data.estado == 0) {
                        $scope.realizarPago();
                    } else {
                        unBlockUI();
                        showNotification('alerta', response.data.mensaje);
                    }
                },
                function (response) {
                    unBlockUI();
                    showNotification('alerta', "No se pudo realizar el pago");
                });
        }

        $scope.actualizarDetalle = function (d) {
            $scope.facturaSeleccionada = d;
            $.each($scope.facturaSeleccionada.parametros, function (i, val) {
                if (val.debitable == true) {
                    montoServicio = val.valor;
                }
            });
        };
        $scope.formatear = function (d) {
            d.valor = $filter('number')(d.valor.replace(/\D+/g, ""), 0);
        };

        $scope.pagar = function (form) {
            if (!form.$valid) {
                return;
            }

            if ($scope.tipo) {
                if ($scope.pin == '') {
                    showNotification('alerta', 'Ingrese su código de verificación');
                } else {
                    if ($scope.tipo == "PIN") {
                        $scope.realizarPago();
                    } else {
                        $scope.verificarOtp();
                    }
                }
            } else {
                showNotification('alerta', 'Seleccione un método de verificación');
            }
        }

        $scope.realizarPago = function () {
            $.each($scope.facturaSeleccionada.parametros, function (i, val) {
                if (val.debitable == true) {
                    val.valor = montoServicio.replace(/\D+/g, "");
                }
            });
            var params = {
                "operacion": "81",
                "producto": "18",
                "entidad": "3",
                "cliente": "1",
                "presentador": "1",
                "entrada": {
                    "iIdSesion": $localStorage.identificadorSesion,
                    "iCtaDebito": $scope.servicio.cuenta.wbNumero,
                    "iIdServicio": $scope.servicio.serviceSelected.idServicio,
                    "iMonto": montoServicio.replace(/\D+/g, ""),
                    "origen": {
                        "tipoOrigen": "MOVIL"
                    },
                    "servicio": {
                        "descripcion": $scope.servicio.serviceSelected.descripcion,
                        "codigoServicio": $scope.servicio.serviceSelected.codigoServicio,
                        "flgCategoria": $scope.servicio.serviceSelected.flgCategoria,
                        "idServicio": $scope.servicio.serviceSelected.idServicio
                    },
                    "idRed": $scope.servicio.servicio.idRed,
                    "datos": $scope.servicio.servicio.datos,
                    "factura": $scope.facturaSeleccionada,
                    "tipoAuth": {
                        "tipo": $scope.tipo,
                        "valor": $scope.pin
                    },
                    "idSocio": $localStorage.usuario
                }
            };

            blockUI();
            PagoServicioFactory.pagar(params)
                .then(function (response) {
                    if (!response.data.netel) {
                        //console.log('NETEL NO LLEGA, REVERSA');
                        showNotification('alerta', response.data.mensaje);
                        $state.go('app.pagos.servicios');
                    } else if (response.data.netel.estado == 0) {
                        //console.log('HIZO EL PAGO');
                        $localStorage.comprobantePagoServicio = response.data.netel;
                        $localStorage.nroOperacion = response.data.data.numeroTransaccion;
                        $localStorage.comprobantePagoServicio.servicio = $scope.servicio.serviceSelected.codigoServicio;
                        $state.go('app.pagos.ComprobanteServicio');
                    } else {
                        if (!response.data.netel.mensaje) {
                            //console.log('NO PAGO POR OTRA COSA1');
                            showNotification('alerta', 'No se pudo realizar el pago');
                            $state.go('app.pagos.servicios');
                        } else {
                            //console.log('NO PAGO POR OTRA COSA2');
                            showNotification('alerta', response.data.netel.mensaje);
                            $state.go('app.pagos.servicios');
                        }
                    }
                    unBlockUI();
                },
                    function (response) {
                        unBlockUI();
                        showNotification('alerta', "No se pudo realizar el pago");
                    });
        };

        $scope.cancelar = function () {
            $state.go('app.pagos.servicios');
        };
    }]);