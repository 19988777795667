app.filter('unsafe', ["$sce", function ($sce) {
    return $sce.trustAsHtml;
}]);

function generarNumeros() {
    var arrayNumeros = [];
    var elemento = [];
    var numerosPosibles = [1, 2, 3, 4, 5, 6, 7, 8, 9, 0];
    for (var i = 0; i < 12; i++) {
        if (i === 10) {
            elemento.push({
                'numero': '&#9668;&#9668;',
                'descripcion': '&#9668;&#9668;'
            });
        }
        if (i === 11) {
            elemento.push({
                'numero': '&#9668;',
                'descripcion': '&#9668;'
            });
        }
        if (numerosPosibles.length !== 0) {
            var index = Math.floor(Math.random() * (numerosPosibles.length));
            var valor = numerosPosibles[index];
            elemento.push({
                'numero': valor,
                'descripcion': valor.toString()
            });
            numerosPosibles.remove(valor);
        }
        if (elemento.length === 3) {
            arrayNumeros.push(elemento);
            elemento = [];
        }
    }
    return arrayNumeros;
}

app.directive('tecladitoactivacion2', function () {
    return {
        templateUrl: 'app/activacion/tecladito2.html',
        restrict: 'E',
        transclude: true,
        replace: true,
        scope: true,
        link: function postLink($scope, element, attrs) {
            $scope.arrayNumeros = generarNumeros();

            $scope.posicion = attrs.coordenada;

            $scope.mouseover = function () {
                $.each($scope.arrayNumeros, function (i, val) {
                    $.each(val, function (j, value) {
                        value.descripcion = '*';
                        if (i === 3 && (j === 1 || j === 2)) {
                            value.descripcion = value.numero.toString();
                        }
                    });
                });
            };

            $scope.mouseexit = function () {
                $.each($scope.arrayNumeros, function (i, val) {
                    $.each(val, function (j, value) {
                        value.descripcion = value.numero.toString();
                    });
                });
            };

            $scope.click = function (numero) {
                if (numero === '&#9668;&#9668;') {
                    $scope.$parent.pin2 = '';
                    $scope.pin2 = '';
                    return;
                }

                if (numero === '&#9668;') {
                    $scope.$parent.pin2 = $scope.$parent.pin2.substr(0, $scope.$parent.pin2.length - 1);
                    $scope.pin2 = $scope.$parent.pin2;
                    return;
                }


                $scope.arrayNumeros = generarNumeros();
                if ($scope.$parent.pin2.length < 6) {
                    $scope.$parent.pin2 = $scope.$parent.pin2 + numero;
                    $scope.pin2 = $scope.$parent.pin2;
                }
            };
        }
    };
});
