app.controller('ValidarConvenioCtrl', ["$scope", "$timeout", "$state", "ValidarConvenioFactory", "$filter", "$location", function ($scope, $timeout, $state, ValidarConvenioFactory, $filter, $location) {

    $scope.idSesion;
    $scope.convenio;
    $scope.mensaje;

    $scope.validarConvenio = function () {
        blockUI();
        var request = {
            "codigoValidacion": $scope.idSesion,
            "idConvenio": $scope.convenio
        };
        ValidarConvenioFactory.validarConvenio(request)
            .then(function (response) {
                unBlockUI();
                console.log(response.data);
                if (response.data.estado == 0) {
                    $scope.mensaje = response.data.mensaje.replace(/\n/g,'<br/>').trim();
                    document.getElementById('respuestaValida').insertAdjacentHTML('beforeend', $scope.mensaje);
                } else {
                    showNotification('alerta', response.data.mensaje);
                }
            },
                function (response) {
                    unBlockUI();
                    showNotification('alerta', "Ha ocurrido un error, intente nuevamente");
                }
            );

    }
    $scope.init = function () {
        $scope.idSesion = $location.search().codigo
    };

    $scope.init();
}]);