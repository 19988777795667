app.controller('DatosPersonalesCtrl', ['$scope', 'MainFactory', '$state', '$sessionStorage', '$localStorage', '$rootScope', '$location', 'NgTableParams', '$filter', 'MenuFactory',
    function ($scope, MainFactory, $state, $sessionStorage, $localStorage, $rootScope, $location, NgTableParams, $filter, MenuFactory) {

        $localStorage.menuActive = 3;
        var datos = $localStorage.datosUsuario;
        $scope.cambio = {};
        $scope.cambio.mail = datos.mail;
        $scope.cambio.telefono = datos.telefono;
        $scope.cambio.movil = datos.celular;

        $scope.confirmar = function () {
            $('#cambioDatosModal').modal('show');
        }

        $scope.cambiarDatos = function () {

            if ($scope.cambio.mail == "") {
                $scope.cambio.mail = null;
            }
            if ($scope.cambio.telefono == "") {
                $scope.cambio.telefono = null;
            }
            if ($scope.cambio.movil == "") {
                $scope.cambio.movil = null;
            }

            parametros = {
                "operacion": "78",
                "producto": "8",
                "entidad": "3",
                "cliente": "1",
                "presentador": "1",
                "entrada": {
                    "iIdSesion": $localStorage.identificadorSesion,
                    "iNvoMail": $scope.cambio.mail,
                    "iNvoTelefono": $scope.cambio.telefono,
                    "iNvoMovil": $scope.cambio.movil
                }
            }

            blockUI();
            MainFactory.doRequest(parametros)
                    .then(function (response) {

                        if (response.data.estado == 0) {

                            unBlockUI();
                            showNotification('info', 'Datos Actualizados');
                            $('#cambioDatosModal').modal('hide');
                            $scope.cambio.mail = response.data.data.oDatCuenta[0].mail;
                            $scope.cambio.telefono = response.data.data.oDatCuenta[0].telefono;
                            $scope.cambio.movil = response.data.data.oDatCuenta[0].celular;
                            var aux = $localStorage.datosUsuario;
                            aux.mail = $scope.cambio.mail;
                            aux.telefono = $scope.cambio.telefono;
                            aux.celular = $scope.cambio.movil;
                            $localStorage.datosUsuario = aux;

                        } else {
                            unBlockUI();
                            showNotification('alerta', response.data.mensaje);
                            $('#cambioDatosModal').modal('hide');
                        }
                    });
        };

        $scope.cancelar = function () {
            $('#cambioDatosModal').modal('hide');
        }
    }]);