
app.controller('ActivacionCtrl', ['$scope', '$state', '$sessionStorage', 'MainFactory', '$localStorage', '$rootScope', '$location',
    function ($scope, $state, $sessionStorage, MainFactory, $localStorage, $rootScope, $location) {

        $scope.pass = '';
        $scope.pin1;
        $scope.pin2;
        $scope.confirmar = '';

        //para obtener token
        var token = $location.search().token;

        $scope.confirmar = function (form) {
            if (form.$valid) {
                var parametros = {
                    "token": token,
                    "pin": $scope.pin1
                };
               
                if ($scope.pin1 == $scope.pin2) {
                    if ($scope.pin1.length != 6 || !/^\d+$/.test($scope.pin1)) {
                        showNotification('alerta', "La contraseña debe ser numérica y tener 6 dígitos");
                    } else {
                        blockUI();
                        MainFactory.activarCuenta(parametros)
                            .then(function (response) {
                                if (response.data.estado === 0) {
                                    showNotification('info', 'La cuenta ha sido activada exitosamente');
                                    unBlockUI();
                                    $state.go('login');
                                } else {
                                    showNotification('alerta', response.data.mensaje);
                                    unBlockUI();
                                }
                            },
                                function (response) {
                                    unBlockUI();
                                    showNotification('alerta', "Ha ocurrido un error, intente nuevamente");
                                });
                    }
                } else {
                    showNotification('alerta', "Las contraseñas no coinciden");
                }
            } else {
                showNotification('alerta', 'Campos Incompletos');
            }
        };
    }]);