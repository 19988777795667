app.controller('AhorroProgramadoConfirmacion', ['$scope', 'MainFactory', '$state', '$sessionStorage', '$localStorage',
    function ($scope, MainFactory, $state, $sessionStorage, $localStorage) {
        $scope.confirmacion = $localStorage.datosConfirmacion;
        $scope.request = $localStorage.datosRequest;
        $scope.pin;
        $scope.tipo;
        $scope.condicion1 = false;
        $scope.condicion2 = false;
        $scope.ambasCondiciones = false;

        unBlockUI();
        $scope.estiloEnlace = {
            color: 'green', // Color del texto verde
            'text-decoration': 'underline', // Subrayado
            transition: 'color 0.3s ease', // Transición suave para el cambio de color
            cursor: 'pointer'
        };

        $scope.onSubmit = function (form) {
            blockUI();
            if (!form.$valid) {
                return;
            }

            if ($scope.tipo) {
                if ($scope.pin == '') {
                    showNotification('alerta', 'Ingrese su código de verificación');
                    unBlockUI();
                } else {
                    if ($scope.tipo == "PIN") {
                        $scope.realizarPago();
                    } else {
                        $scope.verificarOtp();
                    }
                }
            } else {
                showNotification('alerta', 'Seleccione un método de verificación');
                unBlockUI();
            }
        }

        $scope.setTipoVerificacion = function (tipo) {
            $scope.pin = '';
            $scope.tipo = tipo;
        }

        $scope.verificarOtp = function () {
            blockUI();
            MainFactory.verifyOtp().then(
                function (response) {
                    if (response.data.estado == 0) {
                        $scope.realizarPago();
                    } else {
                        unBlockUI();
                        showNotification('alerta', response.data.mensaje);
                    }
                },
                function (response) {
                    unBlockUI();
                    showNotification('alerta', "No se pudo realizar el pago");
                });
        }

        $scope.realizarPago = function () {
            let tipoAuth = {
                "tipo": $scope.tipo,
                "valor": $scope.pin
            };
            $scope.request.tipoAuth = tipoAuth;
            $scope.request.idSocio = $localStorage.usuario;

            MainFactory.crearAhorroProgramado($scope.request).then(
                function (response) {
                    if (response.data.estado === 0) {
                        unBlockUI();
                        $localStorage.comprobante = response;
                        $state.go('app.ahorroProgramadoComprobante');
                    } else {
                        unBlockUI();
                        $state.go('app.ahorroProgramado');
                        showNotification('alerta', response.data.mensaje);
                    }
                },
                function (response) {
                    unBlockUI();
                    showNotification('alerta', "No se pudo realizar la operacion");
                });
        };

        $scope.cancelar = function () {
            $state.go('app.ahorroProgramado');
        }

        $scope.verTerminos = function () {
            $localStorage.url = '/SwitcherSanCristobal-web/webresources/terminos-y-condiciones/ahorro-programado';
            $localStorage.urlRetorno = 'app.ahorroProgramadoConfirmacion';
            $localStorage.titulo = 'BASES Y CONDICIONES AHORRO PROGRAMADO';
            $state.go('app.ver-pdf-pdfjs');
        }

        $scope.checkCondiciones = function () {
            $scope.ambasCondiciones = $scope.condicion1 && $scope.condicion2;
        };
    }]);
