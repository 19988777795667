app.controller('ComprobanteTransferenciaCtrl', ['$scope', '$state', '$sessionStorage', '$localStorage', '$rootScope', '$location', 'MainFactory',
    function ($scope, $state, $sessionStorage, $localStorage, $rootScope, $location, MainFactory) {

        $scope.comprobante = $localStorage.pagoTransferencia;



        $scope.aceptar = function () {
            $state.go('app.transferencia');
        }

        $scope.descargarPdf = function () {

            var json = {
                "pagComprobante": $scope.comprobante.pagComprobante,
                "fecha": $scope.comprobante.fecha,
                "concepto": $scope.comprobante.datos.concepto,
                "nombre": $scope.comprobante.datos.nombre,
                "nroCuenta": $scope.comprobante.datos.nroCuenta,
                "monto": null,
                "cuentaDestino": null,
                "beneficiario": $scope.comprobante.beneficiario,
                "tarjeta": $scope.comprobante.datos.tarjeta,
                "prestamo": $scope.comprobante.datos.prestamo,
                "nroOperacion": ''+$scope.comprobante.nroOperacion,
                "fecOperacion": $scope.comprobante.fecOperacion,
                "socio": $scope.comprobante.socio,
                "cuentaOrigenDescripcion": $scope.comprobante.cuentaOrigenDescripcion,
                "importe": $scope.comprobante.importe,
                "cuentaDestinoDescripcion": $scope.comprobante.cuentaDestinoDescripcion
            }

            var parametros = {
                url: "/SwitcherSanCristobal-web/comprobante",
                method: "POST",
                data: json
            };

            blockUI();
            MainFactory.getPdf(parametros).then(
                function (response) {
                    if (response.data.estado == 0) {
                        window.location.replace('/SwitcherSanCristobal-web/comprobante');
                        unBlockUI();
                    } else {
                        showNotification('alerta', response.data.mensaje);
                        unBlockUI();
                    }
                },
                function (response) {
                    unBlockUI();
                    $scope.mensaje = "Error en la operacion.";
                });
        };
    }]);