app.controller('PrestamoExtractoCtrl', ['$scope', 'MainFactory', '$state', '$sessionStorage', '$localStorage', '$rootScope', '$location', 'NgTableParams', '$filter',
    function ($scope, MainFactory, $state, $sessionStorage, $localStorage, $rootScope, $location, NgTableParams, $filter) {

        $localStorage.menuActive = 1;
        $scope.prestamoExtracto;
        $scope.selected = {};
        //$scope.selected.prestamoCabecera = $localStorage.nroPrestamo;
        $scope.principal = $localStorage.cabeceraPmo;

        $scope.cargarTabla = function () {

            parametros = {
                operacion: "74",
                producto: "18",
                entidad: "3",
                cliente: "1",
                presentador: "1",
                entrada: {
                    iIdSesion: $localStorage.identificadorSesion,
                    iTipExtracto: "PMO",
                    iNroProducto: $scope.principal.wbNumero,
                    anho: null,
                    mes: null,
                    iCantidad: 20,
                    iRegInicial: 0
                }
            };

            blockUI();
            MainFactory.doRequest(parametros)
                .then(function (response) {

                    if (response.data.estado == 0) {

                        $scope.prestamoExtracto = response.data.data.oMovExtracto;
                        $scope.cabecera = $scope.prestamoExtracto[0];

                        $scope.tableParams = new NgTableParams({
                            page: 1,
                            count: 8,
                            sorting: {
                                name: 'wbExtRegistro'
                            }
                        }, {
                                counts: [],
                                getData: function (params) {
                                    var data = $scope.prestamoExtracto;
                                    data = params.filter() ? $filter('filter')(data, params.filter()) : data;
                                    data = params.orderBy() ? $filter('orderBy')(data, params.orderBy()) : data;
                                    params.total(data.length);
                                    data = data.slice((params.page() - 1) * params.count(), params.page() * params.count());
                                    return data;
                                }
                                /*counts:[],
                                total: $scope.prestamoExtracto.length,
                                getData: function (params) {
                                var orderedData = params.sorting() ? $filter('orderBy')($scope.prestamoExtracto, params.orderBy()) : $scope.prestamoExtracto;
                                return (orderedData.slice((params.page() - 1) * params.count(), params.page() * params.count()));*/
                            });
                        unBlockUI();
                    } else {

                        showNotification('alerta', response.data.mensaje);
                        unBlockUI();
                    }
                });

        };

        $scope.cargarTabla();

        $scope.descargarPdf = function () {
            var detalle = [];
            var i = 0;

            $scope.prestamoExtracto.forEach(function (data) {

                var nuevoDetalle = {
                    "fecha": "" + data.wbExtCuoFecPag,
                    "operacion": "",
                    "monto": "",
                    "tipo": "" + data.wbExtTipo,
                    "comprob": '' + data.wbExtSerie + " " + data.wbExtComprobante,
                    "socTransf": "",
                    "suc": "" + data.wbExtNroSucursal,
                    "debito": "" + data.wbExtMtoDebito,
                    "credito": "" + data.wbExtMtoCredito,
                    "compensatorio": "" + data.wbExtMtoCompensa,
                    "fas": "" + data.wbExtCuoIntFas,
                    "moratorio": "" + data.wbExtMtoHonorario,
                    "punit": "" + data.wbExtMtoPunitorio,
                    "gasto": "" + data.wbExtMtoGasto,
                    "iva": "" + data.wbExtCuoIva,
                    "honorarioPag": "" + data.wbExtMtoHonorario,
                    "gastoJudPag": "" + data.wbExtMtoGasJud,
                    "gastoAdmPag": "" + data.wbExtMtoGasAdm,
                    "total": "" + data.wbExtMtoPagTotal,
                    "dias": "" + data.wbExtCuoDias
                }
                detalle[i] = nuevoDetalle;
                i++;
            });
            //var d = new Date;
            var date = new Date;
            var json = {
                "nroSocio": '' + $localStorage.datosUsuario.nroSocio,
                "cedula": "" + $localStorage.usuario,
                "nombre": '' + $localStorage.datosUsuario.nombre,
                "anho": '' + date.getFullYear(),
                "mes": '' + (date.getMonth() + 1),
                "fecIngreso": "" + $localStorage.datosUsuario.fecIngreso,
                "estado": '' + $scope.prestamoExtracto[0].wbExtEstado,
                "suscrito": "",
                "integrado": "",
                "transferido": "",
                "aIntegrar": "",
                "nroPrestamo": $localStorage.datosUsuario.nroSocio + '-' + $scope.principal.wbCodigo,
                "nroSolicitud": "", // FALTA ESTE CAMPO
                "saldo": "" + $scope.prestamoExtracto[0].wbExtSalActual,
                "detalle": detalle
            }

            var parametros = {
                url: "/SwitcherSanCristobal-web/movimientoDePrestamo",
                method: "POST",
                data: json
            };

            blockUI();
            MainFactory.getPdf(parametros).then(
                function (response) {
                    if (response.data.estado == 0) {
                        window.location.replace('/SwitcherSanCristobal-web/movimientoDePrestamo');
                        unBlockUI();
                    } else {
                        showNotification('alerta', response.data.mensaje);
                        unBlockUI();
                    }
                },
                function (response) {
                    unBlockUI();
                    $scope.mensaje = "Error en la operacion.";
                });
        };
    }]);