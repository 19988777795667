app.controller('ConsultaPadronCtrl', ['$scope', 'MainFactory', '$state', '$sessionStorage', '$localStorage', '$rootScope', '$location', 'NgTableParams', '$filter', 'MenuFactory',
    function ($scope, MainFactory, $state, $sessionStorage, $localStorage) {

        var padron = $localStorage.datosPadron.data;
        console.log("DATOS PADRON: ", padron);
        $scope.padron = {};
        $scope.padron.descripcionAsamblea = padron.descripcionAsamblea ? padron.descripcionAsamblea : '----';
        $scope.padron.estadoSocio = padron.estadoSocio ? padron.estadoSocio : '----';
        $scope.padron.tipoHabilitacion = padron.tipoHabilitacion ? padron.tipoHabilitacion : '----';
        $scope.padron.deudaAporte = padron.deudaAporte ? padron.deudaAporte : '----';
        $scope.padron.deudaSolidaridad = padron.deudaSolidaridad ? padron.deudaSolidaridad : '----';
        $scope.padron.deudaPrestamo = padron.deudaPrestamo ? padron.deudaPrestamo : '----';
        $scope.padron.deudaTarjeta = padron.deudaTarjeta ? padron.deudaTarjeta : '----';
        $scope.padron.ordenInscripcion = padron.ordenInscripcion ? padron.ordenInscripcion : '----';
        $scope.padron.fechaInscripcion = padron.fechaInscripcion ? padron.fechaInscripcion : '----';
        $scope.padron.horaInscripcion = padron.horaInscripcion ? padron.horaInscripcion : '----';
        $scope.padron.mesa = padron.mesa ? padron.mesa : '----';
        $scope.padron.orden = padron.orden ? padron.orden : '----';

    }]);
