app.controller('AhorroCtrl', ['$scope', '$state', '$sessionStorage','$localStorage', '$rootScope', '$location', 'MainFactory', 'NgTableParams', '$filter', 'MenuFactory',
    function ($scope, $state, $sessionStorage,$localStorage, $rootScope, $location, MainFactory, NgTableParams, $filter, MenuFactory) {

    $scope.ahorros;
    $localStorage.menuActive= 1;
    $scope.nroSocio= $localStorage.datosUsuario.nroSocio;
    
    parametros= {
            operacion: "73",
            producto: "18",
            entidad: "3",
            cliente: "1",
            presentador: "1",
            entrada: {
                iIdSesion: $localStorage.identificadorSesion,
                iTipSaldo : "AHR"
                    }
    };

    blockUI();
    MainFactory.doRequest(parametros)
           .then(function (response) {

        if (response.data.estado == 0) {

            $scope.ahorros= response.data.data.oDatProducto;

            $scope.tableParams = new NgTableParams({
                page: 1,
                count: 10,
                sorting: {
                name: 'tipo'
                                 }
            }, {
                    counts:[],
                    total: $scope.ahorros.length,
                    getData: function (params) {
                    var orderedData = params.sorting() ? $filter('orderBy')($scope.ahorros, params.orderBy()) : $scope.ahorros;
                    return (orderedData.slice((params.page() - 1) * params.count(), params.page() * params.count()));
            }
            });
            unBlockUI();
        }else{
            
            showNotification('alerta', response.data.mensaje);
            unBlockUI();
        }
    });

    $scope.verExtractoAhorro = function(ahorro){
        
        $localStorage.nroAhorro= ahorro;
        $state.go('app.consultas.ahorroExtracto');
        $rootScope.options = MenuFactory.active($localStorage.menuActive);
    };

    $scope.verExtractoAhorroDetalle = function (ahorro) {
        $localStorage.ahorro = ahorro;
        $state.go('app.consultas.detallesAhorro');
        $rootScope.options = MenuFactory.active($localStorage.menuActive);
    }

 }]);
