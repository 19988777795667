app.controller('CambiarPassCtrl', ['$scope', 'MainFactory', '$state', '$sessionStorage', '$localStorage', '$rootScope', '$location', 'NgTableParams', '$filter', 'MenuFactory',
    function ($scope, MainFactory, $state, $sessionStorage, $localStorage, $rootScope, $location, NgTableParams, $filter, MenuFactory) {

        $localStorage.menuActive = 3;
        $scope.pass = {};
        $scope.pass.actual = '';
        $scope.pass.nuevo = '';
        $scope.pass.confirmar = '';

        $scope.confirmar = function (form) {

            if (!form.$valid) {
                error = true;
                return;
            }
            if ($scope.pass.nuevo == $scope.pass.confirmar) {
                if ($scope.pass.nuevo.length != 6 || !/^\d+$/.test($scope.pass.nuevo)) {
                    showNotification('alerta', "La contraseña debe ser numérica y tener 6 dígitos");

                } else {
                    $('#cambioPassModal').modal('show');
                }
            } else {
                showNotification('alerta', "Las contraseñas no coinciden");
            }

        };

        $scope.cambiarPass = function () {

            parametros = {
                "operacion": "77",
                "producto": "8",
                "entidad": "3",
                "cliente": "1",
                "presentador": "1",
                "entrada": {
                    "idSocio": $localStorage.usuario,
                    "iIdSesion": $localStorage.identificadorSesion,
                    "iConActual": $scope.pass.actual,
                    "iConNueva": $scope.pass.nuevo
                }
            }

            blockUI();
            MainFactory.doRequest(parametros)
                    .then(function (response) {

                        if (response.data.estado == 0) {
                            unBlockUI();
                            showNotification('info', response.data.data.oDatCambio[0].observacion);
                            $('#cambioPassModal').modal('hide');
                            $scope.pass = {};
                            if ($localStorage.datosUsuario.cambiarPass == true) {
                                $localStorage.datosUsuario.cambiarPass = false;
                                $state.go('app.inicio');
                            }
                        } else {
                            unBlockUI();
                            showNotification('alerta', response.data.mensaje);
                            $('#cambioPassModal').modal('hide');
                        }

                    });

        };

        $scope.cancelar = function () {
            $('#cambioPassModal').modal('hide');
        }

    }]);