Object.defineProperty(Array.prototype, 'contains', {
    enumerable: false,
    value: function (v) {
        for (var i = 0; i < this.length; i++) {
            if (this[i] === v) return true;
        }
        return false;
    }
});

Object.defineProperty(Array.prototype, 'remove', {
    enumerable: false,
    value: function (val) {
        var i = this.indexOf(val);
        return i > -1 ? this.splice(i, 1) : [];
    }
});

function collap() {
    $('.collapse').collapse('hide');
}

var app = angular.module('app', [
    'ui.router', 'ngTable', 'ngStorage',
    '720kb.tooltips', 'wt.responsive',
    'ui.carousel', 'ngIdle', 'ngDialog',
    'naif.base64', 'ngMap'
]).config(['$stateProvider', '$httpProvider', '$urlRouterProvider', '$qProvider', 'IdleProvider',
    function ($stateProvider, $httpProvider, $urlRouterProvider, $qProvider, IdleProvider) {
        IdleProvider.idle(295); // in seconds
        IdleProvider.timeout(295); // in seconds

        $stateProvider
            .state('login', {
                url: '/login',
                cache: false,
                templateUrl: 'app/login/view.html',
                controller: 'LoginCtrl',
                module: 'public'
            })
            .state('alta-usuario', {
                url: '/alta-usuario',
                cache: false,
                templateUrl: 'app/alta-usuario/view.html',
                controller: 'AltaUsuarioCtrl',
                module: 'public'
            })
            .state('activacion', {
                url: '/activarCuenta',
                cache: false,
                templateUrl: 'app/activacion/view.html',
                controller: 'ActivacionCtrl',
                module: 'public'
            })
            .state('validacion-datos', {
                url: '/validacion-datos',
                cache: false,
                templateUrl: 'app/validacion-datos/view.html',
                controller: 'ValidacionDatosCtrl',
                module: 'public'
            })
            .state('app', {
                //url: '/',
                //cache: false,
                abstract: true,
                templateUrl: 'app/menu/view.html',
                controller: 'MenuCtrl',
                module: 'private'
            })
            .state('app.inicio', {
                url: '/inicio',
                cache: false,
                templateUrl: 'app/inicio/view.html',
                controller: 'InicioCtrl',
                module: 'private'
            })
            .state('app.consultas', {
                url: '/consultas',
                abstract: true,
                template: '<ui-view></ui-view>',
                module: 'private'
            })
            .state('app.consultas.aportes', {
                url: '/aportes',
                cache: false,
                templateUrl: 'app/aportes/view.html',
                controller: 'AporteCtrl',
                module: 'private'
            })
            .state('app.consultas.aportesExtracto', {
                url: '/aportes/extracto',
                cache: false,
                templateUrl: 'app/aportesExtracto/view.html',
                controller: 'AporteExtractoCtrl',
                module: 'private'
            })
            .state('app.consultas.solidaridad', {
                url: '/solidaridad',
                cache: false,
                templateUrl: 'app/solidaridad/view.html',
                controller: 'SolidaridadCtrl',
                module: 'private'
            })
            .state('app.consultas.solidaridadExtracto', {
                url: '/solidaridad/extracto',
                cache: false,
                templateUrl: 'app/solidaridadExtracto/view.html',
                controller: 'SolidaridadExtractoCtrl',
                module: 'private'
            })
            .state('app.consultas.ahorro', {
                url: '/ahorros',
                cache: false,
                templateUrl: 'app/ahorro/view.html',
                controller: 'AhorroCtrl',
                module: 'private'
            })
            .state('app.consultas.ahorroExtracto', {
                url: '/ahorros/extracto',
                cache: false,
                templateUrl: 'app/ahorroExtracto/view.html',
                controller: 'AhorroExtractoCtrl',
                module: 'private'
            })
            .state('app.consultas.tarjetas', {
                url: '/tarjetas',
                cache: false,
                templateUrl: 'app/tarjetas/view.html',
                controller: 'TarjetaCtrl',
                module: 'private'
            })
            .state('app.consultas.tarjetaExtracto', {
                url: '/tarjetas/extracto',
                cache: false,
                templateUrl: 'app/tarjetasExtracto/view.html',
                controller: 'TarjetaExtractoCtrl',
                module: 'private'
            })
            .state('app.consultas.tarjetaExtractoCerrado', {
                url: '/tarjetas/extracto-cerrado',
                cache: false,
                templateUrl: 'app/tarjetasExtractoCerrado/view.html',
                controller: 'TarjetaExtractoCerradoCtrl',
                module: 'private'
            })
            .state('app.consultas.prestamos', {
                url: '/prestamos',
                cache: false,
                templateUrl: 'app/prestamos/view.html',
                controller: 'PrestamoCtrl',
                module: 'private'
            })
            .state('app.consultas.prestamoExtracto', {
                url: '/prestamos/extracto',
                cache: false,
                templateUrl: 'app/prestamosExtracto/view.html',
                controller: 'PrestamoExtractoCtrl',
                module: 'private'
            })
            .state('app.transferencia', {
                url: '/transferencias',
                cache: false,
                templateUrl: 'app/transferencias/view.html',
                controller: 'TransferenciaCtrl',
                module: 'private'
            })
            .state('app.transferenciaExterna', {
                url: '/transferenciaExterna',
                cache: false,
                templateUrl: 'app/transferenciaExterna/view.html',
                controller: 'TransferenciaExternaCtrl',
                module: 'private'
            })
            .state('app.transferenciaExternaConfirmacion', {
                url: '/transferenciaExterna/confirmacion',
                cache: false,
                templateUrl: 'app/transferenciaExterna/confirmacion.html',
                controller: 'ConfirmacionTransExternaCtrl',
                module: 'private'
            })
            .state('app.transferenciaExternaComprobante', {
                url: '/transferenciaExterna/comprobante',
                cache: false,
                templateUrl: 'app/transferenciaExterna/comprobante.html',
                controller: 'ComprobanteTransExternaCtrl',
                module: 'private'
            })
            .state('app.transferenciaExternaEstado', {
                url: '/transferenciaExterna/estado',
                cache: false,
                templateUrl: 'app/transferenciaExternaEstado/view.html',
                controller: 'TransferenciaExternaEstadoCtrl',
                module: 'private'
            })
            .state('app.solicitudes-parametros', {
                url: '/solicitudes-parametros',
                cache: false,
                templateUrl: 'app/solicitudes-parametros/solicitudes-parametros.html',
                controller: 'SolicitudesParametrosCtrl',
                module: 'private'
            })
            .state('app.solicitudes-parametros-', {
                url: '/solicitudes-parametros-',
                cache: false,
                templateUrl: 'app/solicitudes-parametros/solicitudes-parametros.html',
                controller: 'SolicitudesParametrosCtrl',
                module: 'private'
            })
            .state('app.solicitudes-realizadas', {
                url: '/solicitudes-realizadas',
                cache: false,
                templateUrl: 'app/solicitudes-realizadas/solicitudes-realizadas.html',
                controller: 'SolicitudesRealizadasCtrl',
                module: 'private'
            })
            .state('app.transferenciaconfirmacion', {
                url: '/transferencias/confirmacion',
                cache: false,
                templateUrl: 'app/transferencias/confirmacion.html',
                controller: 'ConfirmacionTransferenciaCtrl',
                module: 'private'
            })
            .state('app.transferenciacomprobante', {
                url: '/transferencias/comprobante',
                cache: false,
                templateUrl: 'app/transferencias/comprobante.html',
                controller: 'ComprobanteTransferenciaCtrl',
                module: 'private'
            })
            .state('app.autogestion', {
                url: '/autogestion',
                abstract: true,
                template: '<ui-view></ui-view>',
                module: 'private'
            })
            .state('app.autogestion.datos', {
                url: '/datosPersonales',
                cache: false,
                templateUrl: 'app/datosPersonales/view.html',
                controller: 'DatosPersonalesCtrl',
                module: 'private'
            })
            .state('app.autogestion.cambiarPass', {
                url: '/cambiarContrasenha',
                cache: false,
                templateUrl: 'app/cambiarContrasenha/view.html',
                controller: 'CambiarPassCtrl',
                module: 'private'
            })
            .state('app.autogestion.cuentas', {
                url: '/cuentas',
                cache: false,
                templateUrl: 'app/cuentas/view.html',
                controller: 'CuentasCtrl',
                module: 'private'
            })
            .state('app.autogestion.agregarCuenta', {
                url: '/agregarCuenta',
                cache: false,
                templateUrl: 'app/cuentas/agregar.html',
                controller: 'AgregarCuentaCtrl',
                module: 'private'
            })
            .state('app.autogestion.confirmarCuenta', {
                url: '/confirmarCuenta',
                cache: false,
                templateUrl: 'app/cuentas/confirmacion.html',
                controller: 'ConfirmarCtaFavCtrl',
                module: 'private'
            })
            .state('app.pagos', {
                url: '/pagos',
                abstract: true,
                template: '<ui-view></ui-view>',
                module: 'private'
            })
            .state('app.pagos.aportes', {
                url: '/aportes',
                cache: false,
                templateUrl: 'app/aportesPago/view.html',
                controller: 'AportePagoCtrl',
                module: 'private'
            })
            .state('app.pagos.confirmacionPagoAporte', {
                url: '/aportes/confirmacion',
                cache: false,
                templateUrl: 'app/pagos/confirmacion.html',
                controller: 'ConfirmacionPagoAporteCtrl',
                module: 'private'
            })
            .state('app.pagos.comprobante', {
                url: '/aportes/comprobante',
                cache: false,
                templateUrl: 'app/pagos/comprobante.html',
                controller: 'ComprobantePagoCtrl',
                module: 'private'
            })
            .state('app.pagos.solidaridad', {
                url: '/solidaridad',
                cache: false,
                templateUrl: 'app/solidaridadPago/view.html',
                controller: 'SolidaridadPagoCtrl',
                module: 'private'
            })
            .state('app.pagos.confirmacionPagoSol', {
                url: '/solidaridad/confirmacion',
                cache: false,
                templateUrl: 'app/pagos/confirmacion.html',
                controller: 'ConfirmacionPagoSolCtrl',
                module: 'private'
            })
            .state('app.pagos.comprobanteSol', {
                url: '/solidaridad/comprobante',
                cache: false,
                templateUrl: 'app/pagos/comprobante.html',
                controller: 'ComprobantePagoSolCtrl',
                module: 'private'
            })
            .state('app.pagos.tarjetas', {
                url: '/tarjetas',
                cache: false,
                templateUrl: 'app/tarjetasPago/view.html',
                controller: 'TarjetaPagoCtrl',
                module: 'private'
            })
            .state('app.pagos.confirmacionPagoTcr', {
                url: '/tarjeta/confirmacion',
                cache: false,
                templateUrl: 'app/pagos/confirmacion.html',
                controller: 'ConfirmacionPagoTcrCtrl',
                module: 'private'
            })
            .state('app.pagos.comprobanteTcr', {
                url: '/tarjeta/comprobante',
                cache: false,
                templateUrl: 'app/pagos/comprobante.html',
                controller: 'ComprobantePagoTcrCtrl',
                module: 'private'
            })
            .state('app.pagos.prestamo', {
                url: '/prestamo',
                cache: false,
                templateUrl: 'app/prestamosPago/view.html',
                controller: 'PrestamoPagoCtrl',
                module: 'private'
            })
            .state('app.pagos.confirmacionPagoPmo', {
                url: '/prestamo/confirmacion',
                cache: false,
                templateUrl: 'app/prestamosPago/confirmacion.html',
                controller: 'ConfirmacionPagoPmoCtrl',
                module: 'private'
            })
            .state('app.pagos.comprobantePmo', {
                url: '/prestamo/comprobante',
                cache: false,
                templateUrl: 'app/pagos/comprobante.html',
                controller: 'ComprobantePagoPmoCtrl',
                module: 'private'
            })
            .state('app.pagos.servicios', {
                url: '/servicios',
                cache: false,
                templateUrl: 'app/pagoServicios/view.html',
                controller: 'PagoServicioCtrl',
                module: 'private'
            })
            .state('app.pagos.detalleServicio', {
                url: '/servicios/detalle',
                cache: false,
                templateUrl: 'app/pagoServicios/detalle.html',
                controller: 'DetalleServicioCtrl',
                module: 'private'
            })
            .state('app.pagos.ComprobanteServicio', {
                url: '/servicios/comprobante',
                cache: false,
                templateUrl: 'app/pagoServicios/comprobante.html',
                controller: 'ComprobanteServicioCtrl',
                module: 'private'
            })
            .state('app.transferenciaHistorial', {
                url: '/transferencias/historial',
                cache: false,
                templateUrl: 'app/transferenciaHistorial/view.html',
                controller: 'HistorialTransferenciaCtrl',
                module: 'private'
            })
            .state('app.pagos.ahorroProgramado', {
                url: '/ahorroProgramado',
                cache: false,
                templateUrl: 'app/ahorroProgramadoPago/view.html',
                controller: 'AhorroProgramadoPagoCtrl',
                module: 'private'
            })
            .state('app.pagos.confirmacionPagoAhoProg', {
                url: '/ahoProg/confirmacion',
                cache: false,
                templateUrl: 'app/pagos/confirmacion.html',
                controller: 'ConfirmacionPagoAhoProgCtrl',
                module: 'private'
            })
            .state('app.pagos.comprobanteAhoProg', {
                url: '/ahoProg/comprobante',
                cache: false,
                templateUrl: 'app/pagos/comprobante.html',
                controller: 'ComprobantePagoAhoProgCtrl',
                module: 'private'
            })
            .state('app.impresiones', {
                url: '/impresiones',
                abstract: true,
                template: '<ui-view></ui-view>',
                module: 'private'
            })
            .state('app.impresiones.facturas', {
                url: '/facturas',
                cache: false,
                templateUrl: 'app/facturasImpresion/view.html',
                controller: 'FacturasImpresionCtrl',
                module: 'private'
            })
            .state('app.impresiones.comprobantes', {
                url: '/comprobantes',
                cache: false,
                templateUrl: 'app/comprobantesImpresion/view.html',
                controller: 'ComprobantesImpresionCtrl',
                module: 'private'
            })
            .state('validar-convenio', {
                url: '/validar-convenio',
                cache: false,
                templateUrl: 'app/validar-convenio/view.html',
                controller: 'ValidarConvenioCtrl',
                module: 'public'
            })
            .state('datos-socio', {
                url: '/datos-socio',
                cache: false,
                templateUrl: 'app/datosSocio/view.html',
                controller: 'DatosSocioCtrl',
                module: 'public'

            })
            .state('app.consulta-padron', {
                url: '/padron',
                cache: false,
                templateUrl: 'app/consulta-padron/view.html',
                controller: 'ConsultaPadronCtrl',
                module: 'private'
            })
            .state('app.ver-pdf', {
                url: '/ver-pdf',
                cache: false,
                templateUrl: 'app/ver-pdf/view.html',
                controller: 'VerPdfCtrl',
                module: 'private'
            })
            .state('app.ver-pdf-pdfjs', {
                url: '/ver-pdf-pdfjs',
                cache: false,
                templateUrl: 'app/ver-pdf-pdfjs/ver-pdf-pdfjs.html',
                controller: 'VerPdfPdfJs',
                module: 'private'
            })
            .state('app.ruedaAhorro', {
                url: '/rueda-ahorro',
                cache: false,
                templateUrl: 'app/ruedaAhorro/view.html',
                controller: 'RuedaAhorro',
                module: 'private'
            })
            .state('app.ruedaAhorroConfirmacion', {
                url: '/rueda-ahorro/confirmacion',
                cache: false,
                templateUrl: 'app/ruedaAhorro/confirmacion.html',
                controller: 'RuedaAhorroConfirmacion',
                module: 'private'
            })
            .state('app.ruedaAhorroComprobante', {
                url: '/rueda-ahorro/comprobante',
                cache: false,
                templateUrl: 'app/ruedaAhorro/comprobante.html',
                controller: 'RuedaAhorroConprobante',
                module: 'private'
            })
            .state('app.pagos.ruedaAhorro', {
                url: '/rueda-ahorro',
                cache: false,
                templateUrl: 'app/ruedaAhorroPago/view.html',
                controller: 'RuedaAhorroPagoCtrl',
                module: 'private'
            })
            .state('app.pagos.ruedaAhorroConfirmacion', {
                url: '/rueda-ahorro/confirmacion',
                cache: false,
                templateUrl: 'app/pagos/confirmacion.html',
                controller: 'RuedaAhorroConfirmacionCtrl',
                module: 'private'
            })
            .state('app.pagos.ruedaAhorroComprobante', {
                url: '/rueda-ahorro/comprobante',
                cache: false,
                templateUrl: 'app/pagos/comprobante.html',
                controller: 'RuedaAhorroComprobanteCtrl',
                module: 'private'
            })
            .state('app.simuladorAhorro', {
                url: '/simulador-ahorro',
                cache: false,
                templateUrl: 'app/simuladorAhorro/view.html',
                controller: 'SimuladorAhorroCtrl',
                module: 'private'
            })
            .state('app.simuladorAhorroDetalles', {
                url: '/simulador-ahorro/detalles',
                cache: false,
                templateUrl: 'app/simuladorAhorro/detalles.html',
                controller: 'SimuladorAhorroDetallesCtrl',
                module: 'private'
            })
            .state('app.manifestacionBienes', {
                url: '/manifestacion-bienes/ingresos',
                cache: false,
                templateUrl: 'app/manifestacion-bienes/view.html',
                controller: 'ManifestacionBienesCtrl',
                module: 'private'
            })
            .state('app.manifestacionBienesEgresos', {
                url: '/manifestacion-bienes/egresos',
                cache: false,
                templateUrl: 'app/manifestacion-bienes/egresos.html',
                controller: 'ManifestacionBienesEgresosCtrl',
                module: 'private'
            })
            .state('app.manifestacionBienesConfirmacion', {
                url: '/manifestacion-bienes/confirmacion',
                cache: false,
                templateUrl: 'app/manifestacion-bienes/confirmacion.html',
                controller: 'ManifestacionBienesConfirmacionCtrl',
                module: 'private'
            })
            .state('app.manifestacionBienesComprobante', {
            url: '/manifestacion-bienes/comprobante',
            cache: false,
            templateUrl: 'app/manifestacion-bienes/comprobante.html',
            controller: 'ManifestacionBienesComprobanteCtrl',
            module: 'private'
            })
            .state('app.ahorroProgramado', {
                url: '/ahorro-programado',
                cache: false,
                templateUrl: 'app/ahorro-programado/view.html',
                controller: 'AhorroProgramado',
                module: 'private'
            })
            .state('app.ahorroProgramadoConfirmacion', {
                url: '/ahorro-programado/confirmacion',
                cache: false,
                templateUrl: 'app/ahorro-programado/confirmacion.html',
                controller: 'AhorroProgramadoConfirmacion',
                module: 'private'
            })
            .state('app.ahorroProgramadoComprobante', {
                url: '/ahorro-programado/comprobante',
                cache: false,
                templateUrl: 'app/ahorro-programado/comprobante.html',
                controller: 'AhorroProgramadoComprobante',
                module: 'private'
            })
            .state('app.consultas.detallesAhorro', {
                url: '/ahorros/detalles',
                cache: false,
                templateUrl: 'app/detalle-ahorro/view.html',
                controller: 'DetallesAhorroCtrl',
                module: 'private'
            })
            .state('app.pagos.otrosPagos', {
                url: '/otros-pagos',
                cache: false,
                templateUrl: 'app/otros-pagos/view.html',
                controller: 'OtrosPagosCtrl',
                module: 'private'
            })
            .state('app.pagos.otrosPagosConfirmacion', {
                url: '/otros-pagos/confirmacion',
                cache: false,
                templateUrl: 'app/otros-pagos/confirmacion.html',
                controller: 'OtrosPagosConfirmacionCtrl',
                module: 'private'
            })
            .state('app.pagos.otrosPagosComprobante', {
                url: '/otros-pagos/comprobante',
                cache: false,
                templateUrl: 'app/otros-pagos/comprobante.html',
                controller: 'OtrosPagosComprobanteCtrl',
                module: 'private'
            });

        $urlRouterProvider.otherwise('login');
        $qProvider.errorOnUnhandledRejections(false);


        var sessionInterceptor = ['$location', '$rootScope', '$injector', '$q', function ($location, $rootScope, $injector, $q) {
            return {
                'response': function (resp) {
                    $rootScope.valor = $location.path();
                    //                                if (
                    //                                        $location.path().indexOf('login') < 0
                    //                                        && $location.path().indexOf('servicios') < 0
                    //                                        && $location.path().indexOf('no-menu') < 0
                    //                                        && localStorage.getItem("autenticado") != "true") {
                    //                                    $location.path("/");
                    //                                    window.location.reload();
                    //                                } else if (resp.headers()['content-type'] === "application/json" ||
                    //                                        resp.headers()['content-type'] === 'application/json;charset=UTF-8') {
                    //                                    if (resp.data.status === 999) {
                    //                                        $location.path("/");
                    //                                        window.location.reload();
                    //                                    }
                    //                                    var response = resp.data;
                    //                                    if (response.estado == 0) {
                    //                                        if (response.mensaje != 'OK') {
                    //                                            toastr.success(response.mensaje, 'Exito!');
                    //                                        }
                    //                                    } else {
                    //                                        toastr.error(response.mensaje, 'Atención!');
                    //                                    }
                    //                                }
                    return resp;
                },
                'responseError': function (resp) {
                    if (resp.status == 401) {
                        unBlockUI();
                        showNotification('alerta', "Su sesi&oacute;n ha expirado!");
                        $location.path('/login');
                        localStorage.clear();
                        /*setTimeout(function () {
                         $location.path('/login');
                         }, 1500);*/

                    }
                    return $q.rejection(resp);
                }
            };
        }];

        $httpProvider.interceptors.push(sessionInterceptor);

    }]);
