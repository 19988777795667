app.controller('LoginCtrl', ['$scope', '$state', '$sessionStorage', 'MainFactory', '$localStorage', '$rootScope', '$location',
    function ($scope, $state, $sessionStorage, MainFactory, $localStorage, $rootScope, $location) {

        $rootScope.$on('$stateChangeStart', function (e, toState, toParams, fromState, fromParams) {
            $("#el-nav").hide();
            if (toState.module === 'private' && !$localStorage.identificadorSesion) {
                e.preventDefault();
                $state.go('login');
            }
            //console.log('toState.url ', toState.url);
            if (toState.url != "/cambiarContrasenha" && toState.url != '/login' && $localStorage.datosUsuario && $localStorage.datosUsuario.cambiarPass) {
                e.preventDefault();
                $state.go('app.autogestion.cambiarPass');
            }
        });

        $scope.pin = '';
        $scope.login = {};
        $localStorage.menuActive = 0;
        unBlockUI();
        $('.carousel-prev').hide();
        $('.carousel-next').hide();
        $scope.img = {};
        $scope.img.imagenes = [];
        $scope.img.cantidad = {};
        $scope.img.popUp = "";

        MainFactory.obtenerImagen().then(function (response) {
            if (response.data.estado === 0) {
                $scope.img.imagenes = response.data.carrusel;
                $scope.img.imagenes.sort();
                $scope.img.cantidad = response.data.cantidadCarrusel;
            }
        });

        $('#modal-login').modal('show');

        $scope.openModal = function () {
            $('#modal-login').modal('show');
        };
        MainFactory.obtenerImagenPopUp().then(function (response) {
            if (response.data.estado === 0) {
                $scope.img.popUp = response.data.popUp;
                $('#modal-promociones').modal('show');
            }
        });


        $scope.ingresar = function (form) {
            if (form.$valid || true) {
                var parametrosLogin = {
                    "operacion": "43",
                    "producto": "8",
                    "entidad": "3",
                    "cliente": "1",
                    "presentador": "1",
                    "entrada": {
                        "idSocio": $scope.login.usuario,
                        "conSocio": $scope.pin,
                        "ipSocio": "127.0.0.1",
                        "ingCanal": "1"
                    }
                };
                blockUI();
                MainFactory.login(parametrosLogin)
                    .then(function (response) {
                            $scope.dataidentificadorSesion;
                            if (response.data.estado === 0) {
                                if (response.data.data.sesion.estado === 0) {
                                    $scope.obtenerDatosPadron(response);
                                }
                            } else if (response.data.estado === 1) {
                                $scope.pin = null;
                                showNotification('alerta', response.data.mensaje);
                                unBlockUI();
                            } else {
                                showNotification('alerta', response.data.mensaje);
                                unBlockUI();
                            }
                        },
                        function (response) {
                            unBlockUI();
                            showNotification('alerta', "Ha ocurrido un error, intente nuevamente");
                        });
            } else {
                showNotification('alerta', 'Campos Incompletos');
            }
        };

        $scope.obtenerDatosPadron = function (responseLogin) {
            try {
                blockUI();
                MainFactory.getDatosPadron().then(function (response) {
                    console.log('response', response);
                    $scope.obtenerHabilitacionCirculo(responseLogin, response);
                }, function (error) {
                    console.error(error)
                    $localStorage.identificadorSesion = responseLogin.data.data.sesion.idSesion;
                    $localStorage.usuario = $scope.login.usuario;
                    $localStorage.datosUsuario = responseLogin.data.data.datosSocio.sesionSocDatos[0];
                    $localStorage.datoSesion = responseLogin.data.data.sesion;
                    unBlockUI();
                    $state.go('app.inicio');
                });
            } catch (err) {
                console.error(err);
                unBlockUI();
                $localStorage.identificadorSesion = responseLogin.data.data.sesion.idSesion;
                $localStorage.usuario = $scope.login.usuario;
                $localStorage.datosUsuario = responseLogin.data.data.datosSocio.sesionSocDatos[0];
                $localStorage.datoSesion = responseLogin.data.data.sesion;
                unBlockUI();
                $state.go('app.inicio');
            }
        }

        $scope.obtenerHabilitacionCirculo = function (responseLogin, responseDatosPadron) {
            try {
                MainFactory.getHabilitacionCirculo().then(function (response) {
                    if (response.data.estado === 0) {
                        console.log('response habilitacion', response);
                        $localStorage.habilitacionCirculo = {};
                        $localStorage.habilitacionCirculo = response.data;
                        if (responseDatosPadron.data.estado === 0) {
                            $localStorage.datosPadron = {};
                            $localStorage.datosPadron = responseDatosPadron.data;
                        }
                        $localStorage.identificadorSesion = responseLogin.data.data.sesion.idSesion;
                        $localStorage.usuario = $scope.login.usuario;
                        $localStorage.datosUsuario = responseLogin.data.data.datosSocio.sesionSocDatos[0];
                        $localStorage.datoSesion = responseLogin.data.data.sesion;
                        console.log('CARGA DE DATOS EN LOCALSTORAGE COMPLETA');
                        unBlockUI();
                        $state.go('app.inicio');
                    } else {
                        if (responseDatosPadron.data.estado === 0) {
                            $localStorage.datosPadron = {};
                            $localStorage.datosPadron = responseDatosPadron.data;
                        }
                        $localStorage.identificadorSesion = responseLogin.data.data.sesion.idSesion;
                        $localStorage.usuario = $scope.login.usuario;
                        $localStorage.datosUsuario = responseLogin.data.data.datosSocio.sesionSocDatos[0];
                        $localStorage.datoSesion = responseLogin.data.data.sesion;
                        unBlockUI();
                        $state.go('app.inicio');
                    }

                }, function (error) {
                    console.error(error)
                    $localStorage.identificadorSesion = responseLogin.data.data.sesion.idSesion;
                    $localStorage.usuario = $scope.login.usuario;
                    $localStorage.datosUsuario = responseLogin.data.data.datosSocio.sesionSocDatos[0];
                    $localStorage.datoSesion = responseLogin.data.data.sesion;
                    unBlockUI();
                    $state.go('app.inicio');
                });

            } catch (err) {
                console.error(err);
                unBlockUI();
                $localStorage.identificadorSesion = responseLogin.data.data.sesion.idSesion;
                $localStorage.usuario = $scope.login.usuario;
                $localStorage.datosUsuario = responseLogin.data.data.datosSocio.sesionSocDatos[0];
                $localStorage.datoSesion = responseLogin.data.data.sesion;
                unBlockUI();
                $state.go('app.inicio');
            }
        }

    }]);

app.filter('capitalize', function () {
    return function (input) {
        return (angular.isString(input) && input.length > 0) ? input.charAt(0).toUpperCase() + input.substr(1).toLowerCase() : input;
    }
});
