app.controller('InicioCtrl', ['$scope', 'MainFactory', '$state', '$sessionStorage', '$localStorage', '$rootScope', '$location', 'NgTableParams', '$filter', 'MenuFactory',
    function ($scope, MainFactory, $state, $sessionStorage, $localStorage, $rootScope, $location, NgTableParams, $filter, MenuFactory) {
        try {
            $scope.nroSocio = $localStorage.datosUsuario.nroSocio;
            $scope.datoSocio = $localStorage.datoSesion;
        } catch (err) {
        }
        $scope.productos;
        $scope.aporte = [];
        $scope.solidaridad = [];
        $scope.ahorro = [];
        $scope.prestamo = [];
        $scope.tarjeta = [];
        $scope.otros = [];
        $scope.lista = [];
        $scope.categorias = ['APORTE', 'SOLIDARIDAD', 'AHORRO', 'PRESTAMO', 'TARJETA'];

        parametros = {
            operacion: "73",
            producto: "18",
            entidad: "3",
            cliente: "1",
            presentador: "1",
            entrada: {
                iIdSesion: $localStorage.identificadorSesion,
                iTipSaldo: "TDS"
            }
        };

        $('#myCarousel').carousel({
            interval: 500
        });

        blockUI();
        MainFactory.doRequest(parametros)
                .then(function (response) {
                    if (response.data.estado == 0) {

                        $scope.productos = response.data.data.oDatProducto;

                        $scope.productos.forEach(function (data) {
                            if (data.wbProducto == 'APORTE') {
                                $scope.aporte.push(data);
                            } else if (data.wbProducto == 'SOLIDARIDAD') {
                                $scope.solidaridad.push(data);
                            } else if (data.wbProducto == 'AHORRO') {
                                $scope.ahorro.push(data);
                            } else if (data.wbProducto == 'PRESTAMO') {
                                $scope.prestamo.push(data);
                            } else if (data.wbProducto == 'TARJETA') {
                                $scope.tarjeta.push(data);
                            } else {
                                $scope.otros.push(data);
                            }
                        });

                        $scope.others = [];
                        var i = 0;
                        $scope.productos.forEach(function (data) {
                            if (data.concepto != $scope.categorias[i]) {
                                $scope.others.push(data);
                            }
                            i++;
                        });
                        unBlockUI();
                    } else {
                        showNotification('alerta', response.data.mensaje);
                        unBlockUI();
                    }
                },
                        function (response) {
                            unBlockUI();
                            showNotification('alerta', "No se pudo obtener resumen de cuentas ");
                        });

        $scope.irExtractoAporte = function () {
            $state.go('app.consultas.aportesExtracto');
        };

        $scope.irExtractoSolidaridad = function () {
            $state.go('app.consultas.solidaridadExtracto');
        };

        $scope.verExtractoAhorro = function (dato) {
            $localStorage.nroAhorro = dato;
            $state.go('app.consultas.ahorroExtracto');
        };

        $scope.verExtractoTarjeta = function (dato) {
            $localStorage.nroTarjeta = dato;
            $state.go('app.consultas.tarjetaExtracto');
        };

        $scope.verExtractoPrestamo = function (dato) {
            $localStorage.cabeceraPmo = dato;
            $state.go('app.consultas.prestamoExtracto');
        };

        $scope.verExtractoAhorroDetalle = function (ahorro) {
            $localStorage.ahorro = ahorro;
            $state.go('app.consultas.detallesAhorro');
            $rootScope.options = MenuFactory.active($localStorage.menuActive);
        }

        $scope.descargarPdf = function () {

            var json = {
                "nroSocio": '' + $localStorage.datosUsuario.nroSocio,
                "nombre": '' + $localStorage.datosUsuario.nombre,
                "cedula": "" + $localStorage.usuario,
                detalle1: $scope.aporte,
                detalle2: $scope.solidaridad,
                detalle3: $scope.ahorro,
                detalle4: $scope.prestamo,
                detalle5: $scope.tarjeta,
                detalle6: $scope.otros,
            }

            var parametros = {
                url: "/SwitcherSanCristobal-web/extractoCuentas",
                method: "POST",
                data: json
            };

            blockUI();
            MainFactory.getPdf(parametros).then(
                    function (response) {
                        if (response.data.estado == 0) {
                            window.location.replace('/SwitcherSanCristobal-web/extractoCuentas');
                            unBlockUI();
                        } else {
                            showNotification('alerta', response.data.mensaje);
                            unBlockUI();
                        }
                    },
                    function (response) {
                        unBlockUI();
                        $scope.mensaje = "Error en la operacion.";
                    }
            );
        }
    }]);
