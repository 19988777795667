'use strict';

var app = angular.module('app');

app.filter('enmascararTarjeta', function(){
  return function(tarjeta){
    if(!tarjeta){
        return "";
    } else {
        var longitud = tarjeta.length/4;
        var mascara = "";
        for(var i = 0; i < longitud; i++){
            mascara = mascara + tarjeta.charAt(i);
        }
        for(var i = longitud; i < longitud*3; i++){
            mascara = mascara + "*";
        }
        for(var i = longitud*3; i < tarjeta.length; i++){
            mascara = mascara + tarjeta.charAt(i);
        }
        return mascara;
    }
  };
});

app.filter('asHtml', ['$sce', function ($sce) {
    return function (text) {
        return $sce.trustAsHtml(text);
    };
}]);


