app.controller('PrestamoCtrl', ['$scope', '$state', '$sessionStorage', '$localStorage', '$rootScope', '$location', 'MainFactory', 'MenuFactory', 'NgTableParams', '$filter',
    function ($scope, $state, $sessionStorage, $localStorage, $rootScope, $location, MainFactory, MenuFactory, NgTableParams, $filter) {

        $scope.prestamos;
        $localStorage.menuActive = 1;
        $scope.nroSocio = $localStorage.datosUsuario.nroSocio;

        parametros = {
            operacion: "73",
            producto: "18",
            entidad: "3",
            cliente: "1",
            presentador: "1",
            entrada: {
                iIdSesion: $localStorage.identificadorSesion,
                iTipSaldo: "PMO"
            }
        };

        blockUI();
        MainFactory.doRequest(parametros)
            .then(function (response) {

                if (response.data.estado == 0) {

                    $scope.prestamos = response.data.data.oDatProducto;

                    $scope.tableParams = new NgTableParams({
                        page: 1,
                        count: 10,
                        sorting: {
                            name: 'tipo'
                        }
                    }, {
                            counts: [],
                            total: $scope.prestamos.length,
                            getData: function (params) {
                                var orderedData = params.sorting() ? $filter('orderBy')($scope.prestamos, params.orderBy()) : $scope.prestamos;
                                return (orderedData.slice((params.page() - 1) * params.count(), params.page() * params.count()));
                            }
                        });
                    $localStorage.prestamos = $scope.prestamos;
                    unBlockUI();
                } else {

                    showNotification('alerta', response.data.mensaje);
                    unBlockUI();
                }
            });

        $scope.verExtractoPrestamo = function (prestamo) {
            $localStorage.cabeceraPmo = prestamo;
            $state.go('app.consultas.prestamoExtracto');
            $rootScope.options = MenuFactory.active($localStorage.menuActive);
        };

        $scope.irPagoPrestamo = function (dato) {
            $localStorage.cabPrestamo = dato;
            $rootScope.options = MenuFactory.active(4);
            $state.go('app.pagos.prestamo');
        };

    }]);