app.controller('ManifestacionBienesCtrl', ['$scope', '$localStorage', 'MainFactory', '$state',
    function ($scope, $localStorage, MainFactory, $state) {
        $scope.ingresos = [];
        $scope.condicion = false;
        $scope.tipoAuth = {};

        let json = {
            tipo: 'I'
        }
        blockUI();
        MainFactory.conceptosManifestacionBienes(json).then(function (response) {
                if (response.data.estado === 0) {
                    $scope.ingresos = response.data.data;
                    for (var i = 0; i < $scope.ingresos.length; i++) {
                        $scope.formatearMonto($scope.ingresos[i].importe, i);
                    }
                } else {
                    showNotification('alerta', response.data.mensaje);
                }
                unBlockUI();
            },
            function (response) {
                unBlockUI();
                showNotification('alerta', "No se pudieron obtener los conceptos");
            });

        $scope.checkCondicion = function () {
            $scope.condicion = !$scope.condicion;
        };

        $scope.confirmar = function (form) {
            blockUI();
            if (!form.$valid) {
                return;
            }
            for (var i = 0; i < $scope.ingresos.length; i++) {
                $scope.ingresos[i].importe = formatoMonto($scope.ingresos[i].importe);
            }
            $localStorage.ingresos = $scope.ingresos;
            $state.go('app.manifestacionBienesEgresos');
        }

        $scope.formatearMonto = function (num, index) {
            if (num != null) {
                const separador = '.';
                num += '';
                num = eliminarPuntos(num);
                const splitStr = num.split('.');

                let splitLeft = splitStr[0];
                const regx = /(\d+)(\d{3})/;
                while (regx.test(splitLeft)) {
                    splitLeft = splitLeft.replace(regx, `$1${separador}$2`);
                }

                $scope.ingresos[index].importe = splitLeft;
            }
        };

    }]
);

app.controller('ManifestacionBienesEgresosCtrl', ['$scope', '$localStorage', 'MainFactory', '$state',
    function ($scope, $localStorage, MainFactory, $state) {
        $scope.datos = $localStorage.datosRequest

        let json = {
            tipo: 'E'
        }
        blockUI();
        MainFactory.conceptosManifestacionBienes(json).then(function (response) {
                if (response.data.estado === 0) {
                    $scope.ingresos = response.data.data;
                    for (var i = 0; i < $scope.ingresos.length; i++) {
                        $scope.formatearMonto($scope.ingresos[i].importe, i);
                    }
                } else {
                    showNotification('alerta', response.data.mensaje);
                }
                unBlockUI();
            },
            function (response) {
                unBlockUI();
                showNotification('alerta', "No se pudieron obtener los conceptos");
            });

        $scope.checkCondicion = function () {
            $scope.condicion = !$scope.condicion;
        };

        $scope.confirmar = function (form) {
            blockUI();
            if (!form.$valid) {
                return;
            }
            for (var i = 0; i < $scope.ingresos.length; i++) {
                $scope.ingresos[i].importe = formatoMonto($scope.ingresos[i].importe);
            }
            $localStorage.egresos = $scope.ingresos;
            $state.go('app.manifestacionBienesConfirmacion');
        }

        $scope.formatearMonto = function (num, index) {
            if (num != null) {
                const separador = '.';
                num += '';
                num = eliminarPuntos(num);
                const splitStr = num.split('.');

                let splitLeft = splitStr[0];
                const regx = /(\d+)(\d{3})/;
                while (regx.test(splitLeft)) {
                    splitLeft = splitLeft.replace(regx, `$1${separador}$2`);
                }

                $scope.ingresos[index].importe = splitLeft;
            }
        };
    }]
);
