/*$(window).unload(function () {
    alert('hola');
    return "Bye now!";
});*/

function blockUI() {
    $.blockUI.defaults.css = {};
    $.blockUI({
        message: '<div align="center">' + '<h1 style="color: #9A9A9A;"><i class="fa-li fa fa-circle-o-notch fa-spin fa-2x"></i></h1>', //'<div align="center">' + '<h1 style="color: #9A9A9A;"><i class="fa-li fa fa-circle-o-notch fa-spin fa-2x"></i></h1>'  
        overlayCSS: { cursor: 'auto' },
        css: {
            position: 'fixed',
            top: '50%',
            left: '50%',
            'margin-right': '-30%',
            transform: 'translate( -50%,-50%)',
            '-ms-transform': 'translate( -50%,-50%)', //IE 9 
            '-webkit-transform': 'translate(-50%,- 50%)', //Chrome, Safari, Opera
            '-webkit-border-radius': '10px',
            '-moz-border-radius': '10px',
            opacity: .3,
            color: '#fff'
        }
    });
}

function unBlockUI() {
    $.unblockUI();
}

function showNotification(tipo, msj) {

    if (msj != "undefined") {
        var icon, message, color;
        if (tipo == 'alerta') {
            icon = "ti-alert";
            message = "<b>Atención!</b><br>" + msj;
            color = '#FFF200'; /* #FFF200 */
        } else if (tipo == 'info') {
            icon = "ti-info";
            message = "<b>Exito!</b><br>" + msj;
            color = 2;
        }

        $.notify({
            icon: icon,
            message: message

        }, {
            type: type[color],
            timer: 400,
            placement: {
                from: 'top',
                align: 'right'
            }
        });
    }
}

function showNotificationInfo(msj) {

    if (msj != "undefined") {

        var icon, message, color;

        /*if (tipo== 'alerta'){
         icon= "ti-alert";
         message= "<b>Atención!</b><br>"+msj;
         color= '#FFF200'; /
         }else if (tipo== 'info'){
         icon= "ti-info";
         message= "<b>Exito!</b><br>"+msj;
         color= '#174785';
         }*/
        color = 2;
        $.notify({
            icon: "ti-info",
            message: "<b>Exito!</b><br>" + msj

        }, {
            type: type[color],
            timer: 400,
            placement: {
                from: 'top',
                align: 'right'
            }
        });
    }
}

function format(input) {
    var num = input.value.replace(/[^0-9]/g, '');
    if (!isNaN(num)) {
        num = num.toString().split('').reverse().join('').replace(/(?=\d*\.?)(\d{3})/g, '$1.');
        num = num.split('').reverse().join('').replace(/^[\.]/, '');
        input.value = num;
    }
}

function isNumber(n) {
    return !isNaN(parseFloat(n)) && isFinite(n);
}

function formatoMonto(input) {

    monto = input.replace(/\./g, '');
    return parseInt(monto);

}

function getFecha() {

    var d = new Date;
    dformat = d.getDate() + "/" + (d.getMonth() + 1) + "/" + d.getFullYear() + ' ' +
        d.getHours() + ":" + d.getMinutes() + ":" + d.getSeconds();

    return dformat;

}

function getDate() {

    var d = new Date;
    if (d.getDate() < 10) {
        dia = '0' + d.getDate();
    } else {
        dia = d.getDate();
    }
    if (d.getMonth() + 1 < 10) {
        mes = '0' + String(d.getMonth() + 1)
    } else {
        mes = d.getMonth() + 1;
    }
    dformat = dia + "/" + mes + "/" + d.getFullYear();
    return dformat;

}

function getAnhoMes() {

    var f = new Date();
    var mes;
    var month = f.getMonth() + 1;

    if (month < 10) {
        mes = '0' + month;
    }

    var anho = f.getFullYear();

    return anho + "-" + mes;
}

function generarFechas() {
    var listFechas = [];
    var today = new Date();
    var anho = today.getFullYear();
    var mes = today.getMonth() + 1;
    for (var i = mes; i >= 1; i--) {
        var jsonFecha = {};
        jsonFecha.mes = anho.toString() + ";" + i.toString();
        jsonFecha.descripcion = getMes(i) + '-' + anho;
        listFechas.push(jsonFecha);
    }
    for (var i = 12; i >= mes; i--) {
        var jsonFecha = {};
        jsonFecha.mes = (anho - 1).toString() + ";" + i.toString();
        jsonFecha.descripcion = getMes(i) + '-' + (anho - 1);
        listFechas.push(jsonFecha);
    }
    return listFechas;
}

function getMes(mesNum) {
    if (mesNum === 1) {
        return "Enero";
    } else if (mesNum === 2) {
        return "Febrero";
    } else if (mesNum === 3) {
        return "Marzo";
    } else if (mesNum === 4) {
        return "Abril";
    } else if (mesNum === 5) {
        return "Mayo";
    } else if (mesNum === 6) {
        return "Junio";
    } else if (mesNum === 7) {
        return "Julio";
    } else if (mesNum === 8) {
        return "Agosto";
    } else if (mesNum === 9) {
        return "Septiembre";
    } else if (mesNum === 10) {
        return "Octubre";
    } else if (mesNum === 11) {
        return "Noviembre";
    } else if (mesNum === 12) {
        return "Diciembre";
    }
}

function getMesExtracto(input) {
    var month = parseInt(input.slice(5, 7));
    return month;
}

function getMesExtractoMM(input) {
    var month = parseInt(input.slice(5, 7));
    if (month < 10) {
        return '0' + month.toString();
    } else {
        return month.toString();
    }
}

function getAnhoExtracto(input) {
    var year = parseInt(input.slice(0, 4));
    return year;
}

function getAnhoExtractoAA(input) {
    var year = input.slice(2, 4);
    return year;
}

function number_format(amount, decimals) {

    amount += ''; // por si pasan un numero en vez de un string
    amount = parseFloat(amount.replace(/[^0-9\.]/g, '')); // elimino cualquier cosa que no sea numero o punto

    decimals = decimals || 0; // por si la variable no fue fue pasada

    // si no es un numero o es igual a cero retorno el mismo cero
    if (isNaN(amount) || amount === 0)
        return parseFloat(0).toFixed(decimals);

    // si es mayor o menor que cero retorno el valor formateado como numero
    amount = '' + amount.toFixed(decimals);

    var amount_parts = amount.split('.'),
        regexp = /(\d+)(\d{3})/;

    while (regexp.test(amount_parts[0]))
        amount_parts[0] = amount_parts[0].replace(regexp, '$1' + '.' + '$2');

    return amount_parts.join('.');
}

function getNroCi(input) {
    //var myStr = "asdasrasdasd$hdghdfgsdfgf";
    //myStr = myStr.substring(0, myStr.indexOf("$"));
    return input.substring(0, input.indexOf("-"));
}

function getCodCuenta(input) {
    //var myStr = "asdasrasdasd$hdghdfgsdfgf";
    //myStr = myStr.substring(myStr.indexOf("$")+1, myStr.length);
    return input.substring(input.indexOf("-") + 1, input.length);
}

function formatTicket(input) {
    return input.split('\\n').join("<br />");
}
function formatTicketComp(input) {
    return input.split('\\n').join("<br />");
}


function mouseoverPass(id) {
    var obj = document.getElementById(id);
    obj.type = "text";
}

function mouseoutPass(id) {
    var obj = document.getElementById(id);
    obj.type = "password";
}

function eliminarPuntos(num) {
    var i = 0;
    nuevoNum = '';
    while (i < num.length) {

        if (num[i] !== '.') {
            nuevoNum += num[i];
        }
        i++;
    }
    return nuevoNum;
}

function getLastDay(year, month) {
    var ultimoDia = new Date(year, month, 0);
    return ultimoDia.getDate();
};

function filtrarListadoSipap(input) {
    return function (parametro) {
        if (parametro.estado.toUpperCase() == input.toUpperCase()) {
            return parametro;
        }
    }
}

function formatoFechaLong(fecha) {
    date = new Date(fecha)
    var dd = date.getDate();
    var mm = date.getMonth() + 1;
    var yyyy = date.getFullYear();
    if (dd < 10) { dd = '0' + dd }
    if (mm < 10) { mm = '0' + mm };
    return dd + '/' + mm + '/' + yyyy;
};
