app.controller('ConfirmacionTransferenciaCtrl', ['$scope', 'MainFactory', '$state', '$sessionStorage', '$localStorage', '$rootScope', '$location',
    function ($scope, MainFactory, $state, $sessionStorage, $localStorage, $rootScope, $location) {

        $scope.confirmacion = $localStorage.transferencia;
        $scope.socio = $localStorage.datosUsuario;
        $scope.pin;
        $scope.tipo;

        $scope.setTipoVerificacion = function (tipo) {
            $scope.pin = '';
            $scope.tipo = tipo;
        }

        $scope.verificarOtp = function () {
            blockUI();
            MainFactory.verifyOtp().then(
                function (response) {
                    if (response.data.estado == 0) {
                        $scope.realizarTransferencia();
                    } else {
                        unBlockUI();
                        showNotification('alerta', response.data.mensaje);
                    }
                },
                function (response) {
                    unBlockUI();
                    showNotification('alerta', "No se pudo realizar el pago");
                });
        }

        $scope.transferir = function (form) {
            if (!form.$valid) {
                return;
            }

            if ($scope.tipo) {
                if ($scope.pin == '') {
                    showNotification('alerta', 'Ingrese su código de verificación');
                } else {
                    if ($scope.tipo == "PIN") {
                        $scope.realizarTransferencia();
                    } else {
                        $scope.verificarOtp();
                    }
                }
            } else {
                showNotification('alerta', 'Seleccione un método de verificación');
            }
        }

        $scope.realizarTransferencia = function () {
            blockUI();
            if ($scope.confirmacion.monto > $scope.confirmacion.cuentaOrigenSaldo) {
                showNotification('alerta', 'Saldo Insuficiente para completar la transaccion');
                unBlockUI();
                $state.go('app.transferencia');
            } else {
                var parametros = {
                    operacion: "72",
                    producto: "18",
                    entidad: "3",
                    cliente: "1",
                    presentador: "1",
                    entrada: {
                        iIdSesion: $localStorage.identificadorSesion,
                        iCtaOrigen: $scope.confirmacion.cuentaOrigen,
                        iCtaDestino: $scope.confirmacion.cuentaDestino,
                        iMtoTransf: $scope.confirmacion.monto,
                        tipoAuth: {
                            tipo: $scope.tipo,
                            valor: $scope.pin
                        },
                        idSocio: $localStorage.usuario
                    }
                };

                MainFactory.doRequest(parametros).then(
                    function (response) {
                        if (response.data.estado == 0) {
                            unBlockUI();
                            $localStorage.pagoTransferencia = response.data.data.oDatTransf[0];
                            $localStorage.pagoTransferencia.cuentaOrigenDescripcion = $scope.confirmacion.cuentaOrigenDescripcion;
                            $localStorage.pagoTransferencia.cuentaDestinoDescripcion = $scope.confirmacion.cuentaDestinoDescripcion;
                            $localStorage.pagoTransferencia.datos = $scope.confirmacion;
                            $state.go('app.transferenciacomprobante');
                            //showNotification('exito', 'La transferencia fue realizada con exito');
                        } else {
                            unBlockUI();
                            $state.go('app.transferencia');
                            showNotification('alerta', response.data.mensaje);
                        }
                    },
                    function (response) {
                        unBlockUI();
                        showNotification('alerta', "No se pudo realizar la transferencia");
                    });
            }
        }

        $scope.cancelar = function () {
            $state.go('app.transferencia');
        }
    }]);