app.controller('AhorroProgramado', ['$scope', '$localStorage', 'MainFactory', '$state',
    function ($scope, $localStorage, MainFactory, $state) {

        $scope.planesAhorro = [];
        $scope.datos = {};
        $scope.nroSocio = $localStorage.datosUsuario.nroSocio;
        $scope.cajasAhorro = [];
        $scope.ahorroProgramadoSelected = {};

        var cuentasAhorro = {
            operacion: "73",
            producto: "18",
            entidad: "3",
            cliente: "1",
            presentador: "1",
            entrada: {
                iIdSesion: $localStorage.identificadorSesion,
                iTipSaldo: "AHR"
            }
        };

        blockUI();
        MainFactory.doRequest(cuentasAhorro).then(function (response) {
                if (response.data.estado === 0) {
                    $scope.cajasAhorro = response.data.data.oDatProducto;
                } else {
                    showNotification('alerta', response.data.mensaje);
                }
                unBlockUI();
            },
            function (response) {
                unBlockUI();
                showNotification('alerta', "No se pudo obtener Cuentas de Ahorro");
            });

        blockUI();
        MainFactory.obtenerPlanesAhorroProgramado().then(function (response) {
            if (response.data.estado === 0) {
                $scope.planesAhorro = response.data.data;
            } else {
                showNotification('alerta', response.data.mensaje);
            }
            unBlockUI();
        });

        $scope.confirmar = function (form) {
            if (!form.$valid) {
                return;
            }
            let montoNumber = formatoMonto($scope.datos.monto);

            blockUI();
            if ($scope.datos.cuenta && $scope.ahorroProgramadoSelected && $scope.monto !== null) {
                if (isNumber(formatoMonto($scope.datos.monto)) && ((montoNumber <= $scope.ahorroProgramadoSelected.montoMaximo) &&
                    (montoNumber >= $scope.ahorroProgramadoSelected.montoMinimo))) {

                    const request = {
                        "idSesion": $localStorage.identificadorSesion,
                        "cuentaDebito": $scope.datos.cuenta.wbNumero,
                        "idProducto": $scope.ahorroProgramadoSelected.codigo,
                        "plazo": $scope.ahorroProgramadoSelected.plazo,
                        "tasa": $scope.ahorroProgramadoSelected.tasa,
                        "monto": formatoMonto($scope.datos.monto)
                    };
                    const datosConfirmacion = {
                        ahorroProgramado: $scope.ahorroProgramadoSelected,
                        cuentaDebito: $scope.datos.cuenta,
                        datos: $scope.datos,
                    };

                    $localStorage.datosConfirmacion = datosConfirmacion;
                    $localStorage.datosRequest = request;
                    $state.go('app.ahorroProgramadoConfirmacion');
                } else {
                    unBlockUI();
                    showNotification('alerta', "El monto debe estar en el rango especificado.");
                }
            } else {
                unBlockUI();
                showNotification('alerta', "Seleccione todos los campos");
            }
        }

        $scope.limpiarCampos = function () {
            $scope.ahorroProgramadoSelected = {};
            $scope.datos = {};
        };

    }]
);
