app.controller('ComprobanteServicioCtrl', ['$scope', 'MainFactory', 'PagoServicioFactory', '$state', '$sessionStorage', '$localStorage', '$rootScope', '$location', 'MenuFactory', 'NgTableParams', '$filter',
    function ($scope, MainFactory, PagoServicioFactory, $state, $sessionStorage, $localStorage, $rootScope, $location, MenuFactory, NgTableParams, $filter) {
        $scope.servicio = $localStorage.comprobantePagoServicio.servicio;
        $localStorage.menuActive = 4;
        $scope.comprobante = $localStorage.comprobantePagoServicio.comprobante;
        $scope.ticket = formatTicket($scope.comprobante.ticket);
        $scope.nroOperacion = $localStorage.nroOperacion;
        $scope.aceptar = function () {
            $state.go('app.pagos.servicios');
        }

        $scope.descargarPdf = function () {
            var json = {
                "nombre": '' + $localStorage.datosUsuario.nombre,
                "cedula": "" + $localStorage.usuario,
                "servicio": '' + $scope.servicio,
                "nroTransaccion": '' + $scope.comprobante.idTransaccion,
                "ticket": '' + formatTicketComp($scope.comprobante.ticket)

            }

            var parametros = {
                url: "/SwitcherSanCristobal-web/reportePagoServicio",
                method: "POST",
                data: json
            };

            blockUI();
            MainFactory.getPdf(parametros).then(
                    function (response) {
                        if (response.data.estado == 0) {
                            window.location.replace('/SwitcherSanCristobal-web/reportePagoServicio');
                            unBlockUI();
                        } else {
                            showNotification('alerta', response.data.mensaje);
                            unBlockUI();
                        }
                    },
                    function (response) {
                        unBlockUI();
                        $scope.mensaje = "Error en la operacion.";
                    });
        };

    }]);