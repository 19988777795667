app.controller('ValidacionDatosCtrl', ["$scope", "$timeout", "$state", "MainFactory", "$filter", "$location", function ($scope, $timeout, $state, MainFactory, $filter, $location) {

    $scope.token = $location.search().codigo;
    $scope.mensaje;
   

    blockUI();
    MainFactory.getValidacion($scope.token)
        .then(function (response) {
            $scope.mensaje = response.data.mensaje;
            unBlockUI();
        },
            function (response) {
                unBlockUI();
                showNotification('alerta', "Ha ocurrido un error, intente nuevamente");
            });

}]);