app.controller('VerPdfPdfJs', ['$scope', '$localStorage', 'MainFactory', '$state',
        function ($scope, $localStorage, MainFactory, $state) {

            var pdfjsLib = window['pdfjs-dist/build/pdf'];
            console.log(pdfjsLib);

            $scope.titulo = $localStorage.titulo;
            $scope.page = 1;
            $scope.numPages = 0;
            $scope.canvas;
            $scope.canvasAux;
            $scope.mouseIsDown;
            $scope.dibujado = false;
            $scope.pdf;
            $scope.ws;
            $scope.wsConnected = false;

            $scope.data = {};

            $scope.renderPage = function () {
                blockUI();
                $scope.pdf.getPage($scope.page).then(function (page) {

                    var viewport = page.getViewport({scale: 1.5});

                    // Prepare canvas using PDF page dimensions
                    $scope.canvas = document.getElementById('the-canvas');

                    var context = $scope.canvas.getContext('2d');
                    $scope.canvas.height = viewport.height;
                    $scope.canvas.width = viewport.width;

                    // Render PDF page into canvas context
                    var renderContext = {
                        canvasContext: context,
                        viewport: viewport
                    };
                    page.render(renderContext).promise.then(function () {
                        unBlockUI();
                    });

                });
            };

            $scope.init = function () {
                blockUI();
                pdfjsLib.disableFontFace = true;

                var url = $localStorage.url;

                pdfjsLib.getDocument(url).promise.then(function (pdf) {
                    $scope.pdf = pdf;
                    $scope.$apply(function () {
                        console.log(pdf);
                        $scope.numPages = pdf._pdfInfo.numPages;
                    });
                    console.log($scope.numPages);
                    $scope.page = 1;
                    $scope.renderPage();
                }, function (reason) {
                    // PDF loading error
                    console.error(reason);
                });

            };

            $scope.init();

            $scope.salir = function () {
                $state.go($localStorage.urlRetorno);
            };

            $scope.anterior = function () {
                $scope.page -= 1;
                $scope.page = Math.max($scope.page, 1);
                $scope.renderPage();
            };

            $scope.siguiente = function () {
                $scope.page += 1;
                $scope.renderPage();
            };

        }
    ]
);
