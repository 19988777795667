app.controller('SolidaridadPagoCtrl', ['$scope', 'MainFactory', '$state', '$sessionStorage', '$localStorage', '$rootScope', '$location', 'MenuFactory', 'NgTableParams', '$filter',
    function ($scope, MainFactory, $state, $sessionStorage, $localStorage, $rootScope, $location, MenuFactory, NgTableParams, $filter) {

        $scope.cajasAhorro = [];
        $scope.datos = {};
        $scope.solidaridad = [];
        $scope.nroSocio = $localStorage.datosUsuario.nroSocio;
        $scope.datos.opcion = "S";
        $scope.habilitar = true;
        $scope.show = false;

        var cuentasAhorro = {
            operacion: "73",
            producto: "18",
            entidad: "3",
            cliente: "1",
            presentador: "1",
            entrada: {
                iIdSesion: $localStorage.identificadorSesion,
                iTipSaldo: "AHR"
            }
        };

        blockUI();
        MainFactory.doRequest(cuentasAhorro)
            .then(function (response) {

                if (response.data.estado == 0) {
                    $scope.cajasAhorro = response.data.data.oDatProducto;
                } else {
                    showNotification('alerta', response.data.mensaje);
                }
                unBlockUI();
            },
                function (response) {
                    unBlockUI();
                    showNotification('alerta', "No se pudo obtener Cuentas de Ahorro");
                });

        parametrosCabecera = {
            operacion: "73",
            producto: "18",
            entidad: "3",
            cliente: "1",
            presentador: "1",
            entrada: {
                iIdSesion: $localStorage.identificadorSesion,
                iTipSaldo: "SOL"
            }
        };

        $scope.cargarCtaFav = function () {

            parametros = {
                operacion: "75",
                producto: "18",
                entidad: "3",
                cliente: "1",
                presentador: "1",
                entrada: {
                    iIdSesion: $localStorage.identificadorSesion,
                    iOpeCuenta: "OBT",
                    iCtaAlias: "",
                    iCtaNombre: "",
                    iCtaMail: "",
                    iCtaTelefono: "",
                    iCtaCi: "",
                    iCtaNumero: ""
                }
            }

            blockUI();
            MainFactory.doRequest(parametros)
                .then(function (response) {
                    if (response.data.estado == 0) {
                        $scope.cuentasFav = response.data.data.oCtaFavorita;
                    } else {
                        showNotification('alerta', response.data.mensaje);
                    }
                    unBlockUI();
                },
                    function (response) {
                        unBlockUI();
                        showNotification('alerta', "No se pudo obtener Cuentas Favoritas");
                    });
        }

        $scope.cargarCtaFav();

        $scope.checkRadio = function (value) {
            $scope.datos.monto = null;
            if (value == 'S') {
                $scope.datos.otraCuenta = null;
                $scope.habilitar = true;
            } else if (value == 'N') {
                $scope.habilitar = false;
            }
            $scope.datos.otroSocio = null;
        }

        $scope.update = function () {
            $scope.datos.otraCuenta = null;
        }

        blockUI();
        if (typeof $localStorage.cabSolidaridad !== 'undefined' && $localStorage.cabSolidaridad !== null) {
            $scope.show = true;
            $scope.solidaridad[0] = $localStorage.cabSolidaridad;
            $localStorage.cabSolidaridad = null;

            $scope.tableParams = new NgTableParams({
                page: 1,
                count: 10,
                sorting: {
                    name: 'tipo'
                }
            }, {
                counts: [],
                total: $scope.solidaridad.length,
                getData: function (params) {
                    var orderedData = params.sorting() ? $filter('orderBy')($scope.solidaridad, params.orderBy()) : $scope.solidaridad;
                    return (orderedData.slice((params.page() - 1) * params.count(), params.page() * params.count()));
                }
            });
            unBlockUI();
        } else {
            MainFactory.doRequest(parametrosCabecera).then(
                function (response) {
                    if (response.data.estado == 0) {
                        $scope.solidaridad = response.data.data.oDatProducto;
                        $scope.show = true;
                        $scope.tableParams = new NgTableParams({
                            page: 1,
                            count: 10,
                            sorting: {
                                name: 'tipo'
                            }
                        }, {
                            counts: [],
                            total: $scope.solidaridad.length,
                            getData: function (params) {
                                var orderedData = params.sorting() ? $filter('orderBy')($scope.solidaridad, params.orderBy()) : $scope.solidaridad;
                                return (orderedData.slice((params.page() - 1) * params.count(), params.page() * params.count()));
                            }
                        });
                    }
                    $scope.show = true;
                    unBlockUI();
                },
                function (response) {
                    unBlockUI();
                });
        }

        $scope.limpiarCampos = function () {
            $scope.datos.cuenta = null;
            var element = document.getElementById('cuenta_select');
            element.value = "1";
            $scope.datos.otroSocio = null;
            $scope.datos.otraCuenta = null;
            $scope.datos.monto = null;
            $scope.datos.opcion = "S";
        };

        $scope.checkMonto = function (value) {
            $scope.datos.monto = number_format(value);
            //document.getElementById('monto_pagar').setAttribute("disabled","disabled");
            $scope.habilitar = true;
        }

        $scope.checkOtro = function () {
            $scope.habilitar = false;
            //document.getElementById('monto_pagar').removeAttribute("disabled");  
            $scope.datos.monto = null;
        }

        $scope.confirmar = function (form) {

            if (!form.$valid) {
                return;
            }

            blockUI();
            if (isNumber(formatoMonto($scope.datos.monto)) && formatoMonto($scope.datos.monto) > 0) {
                $scope.pagoSolidaridad = {};
                $scope.pagoSolidaridad.cuenta = $scope.datos.cuenta.wbNumero;
                $scope.pagoSolidaridad.nroCuenta = $scope.nroSocio + '-' + $scope.datos.cuenta.wbCodigo;
                $scope.pagoSolidaridad.monto = formatoMonto($scope.datos.monto);
                $scope.pagoSolidaridad.cuentaSaldo = $scope.datos.cuenta.wbMtoActual;
                $scope.pagoSolidaridad.fecha = getFecha();
                $scope.pagoSolidaridad.otroSocio = $scope.datos.otraCuenta;
                $scope.pagoSolidaridad.tipo = $scope.datos.otroSocio;
                $scope.pagoSolidaridad.nombre = $localStorage.datosUsuario.nombre;
                if ($scope.datos.opcion == "S") {
                    $scope.pagoSolidaridad.beneficiario = $localStorage.datosUsuario.nombre;
                    $localStorage.pagoSolidaridad = $scope.pagoSolidaridad;
                    unBlockUI();
                    $state.go('app.pagos.confirmacionPagoSol');
                } else {
                    if ($scope.datos.otroSocio == '0') {
                        $scope.pagoSolidaridad.beneficiario = $scope.datos.otraCuenta.nombre.toUpperCase();
                        $scope.pagoSolidaridad.ciBeneficiario = $scope.datos.otraCuenta.docCredito;
                        $localStorage.pagoSolidaridad = $scope.pagoSolidaridad;
                        unBlockUI();
                        $state.go('app.pagos.confirmacionPagoSol');
                    } else if ($scope.datos.otroSocio == '1') {
                        //$scope.pagoSolidaridad.beneficiario = $scope.datos.otraCuenta;
                        var param = {
                            "operacion": "82",
                            "producto": "18",
                            "entidad": "3",
                            "cliente": "1",
                            "presentador": "1",
                            "entrada": {
                                "iCtaCi": $scope.datos.otraCuenta,
                                "iCtaNumero": null
                            }
                        };

                        MainFactory.doRequest(param)
                            .then(function (response) {
                                if (response.data.estado == 0) {
                                    console.log($scope.datos.otraCuenta);
                                    $scope.pagoSolidaridad.ciBeneficiario = $scope.datos.otraCuenta;
                                    $scope.pagoSolidaridad.beneficiario = response.data.data.oDatCuenta[0].nombre.toUpperCase();
                                    $localStorage.pagoSolidaridad = $scope.pagoSolidaridad;
                                    unBlockUI();
                                    $state.go('app.pagos.confirmacionPagoSol');
                                } else {
                                    unBlockUI();
                                    showNotification('alerta', response.data.mensaje);
                                }
                            },
                                function (response) {
                                    unBlockUI();
                                    showNotification('alerta', "No se pudo consultar beneficiario");
                                });
                    }
                }
            } else {
                unBlockUI();
                showNotification('alerta', "Monto ingresado inválido");
            }
        }
    }]);

app.controller('ConfirmacionPagoSolCtrl', ['$scope', 'MainFactory', '$state', '$sessionStorage', '$localStorage', '$rootScope', '$location',
    function ($scope, MainFactory, $state, $sessionStorage, $localStorage, $rootScope, $location) {

        $scope.confirmacion = $localStorage.pagoSolidaridad;
        $scope.pin;
        $scope.tipo;

        $scope.setTipoVerificacion = function (tipo) {
            $scope.pin = '';
            $scope.tipo = tipo;
        }

        $scope.verificarOtp = function () {
            blockUI();
            MainFactory.verifyOtp().then(
                function (response) {
                    if (response.data.estado == 0) {
                        $scope.pagarSolidaridad();
                    } else {
                        unBlockUI();
                        showNotification('alerta', response.data.mensaje);
                    }
                },
                function (response) {
                    unBlockUI();
                    showNotification('alerta', "No se pudo realizar el pago");
                });
        }

        $scope.pagar = function (form) {
            if (!form.$valid) {
                return;
            }

            if ($scope.tipo) {
                if ($scope.pin == '') {
                    showNotification('alerta', 'Ingrese su código de verificación');
                } else {
                    if ($scope.tipo == "PIN") {
                        $scope.pagarSolidaridad();
                    } else {
                        $scope.verificarOtp();
                    }
                }
            } else {
                showNotification('alerta', 'Seleccione un método de verificación');
            }
        }

        $scope.pagarSolidaridad = function () {
            var otroSocio;

            if ($scope.confirmacion.tipo == '0') {
                otroSocio = $scope.confirmacion.otroSocio.docCredito;
            } else if ($scope.confirmacion.tipo == '1') {
                otroSocio = $scope.confirmacion.otroSocio;
            } else {
                otroSocio = null;
            }

            blockUI();
            if ($scope.confirmacion.monto > $scope.confirmacion.cuentaSaldo) {
                showNotification('alerta', 'Saldo Insuficiente para completar la transacción');
                unBlockUI();
                $state.go('app.pagos.solidaridad');
            } else {
                var parametros = {
                    operacion: "79",
                    producto: "18",
                    entidad: "3",
                    cliente: "1",
                    presentador: "1",
                    entrada: {
                        iIdSesion: $localStorage.identificadorSesion,
                        iPrdPago: "SOL",
                        iCtaDebito: $scope.confirmacion.cuenta,
                        iNroProducto: null,
                        iMtoPago: $scope.confirmacion.monto,
                        iCanCuota: null,
                        tipoAuth: {
                            tipo: $scope.tipo,
                            valor: $scope.pin
                        },
                        idSocio: $localStorage.usuario,
                        iCiOtrSocio: otroSocio
                    }
                }

                MainFactory.doRequest(parametros).then(
                    function (response) {
                        if (response.data.estado == 0) {
                            unBlockUI();
                            $localStorage.comprobante = response.data.data.oMovPago[0];
                            $localStorage.comprobante.datos = $scope.confirmacion;
                            $state.go('app.pagos.comprobanteSol');
                        } else {
                            unBlockUI();
                            $state.go('app.pagos.solidaridad');
                            showNotification('alerta', response.data.mensaje);
                        }
                    },
                    function (response) {
                        unBlockUI();
                        showNotification('alerta', "No se pudo realizar el pago");
                    });
            }
        }

        $scope.cancelar = function () {
            $state.go('app.pagos.solidaridad');
        }
    }]);

app.controller('ComprobantePagoSolCtrl', ['$scope', '$state', '$sessionStorage', '$localStorage', '$rootScope', '$location', 'MainFactory',
    function ($scope, $state, $sessionStorage, $localStorage, $rootScope, $location, MainFactory) {

        $scope.comprobante = $localStorage.comprobante;
        $scope.aceptar = function () {
            $state.go('app.pagos.solidaridad');
        }

        $scope.descargarPdf = function () {

            var json = {
                "pagComprobante": $scope.comprobante.pagComprobante,
                "fecha": $scope.comprobante.fecha,
                "concepto": $scope.comprobante.concepto,
                "nombre": $scope.comprobante.datos.nombre,
                "nroCuenta": $scope.comprobante.datos.nroCuenta,
                "monto": $scope.comprobante.datos.monto,
                "cuentaDestino": $scope.comprobante.datos.cuentaDestino,
                "beneficiario": $scope.comprobante.datos.beneficiario,
                "tarjeta": $scope.comprobante.datos.tarjeta,
                "prestamo": $scope.comprobante.datos.prestamo,
                "nroOperacion": $scope.comprobante.nroOperacion,
                "fecOperacion": $scope.comprobante.fecOperacion,
                "socio": $scope.comprobante.socio,
                "cuentaOrigenDescripcion": $scope.comprobante.cuentaOrigenDescripcion,
                "importe": $scope.comprobante.importe,
                "cuentaDestinoDescripcion": $scope.comprobante.cuentaDestinoDescripcion
            }

            var parametros = {
                url: "/SwitcherSanCristobal-web/comprobante",
                method: "POST",
                data: json
            };

            blockUI();
            MainFactory.getPdf(parametros).then(
                function (response) {
                    if (response.data.estado == 0) {
                        window.location.replace('/SwitcherSanCristobal-web/comprobante');
                        unBlockUI();
                    } else {
                        showNotification('alerta', response.data.mensaje);
                        unBlockUI();
                    }
                },
                function (response) {
                    unBlockUI();
                    $scope.mensaje = "Error en la operacion.";
                });
        };
    }]);

