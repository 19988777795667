app.controller('TransferenciaExternaCtrl', ['$scope', 'MainFactory', 'TransferenciaExternaFactory', '$state', '$localStorage',
    function ($scope, MainFactory, TransferenciaExternaFactory, $state, $localStorage) {

        $scope.cajasAhorro;
        $scope.datos = {};
        $localStorage.menuActive = 2;
        $scope.nroSocio = $localStorage.datosUsuario.nroSocio;

        $scope.$watch('datos.documento', function (now, old) {
            if ($scope.datos.tipoDocumento) {
                if ($scope.datos.tipoDocumento.codigo == "CEDULA") {
                    $scope.datos.documento = now.replace(/[^0-9]/g, '');
                } else if ($scope.datos.tipoDocumento.codigo == "RUC") {
                    $scope.datos.documento = now.replace(/[^0-9-]/g, '');
                }
            } else {
                $scope.datos.documento = now.replace(/[^0-9]/g, '');
            }
        });


        var cuentasAhorro = {
            operacion: "73",
            producto: "18",
            entidad: "3",
            cliente: "1",
            presentador: "1",
            entrada: {
                iIdSesion: $localStorage.identificadorSesion,
                iTipSaldo: "AHR"
            }
        };

        blockUI();
        MainFactory.doRequest(cuentasAhorro)
            .then(function (response) {
                if (response.data.estado == 0) {
                    $scope.cajasAhorro = response.data.data.oDatProducto;
                }
                unBlockUI();
            },
                function (response) {
                    unBlockUI();
                    showNotification('alerta', "No se pudo obtener Cuentas de Ahorro");
                });

        // lista las entidades        
        blockUI();
        TransferenciaExternaFactory.listarValores(1)
            .then(function (response) {
                if (response.data.estado == 0) {
                    $scope.entidades = response.data.data;
                }
                unBlockUI();
            },
                function (response) {
                    unBlockUI();
                    showNotification('alerta', "No se pudo obtener las entidades");
                });

        // lista los motivos        
        blockUI();
        TransferenciaExternaFactory.listarValores(2)
            .then(function (response) {
                if (response.data.estado == 0) {
                    $scope.motivos = response.data.data;
                }
                unBlockUI();
            },
                function (response) {
                    unBlockUI();
                    showNotification('alerta', "No se pudo obtener los motivos");
                });

        blockUI();
        TransferenciaExternaFactory.listarDocumentos()
            .then(function (response) {
                if (response.data.estado == 0) {
                    $scope.tiposDocumento = response.data.data;
                }
                unBlockUI();
            },
                function (response) {
                    unBlockUI();
                    showNotification('alerta', "No se pudo obtener los tipos de documentos");
                });

        $scope.limpiarCampos = function () {
            $scope.datos.origen = null;
            var element1 = document.getElementById('cuenta_origen_select');
            element1.value = "1";
            $scope.datos.entidad = null;
            var element2 = document.getElementById('entidades_select');
            element2.value = "1";
            $scope.datos.destino = null;
            $scope.datos.tiposDocumento = null;
            var element3 = document.getElementById('tiposDocumento_select');
            element3.value = "1";
            $scope.datos.documento = null;
            $scope.datos.nombre = null;
            $scope.datos.monto = null;
            $scope.datos.motivo = null;
            $scope.datos.otroMotivo = null;
            var element4 = document.getElementById('motivos_select');
            element4.value = "1";
        };

        $scope.confirmar = function (form) {
            if (!form.$valid) {
                return;
            }
            blockUI();
            if (isNumber(formatoMonto($scope.datos.monto)) && formatoMonto($scope.datos.monto) > 0) {
                $scope.transferencia = $scope.datos;
                $scope.transferencia.monto = formatoMonto($scope.datos.monto);
                $scope.transferencia.fecha = getFecha();
                $localStorage.transferencia = $scope.transferencia;
                unBlockUI();
                $state.go('app.transferenciaExternaConfirmacion');
            } else {
                unBlockUI();
                showNotification('alerta', "Monto ingresado inválido");
            }
        }
    }]);

app.controller('ConfirmacionTransExternaCtrl', ['$scope', 'TransferenciaExternaFactory', 'MainFactory', '$state', '$localStorage',
    function ($scope, TransferenciaExternaFactory, MainFactory, $state, $localStorage) {

        $scope.confirmacion = $localStorage.transferencia;
        $scope.socio = $localStorage.datosUsuario;
        $scope.pin;
        $scope.tipo = "PIN";
        //$scope.tipo;

        $scope.setTipoVerificacion = function (tipo) {
            if (tipo == 'PIN') {
                $scope.generarCodigoSms();
            }
            $scope.pin = '';
            $scope.tipo = tipo;
        }

        $scope.verificarOtp = function () {
            blockUI();
            MainFactory.verifyOtp().then(
                function (response) {
                    if (response.data.estado == 0) {
                        $scope.realizarTransferencia();
                    } else {
                        unBlockUI();
                        showNotification('alerta', response.data.mensaje);
                    }
                },
                function (response) {
                    unBlockUI();
                    showNotification('alerta', "No se pudo realizar el pago");
                });
        }

        $scope.generarCodigoSms = function () {
            blockUI();
            TransferenciaExternaFactory.generarCodigo("SMS")
                .then(function (response) {
                    if (response.data.estado == 0) {
                    } else {
                        showNotification('alerta', response.data.mensaje);
                    }
                    unBlockUI();
                },
                    function (response) {
                        unBlockUI();
                        showNotification('alerta', "No se pudo obtener los motivos");
                    });
        };

        $scope.transferir = function (form) {
            if (!form.$valid) {
                return;
            }

            if ($scope.tipo) {
                if ($scope.pin == '') {
                    showNotification('alerta', 'Ingrese su código de verificación');
                } else {
                    if ($scope.tipo == "PIN") {
                        $scope.realizarTransferencia();
                    } else {
                        $scope.verificarOtp();
                    }
                }
            } else {
                showNotification('alerta', 'Seleccione un método de verificación');
            }
        }

        $scope.realizarTransferencia = function () {
            blockUI();
            if ($scope.confirmacion.monto > $scope.confirmacion.origen.wbMtoActual) {
                showNotification('alerta', 'Saldo Insuficiente para completar la transacción');
                unBlockUI();
                $state.go('app.transferenciaExterna');
            } else {
                let parametros = {
                    "tipoAuth": {
                        "tipo": $scope.tipo,
                        "valor": $scope.pin
                    },
                    "monto": $scope.confirmacion.monto,
                    "moneda": "GS",
                    "idMotivoTransferencia": $scope.confirmacion.motivo.codigo,
                    "obsTransferencia": $scope.confirmacion.otroMotivo ? $scope.confirmacion.otroMotivo : "",
                    "ordNroCuenta": $scope.confirmacion.origen.wbNumero,
                    "recTipoDocumento": $scope.confirmacion.tipoDocumento.descripcion,
                    "recNroDocumento": $scope.confirmacion.documento,
                    "recNombre": $scope.confirmacion.nombre,
                    "recNroCuenta": $scope.confirmacion.destino,
                    "recIdParticipante": $scope.confirmacion.entidad.codigo
                };

                TransferenciaExternaFactory.enviar(parametros).then(
                    function (response) {
                        if (response.data.estado == 0) {
                            unBlockUI();
                            let datos = {
                                motivo: $scope.confirmacion.otroMotivo ? $scope.confirmacion.otroMotivo : $scope.confirmacion.motivo.descripcion,
                                socio: $localStorage.datosUsuario.nombre,
                                cuentaDebito: $localStorage.datosUsuario.nroSocio + ' - ' + $scope.confirmacion.origen.wbCodigo,
                                nombre: $scope.confirmacion.nombre,
                                cuentaCredito: $scope.confirmacion.destino,
                                entidad: $scope.confirmacion.entidad.nombre,
                                monto: $scope.confirmacion.monto
                            }
                            $localStorage.datosSipap = datos;
                            $state.go('app.transferenciaExternaComprobante');
                        } else {
                            unBlockUI();
                            $state.go('app.transferenciaExterna');
                            showNotification('alerta', response.data.mensaje);
                        }
                    },
                    function (response) {
                        unBlockUI();
                        showNotification('alerta', "No se pudo realizar la transferencia");
                    });
            }
        }

        $scope.cancelar = function () {
            $state.go('app.transferenciaExterna');
        }

    }]);


app.controller('ComprobanteTransExternaCtrl', ['$scope', 'TransferenciaExternaFactory', '$state', '$localStorage',
    function ($scope, TransferenciaExternaFactory, $state, $localStorage) {

        $scope.comprobante = $localStorage.datosSipap;

        $scope.aceptar = function () {
            $state.go('app.transferenciaExterna');
        }

    }]);