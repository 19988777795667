app.controller('PrestamoPagoCtrl', ['$scope', 'MainFactory', '$state', '$sessionStorage', '$localStorage', '$rootScope', '$location', 'MenuFactory', 'NgTableParams', '$filter',
    function ($scope, MainFactory, $state, $sessionStorage, $localStorage, $rootScope, $location, MenuFactory, NgTableParams, $filter) {

        $scope.show = false;
        $scope.cajasAhorro = [];
        $scope.datos = {};
        $scope.prestamo;
        $scope.nroSocio = $localStorage.datosUsuario.nroSocio;
        $scope.datos.radio;
        $scope.datos.montoAPagar = "";
        $scope.datos.opcion = "S";
        $scope.prestamosOtrosSocio = [];

        var cuentasAhorro = {
            operacion: "73",
            producto: "18",
            entidad: "3",
            cliente: "1",
            presentador: "1",
            entrada: {
                iIdSesion: $localStorage.identificadorSesion,
                iTipSaldo: "AHR"
            }
        };

        blockUI();
        MainFactory.doRequest(cuentasAhorro)
            .then(function (response) {
                if (response.data.estado == 0) {
                    $scope.cajasAhorro = response.data.data.oDatProducto;
                } else {
                    showNotification('alerta', response.data.mensaje);
                }
                unBlockUI();
            },
                function (response) {
                    unBlockUI();
                    showNotification('alerta', "No se pudo obtener Cuentas de Ahorro");
                });

        parametrosCabecera = {
            operacion: "73",
            producto: "18",
            entidad: "3",
            cliente: "1",
            presentador: "1",
            entrada: {
                iIdSesion: $localStorage.identificadorSesion,
                iTipSaldo: "PMO"
            }
        };

        if (typeof $localStorage.cabPrestamo !== 'undefined' && $localStorage.cabPrestamo !== null) {
            $scope.aPagar = $localStorage.cabPrestamo;
            $scope.datos.prestamo = $scope.aPagar;
            $localStorage.cabPrestamo = null;
        }

        if (typeof $localStorage.prestamos !== 'undefined' && $localStorage.prestamos !== null) {
            $scope.prestamo = $localStorage.prestamos;
            //$scope.show = true;
            $localStorage.prestamos = null;
        } else {
            MainFactory.doRequest(parametrosCabecera).then(
                function (response) {
                    if (response.data.estado == 0) {
                        if (response.data.data.oDatProducto.length > 0) {
                            $scope.show = false;
                        } else {
                            $scope.show = true;
                            $scope.datos.opcion = "N";
                            $scope.habilitar = true;
                        }
                        $scope.prestamo = response.data.data.oDatProducto;
                    } else {
                        showNotification('alerta', response.data.mensaje);
                    }
                },
                function (response) {
                    showNotification('alerta', "No se pudo obtener Prestamos");
                    $scope.show = true;
                });
        }

        $scope.cargarCtaFav = function () {

            parametros = {
                operacion: "75",
                producto: "18",
                entidad: "3",
                cliente: "1",
                presentador: "1",
                entrada: {
                    iIdSesion: $localStorage.identificadorSesion,
                    iOpeCuenta: "OBT",
                    iCtaAlias: "",
                    iCtaNombre: "",
                    iCtaMail: "",
                    iCtaTelefono: "",
                    iCtaCi: "",
                    iCtaNumero: ""
                }
            }

            blockUI();
            MainFactory.doRequest(parametros)
                .then(function (response) {
                    if (response.data.estado == 0) {
                        $scope.cuentasFav = response.data.data.oCtaFavorita;
                    } else {
                        showNotification('alerta', response.data.mensaje);
                    }
                    unBlockUI();
                },
                    function (response) {
                        unBlockUI();
                        showNotification('alerta', "No se pudo obtener Cuentas Favoritas");
                    });
        }

        $scope.cargarCtaFav();

        $scope.consultarSocio = function () {
            if ($scope.datos.otraCuenta) {
                blockUI();
                $scope.prestamosOtrosSocio = [];
                let otroSocio;
                if ($scope.datos.otroSocio == 'O') {
                    otroSocio = $scope.datos.otraCuenta;
                } else {
                    otroSocio = $scope.datos.otraCuenta.docCredito;
                }
                MainFactory.getDatosOtroSocio($localStorage.identificadorSesion, "PMO", otroSocio)
                    .then(function (response) {
                        if (response.data.estado == 0) {
                            if (response.data.data.oDatProducto.length > 0) {
                                $scope.prestamosOtrosSocio = response.data.data.oDatProducto;
                                $scope.cedulaOtroSocio = otroSocio;
                            } else {
                                showNotification('alerta', "No posee Préstamo");
                            }
                            unBlockUI();
                        }
                    },
                        function (response) {
                            unBlockUI();
                            showNotification('alerta', "Ha ocurrido un error, intente nuevamente.");
                        });
            } else {
                showNotification('alerta', "Ingrese la cédula del otro socio");
            }
        }

        $scope.update = function () {
            $scope.prestamosOtrosSocio = [];
            $scope.datos.otraCuenta = null;
        }

        $scope.updateRadio = function () {
            $scope.datos.prestamo = $scope.aPagar;
            $scope.datos.montoAPagar = "";
            if ($scope.datos.radio == "1" && $scope.datos.opcion == 'S') {
                $scope.habilitar = false;
            } else {
                $scope.habilitar = true;
            }
        }

        $scope.checkRadio = function (value) {
            $scope.datos.monto = null;
            $scope.datos.montoAPagar = "";
            $scope.datos.nroProducto = null;
            if (value == 'S') {
                $scope.prestamosOtrosSocio = [];
                if ($scope.datos.radio == 1) {
                    $scope.habilitar = false;
                } else {
                    $scope.habilitar = true;
                }
            } else if (value == 'N') {
                $scope.habilitar = true;
            }
            $scope.datos.otroSocio = null;
        }

        $scope.limpiarCampos = function () {
            $scope.datos.cuenta = null;
            var element = document.getElementById('cuenta_select');
            element.value = "1";
            //var element1 = document.getElementById('prestamo_select');
            //element1.value = "1";
            $scope.datos.radio = null;
            $scope.datos.opcion = "S";
            $scope.datos.otraCuenta = null;
            $scope.datos.otroSocio = null;
            $scope.prestamosOtrosSocio = [];
            $scope.habilitar = false;
            $scope.datos.montoAPagar = "";
            $scope.datos.prestamo = null;
        };

        $scope.confirmar = function (form) {

            if (!form.$valid) {
                return;
            }

            if ($scope.datos.prestamo) {
                if ($scope.datos.opcion == 'S') {
                    if ($scope.datos.radio == "0") {
                        if (isNumber(formatoMonto($scope.datos.montoAPagar)) && (formatoMonto($scope.datos.montoAPagar)) > 0) {
                            $scope.confirmarPago();
                        } else {
                            showNotification('alerta', 'Monto Inválido');
                        }
                    } else {
                        $scope.confirmarPago();
                    }
                } else {
                    if (isNumber(formatoMonto($scope.datos.montoAPagar)) && (formatoMonto($scope.datos.montoAPagar)) > 0) {
                        $scope.confirmarPago();
                    } else {
                        showNotification('alerta', 'Monto Inválido');
                    }
                }
            } else {
                showNotification('alerta', "Debe seleccionar el préstamo");
                unBlockUI();
            }
        }

        $scope.confirmarPago = function () {
            $scope.datos.cuota = 1;
            blockUI();
            var consultaPrestamo = {
                operacion: "80",
                producto: "18",
                entidad: "3",
                cliente: "1",
                presentador: "1",
                entrada: {
                    iPmoClave: $scope.datos.prestamo.wbNumero,
                    iCanCuota: $scope.datos.cuota
                }
            };

            $scope.pagoPmo = {};
            $scope.pagoPmo.cuenta = $scope.datos.cuenta.wbNumero;
            $scope.pagoPmo.cuentaSaldo = $scope.datos.cuenta.wbMtoActual;
            $scope.pagoPmo.nroPrestamo = $scope.datos.prestamo.wbNumero;
            $scope.pagoPmo.prestamo = $scope.nroSocio + '-' + $scope.datos.prestamo.wbCodigo
            $scope.pagoPmo.cantCuota = $scope.datos.cuota;
            $scope.pagoPmo.nroCuenta = $scope.nroSocio + '-' + $scope.datos.cuenta.wbCodigo;
            $scope.pagoPmo.nombre = $localStorage.datosUsuario.nombre;
            $scope.pagoPmo.prestamoDesc = $scope.nroSocio + '-' + $scope.datos.prestamo.wbCodigo + ' / ' + $scope.datos.prestamo.wbDescripcion;

            MainFactory.doRequest(consultaPrestamo).then(
                function (response) {
                    if (response.data.estado == 0) {
                        $scope.pagoPmo.consulta = response.data.data;
                        if ($scope.datos.radio == "0" || $scope.datos.opcion == "N") {
                            $scope.pagoPmo.consulta.oMtoCuota = formatoMonto($scope.datos.montoAPagar);
                            $scope.pagoPmo.cantCuota = 0;
                            $scope.pagoPmo.pagoPorMonto = true;
                        }
                        if ($scope.datos.opcion == "S") {
                            $scope.pagoPmo.beneficiario = $localStorage.datosUsuario.nombre;
                            $localStorage.pagoPmo = $scope.pagoPmo;
                            unBlockUI();
                            $state.go('app.pagos.confirmacionPagoPmo');
                        } else {
                            if ($scope.datos.otroSocio == 'F') {
                                $scope.pagoPmo.beneficiario = $scope.datos.otraCuenta.nombre;
                                $scope.pagoPmo.ciOtrSocio = $scope.datos.otraCuenta.docCredito;
                                $localStorage.pagoPmo = $scope.pagoPmo;
                                unBlockUI();
                                $state.go('app.pagos.confirmacionPagoPmo');
                            } else if ($scope.datos.otroSocio == 'O') {
                                $scope.pagoPmo.ciOtrSocio = $scope.datos.otraCuenta;
                                var param = {
                                    "operacion": "82",
                                    "producto": "18",
                                    "entidad": "3",
                                    "cliente": "1",
                                    "presentador": "1",
                                    "entrada": {
                                        "iCtaCi": $scope.datos.otraCuenta,
                                        "iCtaNumero": null
                                    }
                                };

                                MainFactory.doRequest(param)
                                    .then(function (response) {
                                        if (response.data.estado == 0) {
                                            $scope.pagoPmo.beneficiario = response.data.data.oDatCuenta[0].nombre;
                                            $localStorage.pagoPmo = $scope.pagoPmo;
                                            unBlockUI();
                                            $state.go('app.pagos.confirmacionPagoPmo');
                                        } else {
                                            unBlockUI();
                                            showNotification('alerta', response.data.mensaje);
                                        }
                                    },
                                        function (response) {
                                            unBlockUI();
                                            showNotification('alerta', "No se pudo consultar beneficiario");
                                        });

                            }
                        }
                    } else {
                        showNotification('alerta', response.data.mensaje);
                    }
                },
                function (response) {
                    showNotification('alerta', "No se pudo consultar Prestamos");
                    unBlockUI();
                });
            /*} else {
                unBlockUI();
                showNotification('alerta', "Cantidad de cuota inválida");
            }*/
        }
    }]);

app.controller('ConfirmacionPagoPmoCtrl', ['$scope', 'MainFactory', '$state', '$sessionStorage', '$localStorage', '$rootScope', '$location',
    function ($scope, MainFactory, $state, $sessionStorage, $localStorage, $rootScope, $location) {

        $scope.confirmacion = $localStorage.pagoPmo;
        $scope.tarjeta = true;
        $scope.pin;
        $scope.tipo;

        $scope.setTipoVerificacion = function (tipo) {
            $scope.pin = '';
            $scope.tipo = tipo;
        }

        $scope.verificarOtp = function () {
            blockUI();
            MainFactory.verifyOtp().then(
                function (response) {
                    if (response.data.estado == 0) {
                        $scope.pagarPrestamo();
                    } else {
                        unBlockUI();
                        showNotification('alerta', response.data.mensaje);
                    }
                },
                function (response) {
                    unBlockUI();
                    showNotification('alerta', "No se pudo realizar el pago");
                });
        }

        $scope.pagar = function (form) {
            if (!form.$valid) {
                return;
            }


            if ($scope.tipo) {
                if ($scope.pin == '') {
                    showNotification('alerta', 'Ingrese su código de verificación');
                } else {
                    if ($scope.tipo == "PIN") {
                        $scope.pagarPrestamo();
                    } else {
                        $scope.verificarOtp();
                    }
                }
            } else {
                showNotification('alerta', 'Seleccione un método de verificación');
            }
        }

        $scope.pagarPrestamo = function () {
            blockUI();
            var parametros = {
                operacion: "79",
                producto: "18",
                entidad: "3",
                cliente: "1",
                presentador: "1",
                entrada: {
                    iIdSesion: $localStorage.identificadorSesion,
                    iPrdPago: "PMO",
                    iCtaDebito: $scope.confirmacion.cuenta,
                    iNroProducto: $scope.confirmacion.nroPrestamo,
                    iMtoPago: $scope.confirmacion.consulta.oMtoCuota,
                    iCanCuota: $scope.confirmacion.cantCuota,
                    tipoAuth: {
                        tipo: $scope.tipo,
                        valor: $scope.pin
                    },
                    idSocio: $localStorage.usuario
                }
            }

            if ($scope.confirmacion.ciOtrSocio) {
                parametros.entrada.iCiOtrSocio = $scope.confirmacion.ciOtrSocio;
                $scope.confirmacion.prestamo = $scope.confirmacion.ciOtrSocio
            }

            MainFactory.doRequest(parametros).then(
                function (response) {
                    if (response.data.estado == 0) {
                        unBlockUI();
                        $localStorage.comprobante = response.data.data.oMovPago[0];
                        $localStorage.comprobante.datos = $scope.confirmacion;
                        $localStorage.comprobante.datos.monto = $scope.confirmacion.consulta.oMtoCuota;
                        $state.go('app.pagos.comprobantePmo');
                    } else {
                        unBlockUI();
                        $state.go('app.pagos.prestamo');
                        showNotification('alerta', response.data.mensaje);
                    }
                });
        }
        $scope.cancelar = function () {
            $state.go('app.pagos.prestamo');
        }
    }]);

app.controller('ComprobantePagoPmoCtrl', ['$scope', '$state', '$sessionStorage', '$localStorage', '$rootScope', '$location', 'MainFactory',
    function ($scope, $state, $sessionStorage, $localStorage, $rootScope, $location, MainFactory) {

        $scope.comprobante = $localStorage.comprobante;

        $scope.aceptar = function () {
            $state.go('app.pagos.prestamo');
        }

        $scope.descargarPdf = function () {

            var json = {
                "pagComprobante": $scope.comprobante.pagComprobante,
                "fecha": $scope.comprobante.fecha,
                "concepto": $scope.comprobante.concepto,
                "nombre": $scope.comprobante.datos.nombre,
                "nroCuenta": $scope.comprobante.datos.nroCuenta,
                "monto": $scope.comprobante.datos.monto,
                "cuentaDestino": $scope.comprobante.datos.cuentaDestino,
                "beneficiario": $scope.comprobante.datos.beneficiario,
                "tarjeta": $scope.comprobante.datos.tarjeta,
                "prestamo": $scope.comprobante.datos.prestamo,
                "nroOperacion": $scope.comprobante.nroOperacion,
                "fecOperacion": $scope.comprobante.fecOperacion,
                "socio": $scope.comprobante.socio,
                "cuentaOrigenDescripcion": $scope.comprobante.cuentaOrigenDescripcion,
                "importe": $scope.comprobante.importe,
                "cuentaDestinoDescripcion": $scope.comprobante.cuentaDestinoDescripcion
            }

            var parametros = {
                url: "/SwitcherSanCristobal-web/comprobante",
                method: "POST",
                data: json
            };

            blockUI();
            MainFactory.getPdf(parametros).then(
                function (response) {
                    if (response.data.estado == 0) {
                        window.location.replace('/SwitcherSanCristobal-web/comprobante');
                        unBlockUI();
                    } else {
                        showNotification('alerta', response.data.mensaje);
                        unBlockUI();
                    }
                },
                function (response) {
                    unBlockUI();
                    $scope.mensaje = "Error en la operacion.";
                });
        };
    }]);
