app.controller('SolicitudesRealizadasCtrl', ['$scope', 'MainFactory', '$state', '$sessionStorage', '$localStorage', '$rootScope', '$location', 'NgTableParams', '$filter', 'SolicitudFactory',
    function ($scope, MainFactory, $state, $sessionStorage, $localStorage, $rootScope, $location, NgTableParams, $filter, SolicitudFactory) {

        $scope.mensajeFactura = null;
        $scope.comprobantes;
        $scope.comprobantesDescargarDet = {};
        $scope.comprobantesDescargaCab = {};

        $scope.fechas = generarFechas();
        $scope.selected = {};
        $scope.selected.fecha = $scope.fechas[0];

        $scope.obtenerFechaInicio = function (fecha) {
            return '01' + '/' + getMesExtracto(fecha) + '/' + getAnhoExtracto(fecha);
        };

        $scope.obtenerFechaFin = function (fecha) {
            today = new Date();
            mes = today.getMonth() + 1;
            if (mes == getMesExtracto(fecha)) {
                return today.getDate() + '/' + getMesExtracto(fecha) + '/' + getAnhoExtracto(fecha);
            } else {
                day = new Date(getAnhoExtracto(fecha), getMesExtracto(fecha), 0);
                return String(day).substring(8, 10) + '/' + getMesExtracto(fecha) + '/' + getAnhoExtracto(fecha);
            }
        };

        $scope.consultar = function () {
            $scope.comprobantes = [];

            parametros = {
                "operacion": "90",
                "producto": "18",
                "entidad": "3",
                "cliente": "1",
                "presentador": "1",
                "entrada": {
                    "iIdSesion": $localStorage.identificadorSesion,
                    "iObtLista": 'SDI',
                    "iFecInicio": $scope.obtenerFechaInicio($scope.selected.fecha.mes),
                    "iFecFin": $scope.obtenerFechaFin($scope.selected.fecha.mes),
                    "iCantidad": 20,
                    "iRegInicial": 1
                }
            };

            blockUI();
            MainFactory.doRequest(parametros)
                .then(function (response) {
                    if (response.data.estado == 0) {
                        $scope.comprobantes = response.data.data.oLisOperaciones;
                        $scope.tableParams = new NgTableParams({
                            page: 1,
                            count: 8
                        }, {
                            counts: [],
                            getData: function (params) {
                                var data = $scope.comprobantes;
                                data = params.filter() ? $filter('filter')(data, params.filter()) : data;
                                data = params.orderBy() ? $filter('orderBy')(data, params.orderBy()) : data;
                                params.total(data.length);
                                data = data.slice((params.page() - 1) * params.count(), params.page() * params.count());
                                return data;
                            }
                        });
                        unBlockUI();
                    } else {
                        unBlockUI();
                        $scope.mensaje = response.data.mensaje;
                        //showNotification('alerta', response.data.mensaje);
                    }
                });
        };

        $scope.consultar();

        $scope.update = function () {
            $scope.consultar();
        };

        $scope.consultarImpresion = function (id) {

            blockUI();
            SolicitudFactory.solicitarImpresion(id)
                .then(function (response) {
                    if (response.data.estado == 0) {
                        SolicitudFactory.imprimirSolicitud(response.data.data[0]).then(function (response) {
                            if (response.data.estado == 0) {
                                window.location.replace('/SwitcherSanCristobal-web/solicitid-impresion-servlet');
                                unBlockUI();
                            } else {
                                unBlockUI();
                                showNotification('alerta', response.data.mensaje);
                            }
                        }, function (response) {
                            unBlockUI();
                            showNotification('alerta', "Error en la operacion.");
                        });
                    } else {
                        unBlockUI();
                        showNotification('alerta', response.data.mensaje);
                    }
                });
        };

    }]);