app.controller('OtrosPagosComprobanteCtrl', ['$scope', '$state', '$sessionStorage', '$localStorage', '$rootScope', '$location', 'MainFactory',
    function ($scope, $state, $sessionStorage, $localStorage, $rootScope, $location, MainFactory) {

        $scope.comprobante = $localStorage.comprobante;
        $scope.confirmacion = $localStorage.datosConfirmacion;
        $scope.datosUsuario = $localStorage.datosUsuario;

        $scope.aceptar = function () {
            $state.go('app.inicio');
        }

    }]);
