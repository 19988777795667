app.controller('FacturasImpresionCtrl', ['$scope', 'MainFactory', '$state', '$sessionStorage', '$localStorage', '$rootScope', '$location', 'NgTableParams', '$filter', 'MenuFactory',
    function ($scope, MainFactory, $state, $sessionStorage, $localStorage, $rootScope, $location, NgTableParams, $filter, MenuFactory) {

        $scope.mensajeFactura = null;
        $scope.facturas;
        $scope.facturasDescargarDet = {};
        $scope.facturasDescargaCab = {};
        $scope.fechas = generarFechas();
        $scope.selected = {};
        $scope.selected.fecha = $scope.fechas[0];


        $scope.obtenerFechaInicio = function (fecha) {
            return '01' + '/' + getMesExtracto(fecha) + '/' + getAnhoExtracto(fecha);
        };

        $scope.obtenerFechaFin = function (fecha) {
            today = new Date();
            mes = today.getMonth() + 1;
            if (mes == getMesExtracto(fecha)) {
                return today.getDate() + '/' + getMesExtracto(fecha) + '/' + getAnhoExtracto(fecha);
            } else {
                day = new Date(getAnhoExtracto(fecha), getMesExtracto(fecha), 0);
                return String(day).substring(8, 10) + '/' + getMesExtracto(fecha) + '/' + getAnhoExtracto(fecha);
            }
        };

        $scope.consultar = function () {

            parametros = {
                "operacion": "87",
                "producto": "18",
                "entidad": "3",
                "cliente": "1",
                "presentador": "1",
                "entrada": {
                    "iIdSesion": $localStorage.identificadorSesion,
                    "iObtFactura": "LIS",
                    "iFecInicioString": $scope.obtenerFechaInicio($scope.selected.fecha.mes),
                    "iFecFinString": $scope.obtenerFechaFin($scope.selected.fecha.mes),
                    "iNroFactura": 0,
                    "iCantidad": 20,
                    "iRegInicial": 1
                }
            }

            blockUI();
            MainFactory.doRequest(parametros)
                .then(function (response) {
                    if (response.data.estado == 0) {
                        $scope.facturas = response.data.data.oDatFactura;
                        if ($scope.facturas.length == 0) {
                            $scope.mensajeFactura = "No se encontraron Facturas";
                        }
                        $scope.tableParams = new NgTableParams({
                            page: 1,
                            count: 8
                        }, {
                                counts: [],
                                getData: function (params) {
                                    var data = $scope.facturas;
                                    data = params.filter() ? $filter('filter')(data, params.filter()) : data;
                                    data = params.orderBy() ? $filter('orderBy')(data, params.orderBy()) : data;
                                    params.total(data.length);
                                    data = data.slice((params.page() - 1) * params.count(), params.page() * params.count());
                                    return data;
                                }
                            });
                        unBlockUI();
                    } else {
                        unBlockUI();
                        showNotification('alerta', response.data.mensaje);
                    }
                });
        }

        $scope.consultar();

        $scope.update = function () {
            $scope.consultar();
        };

        $scope.descargar = function (factura) {
            parametrosImp = {
                "operacion": "87",
                "producto": "18",
                "entidad": "3",
                "cliente": "1",
                "presentador": "1",
                "entrada": {
                    "iIdSesion": $localStorage.identificadorSesion,
                    "iObtFactura": "IMP",
                    "iFecInicioString": null,
                    "iFecFinString": null,
                    "iNroFactura": factura.factura,
                    "iCantidad": null,
                    "iRegInicial": null
                }
            }

            blockUI();
            MainFactory.doRequest(parametrosImp)
                .then(
                function (response) {
                    if (response.data.estado == 0) {
                        $scope.facturasDescargaCab = response.data.data.oDatFactura;
                        if ($scope.facturasDescargaCab.length > 0) {
                            parametros = {
                                "operacion": "88",
                                "producto": "18",
                                "entidad": "3",
                                "cliente": "1",
                                "presentador": "1",
                                "entrada": {
                                    "iNroCedula": factura.socCedula,
                                    "iNroFactura": factura.factura,
                                    "iCodDocumento": $scope.facturasDescargaCab[0].cabCodDocumento
                                }
                            }
                            MainFactory.doRequest(parametros)
                                .then(
                                function (response) {
                                    if (response.data.estado == 0) {
                                        $scope.facturasDescargarDet = response.data.data.oDetFactura;
                                        $scope.facturasDescargaCab[0].detalle = $scope.facturasDescargarDet;
                                        var json = JSON.stringify($scope.facturasDescargaCab[0]);
                                        var parametrosDescar = {
                                            url: "/SwitcherSanCristobal-web/facturaImprimir",
                                            method: "POST",
                                            data: json
                                        };
                                        MainFactory.getPdf(parametrosDescar)
                                            .then(
                                            function (response) {
                                                if (response.data.estado == 0) {
                                                    parametrosModImpresion = {
                                                        "operacion": "89",
                                                        "producto": "18",
                                                        "entidad": "3",
                                                        "cliente": "1",
                                                        "presentador": "1",
                                                        "entrada": {
                                                            "iNroCedula": factura.socCedula,
                                                            "iNroFactura": factura.factura,
                                                            "iTipFactura": $scope.facturasDescargaCab[0].cabCodDocumento
                                                        }
                                                    }

                                                    MainFactory.doRequest(parametrosModImpresion)
                                                        .then(
                                                        function (response) {
                                                            if (response.data.estado == 0) {
                                                                window.location.replace('/SwitcherSanCristobal-web/facturaImprimir');
                                                                unBlockUI();
                                                            } else {
                                                                showNotification('alerta', response.data.mensaje);
                                                                unBlockUI();
                                                            }
                                                        },
                                                        function (response) {
                                                            unBlockUI();
                                                            showNotification('alerta', "Error en la operacion.");
                                                        });
                                                } else {
                                                    unBlockUI();
                                                    showNotification('alerta', response.data.mensaje);
                                                }
                                            },
                                            function (response) {
                                                unBlockUI();
                                                showNotification('alerta', "Error en la operacion.");
                                            });
                                    } else {
                                        unBlockUI();
                                        showNotification('alerta', response.data.mensaje);
                                    }
                                },
                                function (response) {
                                    unBlockUI();
                                    showNotification('alerta', "Error en la operacion.");
                                });
                        } else {
                            unBlockUI();
                            showNotification('alerta', "No se encontr&oacute; la factura");
                        }
                    } else {
                        unBlockUI();
                        showNotification('alerta', response.data.mensaje);
                    }
                },
                function (response) {
                    unBlockUI();
                    showNotification('alerta', "Error en la operacion.");
                });
        }
    }]);