app.controller('TransferenciaCtrl', ['$scope', 'MainFactory', '$state', '$sessionStorage', '$localStorage', '$rootScope', '$location', 'ngDialog',
    function ($scope, MainFactory, $state, $sessionStorage, $localStorage, $rootScope, $location, ngDialog) {

        $scope.cajasAhorro;
        $scope.datos = {};
        $scope.datos.origen;
        $scope.datos.destino;
        $scope.datos.monto = "";
        $scope.datos.cedula = "";
        $scope.datos.ctaDestino = "";
        $scope.datos.otraCuenta = {};
        $scope.datos.otraCuenta.cuenta = '';
        $scope.datos.cuenta = "S";
        $scope.radio1 = true;
        $scope.radio2 = false;
        $scope.radio3 = false;
        $scope.cuentaDestino = {};
        $scope.cuenta = {};
        $localStorage.menuActive = 2;
        $scope.nroSocio = $localStorage.datosUsuario.nroSocio;

        var cuentasAhorro = {
            operacion: "73",
            producto: "18",
            entidad: "3",
            cliente: "1",
            presentador: "1",
            entrada: {
                iIdSesion: $localStorage.identificadorSesion,
                iTipSaldo: "AHR"
            }
        };

        $scope.show = function (value) {
            return value != $scope.datos.origen && value.wbTipRenta == 'N';
        }

        blockUI();
        MainFactory.doRequest(cuentasAhorro)
            .then(function (response) {
                if (response.data.estado == 0) {
                    $scope.cajasAhorro = response.data.data.oDatProducto;
                }
                unBlockUI();
            },
            function (response) {
                unBlockUI();
                showNotification('alerta', "No se pudo obtener Cuentas de Ahorro");
            });

        $scope.cargarCtaFav = function () {

            parametros = {
                operacion: "75",
                producto: "18",
                entidad: "3",
                cliente: "1",
                presentador: "1",
                entrada: {
                    iIdSesion: $localStorage.identificadorSesion,
                    iOpeCuenta: "OBT",
                    iCtaAlias: "",
                    iCtaNombre: "",
                    iCtaMail: "",
                    iCtaTelefono: "",
                    iCtaCi: "",
                    iCtaNumero: ""
                }
            }

            blockUI();
            MainFactory.doRequest(parametros)
                .then(function (response) {
                    if (response.data.estado == 0) {
                        $scope.cuentasFav = response.data.data.oCtaFavorita;
                    }
                    unBlockUI();
                },
                function (response) {
                    unBlockUI();
                    showNotification('alerta', "No se pudo obtener Cuentas Favoritas");
                });
        }

        $scope.cargarCtaFav();

        $scope.limpiarCampos = function () {
            $scope.datos.origen = null;
            var element = document.getElementById('cuenta_origen_select');
            element.value = "1";
            $scope.datos.monto = null;
            if ($scope.datos.cuenta == "S") {
                $scope.datos.destino = null;
                var element1 = document.getElementById('cuenta_destino_select');
                element1.value = "1";
            } else {
                $scope.datos.otraCuenta = null;
                var element2 = document.getElementById('cuenta_otras_select');
                element2.value = "1";
            }
        };

        $scope.checkRadio = function (value) {
            $scope.datos.monto = null;
            if (value == 'S') {
                var element1 = document.getElementById('cuenta_destino_select');
                element1.value = "";
            } else if (value == 'N') {
                var element = document.getElementById('cuenta_otras_select');
                element.value = "";
            }
            $scope.datos.cedula = null;
            $scope.datos.ctaDestino;
        }


        $scope.confirmar = function (form) {
            if (!form.$valid) {
                return;
            }

            blockUI();
            if (isNumber(formatoMonto($scope.datos.monto)) && formatoMonto($scope.datos.monto) > 0) {
                $scope.transferencia = {};
                $scope.transferencia.cuentaOrigen = $scope.datos.origen.wbNumero;
                $scope.transferencia.cuentaOrigenDescripcion = $scope.nroSocio + '-' + $scope.datos.origen.wbCodigo;
                $scope.transferencia.cuentaOrigenSaldo = $scope.datos.origen.wbMtoActual;
                if ($scope.datos.cuenta == "S") {
                    $scope.transferencia.cuentaDestino = $localStorage.usuario + '-' + $scope.datos.destino.wbCodigo;
                    $scope.transferencia.cuentaDestinoDescripcion = $scope.nroSocio + '-' + $scope.datos.destino.wbCodigo;
                    $scope.ctaCi = $localStorage.usuario;
                    $scope.CtaNumero = $scope.datos.destino.wbCodigo;
                    $scope.transferencia.concepto = 'TRANSFERENCIA ENTRE CUENTAS';
                } else if ($scope.datos.cuenta == "N") {
                    $scope.transferencia.cuentaDestino = $scope.datos.otraCuenta.docCredito + '-' + $scope.datos.otraCuenta.cuenta;
                    $scope.transferencia.cuentaDestinoDescripcion = $scope.datos.otraCuenta.alias + '-' + $scope.datos.otraCuenta.cuenta;
                    $scope.ctaCi = $scope.datos.otraCuenta.docCredito;
                    $scope.CtaNumero = $scope.datos.otraCuenta.cuenta;
                    $scope.transferencia.concepto = 'TRANSFERENCIA OTRO SOCIO';
                } else {
                    $scope.ctaCi = $scope.datos.cedula;
                    $scope.CtaNumero = $scope.datos.ctaDestino;
                    $scope.transferencia.cuentaDestino = $scope.datos.cedula + '-' + $scope.datos.ctaDestino;
                    $scope.transferencia.cuentaDestinoDescripcion = $scope.datos.cedula + '-' + $scope.datos.ctaDestino;
                    $scope.transferencia.concepto = 'TRANSFERENCIA OTRO SOCIO';
                }

                var param = {
                    "operacion": "82",
                    "producto": "18",
                    "entidad": "3",
                    "cliente": "1",
                    "presentador": "1",
                    "entrada": {
                        "iCtaCi": $scope.ctaCi,
                        "iCtaNumero": $scope.CtaNumero
                    }
                };

                MainFactory.doRequest(param)
                    .then(function (response) {
                        if (response.data.estado == 0) {
                            $scope.transferencia.consulta = response.data.data.oDatCuenta[0];
                            $scope.transferencia.monto = formatoMonto($scope.datos.monto);
                            $scope.transferencia.fecha = getFecha();
                            $localStorage.transferencia = $scope.transferencia;
                            unBlockUI();
                            $state.go('app.transferenciaconfirmacion');
                        } else {
                            unBlockUI();
                            showNotification('alerta', response.data.mensaje);
                        }
                    },
                    function (response) {
                        unBlockUI();
                        showNotification('alerta', "No se pudo consultar transferencia");
                    });
            } else {
                unBlockUI();
                showNotification('alerta', "Monto ingresado inválido");
            }
        }

        $scope.agregarCuenta = function () {
            $scope.cuenta = {};
            //$('#agregarCuentaModal').modal('show');
            ngDialog.open({
                template: 'app/transferencias/agregar.html',
                className: 'ngdialog-theme-default',
                width: 600, height: 500,
                id: 'agregarModal',
                closeByNavigation: true
            });
        }

        $scope.limpiarCamposCuenta = function () {
            $scope.cuenta = {};
        }

        $scope.guardar = function (form) {
            if (!form.$valid) {
                return;
            }

            parametros = {
                operacion: "75",
                producto: "18",
                entidad: "3",
                cliente: "1",
                presentador: "1",
                entrada: {
                    iIdSesion: $localStorage.identificadorSesion,
                    iOpeCuenta: "INS",
                    iCtaAlias: $scope.cuenta.alias,
                    iCtaNombre: $scope.cuenta.nombre,
                    iCtaMail: $scope.cuenta.email,
                    iCtaTelefono: $scope.cuenta.telefono,
                    iCtaCi: $scope.cuenta.ciRuc,
                    iCtaNumero: $scope.cuenta.numero
                }
            }

            blockUI();
            MainFactory.doRequest(parametros)
                .then(function (response) {
                    if (response.data.estado == 0) {
                        $scope.cargarCtaFav();
                        showNotification('info', response.data.data.oCtaFavorita[0].observacion);
                        ngDialog.close('agregarModal');
                        //$('#agregarCuentaModal').modal('hide');
                        unBlockUI();
                    } else {
                        showNotification('alerta', response.data.mensaje);
                        //$('#agregarCuentaModal').modal('hide');
                        ngDialog.close('agregarModal');
                        unBlockUI();
                    }
                });
        }
    }]);

