app.controller('PagoServicioCtrl', ['$scope', 'MainFactory', 'PagoServicioFactory', '$state', '$sessionStorage', '$localStorage', '$rootScope', '$location', 'MenuFactory', 'NgTableParams', '$filter', '$timeout',
    function ($scope, MainFactory, PagoServicioFactory, $state, $sessionStorage, $localStorage, $rootScope, $location, MenuFactory, NgTableParams, $filter, $timeout) {

        $scope.cajasAhorro = [];
        $scope.datos = {};
        $scope.servicio = {};
        $scope.nroSocio = $localStorage.datosUsuario.nroSocio;
        $scope.subServicio;
        $scope.filtro;
        $scope.habilitar = false;

        var cuentasAhorro = {
            operacion: "73",
            producto: "18",
            entidad: "3",
            cliente: "1",
            presentador: "1",
            entrada: {
                iIdSesion: $localStorage.identificadorSesion,
                iTipSaldo: "AHR"
            }
        };

        blockUI();
        MainFactory.doRequest(cuentasAhorro)
                .then(function (response) {
                    if (response.data.estado === 0) {
                        $scope.cajasAhorro = response.data.data.oDatProducto;
                    } else {
                        showNotification('alerta', response.data.mensaje);
                    }
                    unBlockUI();
                },
                        function (response) {
                            unBlockUI();
                            showNotification('alerta', "No se pudo obtener Cuentas de Ahorro");
                        });

        blockUI();
        PagoServicioFactory.listarServicio()
                .then(function (response) {
                    if (response.data.estado == 0) {
                        $scope.servicio.categorias = response.data.servicios;
                        lista = [];
                        response.data.servicios.forEach(function (data) {
                            if (data.servicios) {
                                data.servicios.forEach(function (data) {
                                    lista.push(data);
                                });
                            }
                        });
                        $scope.servicio.lista = lista;
                    } else {
                        showNotification('alerta', response.data.mensaje);
                    }
                    unBlockUI();
                },
                        function (response) {
                            unBlockUI();
                            showNotification('alerta', "No se pudo obtener Servicios");
                        });

        $scope.limpiarCampos = function () {
            $scope.datos.cuenta = null;
            var element = document.getElementById('cuenta_select');
            element.value = "1";

            $scope.datos.categoriaSeleccionada = null;
            var element1 = document.getElementById('servicio_select');
            element1.value = "1";

            $scope.servicios = null;

        };

        $scope.filtrarServicio = function (text) {
            $scope.ayuda = null;
            $scope.mostrarServicio = false;
            $scope.servicio.detalle = null;
            if (text == "") {
                $scope.habilitar = false;
            } else {
                $scope.habilitar = true;
            }
            $scope.filtro = text;
            aux = [];
            $scope.servicio.lista.filter((item) => {
                if (item.descripcion.toUpperCase().search(text.toUpperCase()) >= 0 || text === "") {
                    aux.push(item);
                }
            });
            $scope.servicios = aux;
        };

        $scope.updateCategoria = function () {
            blockUI();
            $scope.ayuda = null;
            $scope.mostrarServicio = false;
            $scope.servicio.detalle = null;
            $scope.servicios = $scope.datos.categoriaSeleccionada.servicios;
            unBlockUI();
        }

        $scope.getUrl = function (path) {
            if (path) {
                return path;
            } else {
                return 'assets/img/imagen-no-disponible.png';
            }
        };

        $scope.updateServicio = function (servicio) {
            $scope.mostrarServicio = false;
            $scope.servicio.detalle = null;
            $scope.datos.servicioSeleccionado = servicio;
            $scope.servicios.forEach(function (data) {
                if (servicio.descripcion != data.descripcion) {
                    document.getElementById("" + data.descripcion).style.outline = '2px solid #ebebeb';
                }
            });
            //resalta la imagen
            document.getElementById("" + servicio.descripcion).style.outline = '2px solid #008441';
            if ($scope.datos.servicioSeleccionado.servicios) {
                $scope.mostrarServicio = true;
            } else {
                $scope.obtenerParametro();
            }
            if ($scope.datos.servicioSeleccionado.servicios.length == 1) {
                $scope.datos.servicioSeleccionado.subServicio = $scope.datos.servicioSeleccionado.servicios[0];
                $scope.obtenerParametro();
            }
            //hace focus directo al combo de servicio
            $timeout(function () {
                document.getElementById("pago_servicio_select").focus();
            }, 500);
        };

        $scope.obtenerParametro = function () {
            if ($scope.datos.servicioSeleccionado.servicios) {
                $scope.servicio.seleccionadoSub = $scope.datos.servicioSeleccionado.subServicio;
            } else {
                $scope.servicio.seleccionadoSub = $scope.datos.servicioSeleccionado;
            }
            blockUI();
            PagoServicioFactory.parametrosServicio($scope.servicio.seleccionadoSub)
                    .then(function (response) {
                        if (response.data.estado == 0) {
                            $scope.servicio.detalle = response.data;
                            $timeout(function () {
                                $.each($scope.servicio.detalle.parametros, function (i, val) {
                                    if (val.formato == 'DATE') {
                                        $("#" + val.codigo).datepicker({
                                            changeMonth: true,
                                            changeYear: true
                                        });
                                    }
                                    if (val.seleccionable == true) {
                                        $scope.listOption = JSON.parse(val.adicionales);
                                    }
                                });
                            }, 100);
                            try {
                                var x = JSON.parse(atob($scope.servicio.detalle.datos));
                                $scope.ayuda = x.datos.ayuda;
                            } catch (err) {
                            }
                        } else {
                            showNotification('alerta', response.data.mensaje);
                        }
                        unBlockUI();
                    },
                            function (response) {
                                unBlockUI();
                                showNotification('alerta', "No se pudo obtener parámetros del servicio");
                            });
        };

        $scope.confirmar = function (form) {
            if (!form.$valid) {
                return;
            }

            blockUI();
            if ($scope.servicio.seleccionadoSub && $scope.servicio.detalle) {
                PagoServicioFactory.facturas($scope.servicio.seleccionadoSub, $scope.servicio.detalle)
                        .then(function (response) {
                            if (response.data.estado == 0) {
                                $scope.servicio.detalle.datos = response.data.datos;
                                $scope.datos.servicio = $scope.servicio.detalle;
                                $scope.datos.detalleFactura = response.data;
                                $scope.datos.serviceSelected = $scope.servicio.seleccionadoSub;
                                $localStorage.detalleServicio = $scope.datos;
                                $state.go('app.pagos.detalleServicio');
                            } else {
                                showNotification('alerta', response.data.mensaje);
                            }
                            unBlockUI();
                        },
                                function (response) {
                                    unBlockUI();
                                    showNotification('alerta', "No se pudo obtener factura del servicio");
                                });
            } else {
                unBlockUI();
                showNotification('alerta', "Seleccione un servicio");
            }
        }
    }]);

