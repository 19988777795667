app.controller('DatosSocioCtrl', ['$scope', 'MainFactory', '$state', '$sessionStorage', '$localStorage', '$rootScope', '$location', 'NgTableParams', '$filter', 'MenuFactory',
    function ($scope, MainFactory, $state, $sessionStorage, $localStorage, $rootScope, $location, NgTableParams, $filter, MenuFactory) {
        $scope.paso = 1;

        $scope.marker = null;
        $scope.markerPersonal = null;
        $scope.markerLaboral = null;
        $scope.map = null;
        $scope.$on('mapInitialized', function (event, map) {
            $scope.map = map;
            if ($scope.paso == 3 && $scope.markerPersonal) {
                $scope.markerPersonal.setMap($scope.map);
            } else if ($scope.paso == 5 && $scope.markerLaboral) {
                $scope.markerLaboral.setMap($scope.map);
            }
        });

        $scope.datosSocio = {
            tipo: null,//PMO,TCR
            cedula: null,//String "0",
            nroSocio: null,//String "0",
            nombre: null,//String "nombre",
            apellido: null,//String "apellido",
            estadoCivil: null,//B,S,C,D,V,U,H
            educacion: null,//P,S,T,U
            cantHijosMenores: null,//0,
            cantHijosMayores: null,//0,
            profesion: null,//String "profesion",
            facebook: null,//String "facebook",
            twitter: null,//String "twitter",
            instagram: null,//String "instagram",
            nisAnde: null,//0,
            cuentaCopaco: null,//String "cuentaCopaco",
            cuentaEssap: null,//String "cuentaEssap",
            direccion: null,//String "direccion",
            nroCasa: null,//0,
            nombreEdificio: null,//String "nombreEdificio",
            piso: null,//0,
            nroDpto: null,//0,
            barrio: null,//String "barrio",
            ciudad: null,//String "ciudad",
            latitud: null,//String "0",
            longitud: null,//String "0",
            tipoVivienda: null,//P,D,A,O
            distrito: null,//String "distrito",
            matricula: null,//String "matricula",
            cuentaCatastral: null,//String "cuentaCatastral",
            esHipotecado: null,//N,S
            valorActual: null,//0,
            superficie: null,//0,
            superficieConstruida: null,//0,
            yearsResidencia: null,//0,
            telefono: null,//String "0",
            celular: null,//String "0",
            correoElectronico: null,//String "correo@correo.com",
            marcaVehiculo: null,//String "marcaVehiculo",
            modeloVehiculo: null,//String "modeloVehiculo",
            yearVehiculo: null,//2022,
            estadoVehiculo: null,//T,P
            tieneSeguroVehiculo: null,//S,N
            valorActualVehiculo: null,//0,
            lugarTrabajo: null,//String "lugarTrabajo",
            cargoTrabajo: null,//String "cargoTrabajo",
            antiguedadTrabajo: null,//0,
            antiguedadCargoTrabajo: null,//0,
            direccionLaboral: null,//String "direccionLaboral",
            latitudLaboral: null,//String "0",
            longLaboral: null,//String "0",
            nroCasaLaboral: null,//String "nroCasaLaboral",
            barrioLaboral: null,//String "barrioLaboral",
            ciudadLaboral: null,//String "ciudadLaboral",
            telefonoLaboral: null,//String "telefonoLaboral",
            correoElectronicoLaboral: null,//String "correoElectronicoLaboral",
            horarioContactoLaboral: null,//String "horarioContactoLaboral",
            nroSocioConyuge: null,//0,
            cedulaConyuge: null,//0,
            nombreConyuge: null,//String - NOT REQUIRED,
            apellidoConyuge: null,//String - NOT REQUIRED,
            celularConyuge: null,//String - NOT REQUIRED,
            correoConyuge: null,//String - NOT REQUIRED,
            profesionConyuge: null,//String - NOT REQUIRED,
            trabajoConyuge: null,//String - NOT REQUIRED,
            antiguedadConyuge: null,//String - NOT REQUIRED,
            telefonoLaboralConyuge: null,//String - NOT REQUIRED,
            direccionLaboralConyuge: null,//String - NOT REQUIRED,
            totalIngresosTitular: null,//0.0,
            totalIngresosConyuge: null,//0.0,
            totalEgresos: null,//0.0,
            entidadReferenciaBancaria1: null,//String - NOT REQUIRED,
            telefonoReferenciaBancaria1: null,//String - NOT REQUIRED,
            cuotaReferenciaBancaria1: null,//0.0,
            garantiaReferenciaBancaria1: null,//String - NOT REQUIRED,
            saldoReferenciaBancaria1: null,//0.0,
            entidadReferenciaBancaria2: null,//String - NOT REQUIRED,
            telefonoReferenciaBancaria2: null,//String - NOT REQUIRED,
            cuotaReferenciaBancaria2: null,//0.0,
            garantiaReferenciaBancaria2: null,//String - NOT REQUIRED,
            saldoReferenciaBancaria2: null,//0.0,
            entidadReferenciaBancaria3: null,//String - NOT REQUIRED,
            telefonoReferenciaBancaria3: null,//String - NOT REQUIRED,
            cuotaReferenciaBancaria3: null,//0.0,
            garantiaReferenciaBancaria3: null,//String - NOT REQUIRED,
            saldoReferenciaBancaria3: null,//0.0,
            entidadReferenciaBancaria4: null,//String - NOT REQUIRED,
            telefonoReferenciaBancaria4: null,//String - NOT REQUIRED,
            cuotaReferenciaBancaria4: null,//0.0,
            garantiaReferenciaBancaria4: null,//String - NOT REQUIRED,
            saldoReferenciaBancaria4: null,//0.0,
            nombreReferenciaPersonal1: null,//String - NOT REQUIRED,
            direccionReferenciaPersonal1: null,//String - NOT REQUIRED,
            telefonoReferenciaPersonal1: null,//String - NOT REQUIRED,
            nombreReferenciaPersonal2: null,//String - NOT REQUIRED,
            direccionReferenciaPersonal2: null,//String - NOT REQUIRED,
            telefonoReferenciaPersonal2: null,//String - NOT REQUIRED,
            nombreReferenciaPersonal3: null,//String - NOT REQUIRED,
            direccionReferenciaPersonal3: null,//String - NOT REQUIRED,
            telefonoReferenciaPersonal3: null,//String - NOT REQUIRED,
            tipoTarjetaCredito: null,//String - NOT REQUIRED,
            plazo: null,//0,
            formula: null,//F,A,M
            importeSolicitado: null,//0.0,
            destinoCredito: null,//String - NOT REQUIRED,
            garantiaCredito: null,//String - NOT REQUIRED,
        };


        $scope.actualizarMarcadores = function () {
            if ($scope.paso == 3) {
                if ($scope.markerLaboral) {
                    $scope.markerLaboral.setVisible(false);
                }
                if ($scope.markerPersonal) {
                    $scope.markerPersonal.setVisible(true);
                }
            } else if ($scope.paso == 5) {
                if ($scope.markerPersonal) {
                    $scope.markerPersonal.setVisible(false);
                }
                if ($scope.markerLaboral) {
                    $scope.markerLaboral.setVisible(true);
                }
            }
        };

        $scope.siguiente = function (valid) {
            if (valid) {
                $scope.paso = $scope.paso + 1;
                // console.log("PASO", $scope.paso);
                $scope.actualizarMarcadores();
            }
        }

        $scope.volverAtras = function () {
            $scope.paso = $scope.paso - 1;
            // console.log("PASO", $scope.paso);
            $scope.actualizarMarcadores();
        }

        $scope.limpiarDatos = function (data) {
            data = {
                tipo: data.tipo,
                cedula: data.cedula,
                nroSocio: data.nroSocio,
                nombre: data.nombre,
                apellido: data.apellido,
                estadoCivil: data.estadoCivil,
                educacion: data.educacion,
                cantHijosMenores: data.cantHijosMenores ? parseInt(data.cantHijosMenores.toString().replace(/\./g, '')) : null,
                cantHijosMayores: data.cantHijosMayores ? parseInt(data.cantHijosMayores.toString().replace(/\./g, '')) : null,
                profesion: data.profesion,
                facebook: data.facebook,
                twitter: data.twitter,
                instagram: data.instagram,
                nisAnde: data.nisAnde ? parseInt(data.nisAnde.toString().replace(/\./g, '')) : null,
                cuentaCopaco: data.cuentaCopaco,
                cuentaEssap: data.cuentaEssap,
                direccion: data.direccion,
                nroCasa: data.nroCasa ? parseInt(data.nroCasa.toString().replace(/\./g, '')) : null,
                nombreEdificio: data.nombreEdificio,
                piso: data.piso ? parseInt(data.piso.toString().replace(/\./g, '')) : null,
                nroDpto: data.nroDpto ? parseInt(data.nroDpto.toString().replace(/\./g, '')) : null,
                barrio: data.barrio,
                ciudad: data.ciudad,
                latitud: data.latitud,
                longitud: data.longitud,
                tipoVivienda: data.tipoVivienda,
                distrito: data.distrito,
                matricula: data.matricula,
                cuentaCatastral: data.cuentaCatastral,
                esHipotecado: data.esHipotecado,
                valorActual: data.valorActual ? parseInt(data.valorActual.toString().replace(/\./g, '')) : null,
                superficie: data.superficie ? parseInt(data.superficie.toString().replace(/\./g, '')) : null,
                superficieConstruida: data.superficieConstruida ? parseInt(data.superficieConstruida.toString().replace(/\./g, '')) : null,
                yearsResidencia: data.yearsResidencia ? parseInt(data.yearsResidencia.toString().replace(/\./g, '')) : null,
                telefono: data.telefono,
                celular: data.celular,
                correoElectronico: data.correoElectronico,
                marcaVehiculo: data.marcaVehiculo,
                modeloVehiculo: data.modeloVehiculo,
                yearVehiculo: data.yearVehiculo ? parseInt(data.yearVehiculo.toString().replace(/\./g, '')) : null,
                estadoVehiculo: data.estadoVehiculo,
                tieneSeguroVehiculo: data.tieneSeguroVehiculo,
                valorActualVehiculo: data.valorActualVehiculo ? parseInt(data.valorActualVehiculo.toString().replace(/\./g, '')) : null,
                lugarTrabajo: data.lugarTrabajo,
                cargoTrabajo: data.cargoTrabajo,
                antiguedadTrabajo: data.antiguedadTrabajo ? parseInt(data.antiguedadTrabajo.toString().replace(/\./g, '')) : null,
                antiguedadCargoTrabajo: data.antiguedadCargoTrabajo ? parseInt(data.antiguedadCargoTrabajo.toString().replace(/\./g, '')) : null,
                direccionLaboral: data.direccionLaboral,
                latitudLaboral: data.latitudLaboral,
                longLaboral: data.longLaboral,
                nroCasaLaboral: data.nroCasaLaboral,
                barrioLaboral: data.barrioLaboral,
                ciudadLaboral: data.ciudadLaboral,
                telefonoLaboral: data.telefonoLaboral,
                correoElectronicoLaboral: data.correoElectronicoLaboral,
                horarioContactoLaboral: data.horarioContactoLaboral,
                nroSocioConyuge: data.nroSocioConyuge ? parseInt(data.nroSocioConyuge.toString().replace(/\./g, '')) : null,
                cedulaConyuge: data.cedulaConyuge ? parseInt(data.cedulaConyuge.toString().replace(/\./g, '')) : null,
                nombreConyuge: data.nombreConyuge,
                apellidoConyuge: data.apellidoConyuge,
                celularConyuge: data.celularConyuge,
                correoConyuge: data.correoConyuge,
                profesionConyuge: data.profesionConyuge,
                trabajoConyuge: data.trabajoConyuge,
                antiguedadConyuge: data.antiguedadConyuge,
                telefonoLaboralConyuge: data.telefonoLaboralConyuge,
                direccionLaboralConyuge: data.direccionLaboralConyuge,
                totalIngresosTitular: data.totalIngresosTitular ? parseFloat(data.totalIngresosTitular.toString().replace(/\./g, '')) : null,
                totalIngresosConyuge: data.totalIngresosConyuge ? parseFloat(data.totalIngresosConyuge.toString().replace(/\./g, '')) : null,
                totalEgresos: data.totalEgresos ? parseFloat(data.totalEgresos.toString().replace(/\./g, '')) : null,
                entidadReferenciaBancaria1: data.entidadReferenciaBancaria1,
                telefonoReferenciaBancaria1: data.telefonoReferenciaBancaria1,
                cuotaReferenciaBancaria1: data.cuotaReferenciaBancaria1 ? parseFloat(data.cuotaReferenciaBancaria1.toString().replace(/\./g, '')) : null,
                garantiaReferenciaBancaria1: data.garantiaReferenciaBancaria1,
                saldoReferenciaBancaria1: data.saldoReferenciaBancaria1 ? parseFloat(data.saldoReferenciaBancaria1.toString().replace(/\./g, '')) : null,
                entidadReferenciaBancaria2: data.entidadReferenciaBancaria2,
                telefonoReferenciaBancaria2: data.telefonoReferenciaBancaria2,
                cuotaReferenciaBancaria2: data.cuotaReferenciaBancaria2 ? parseFloat(data.cuotaReferenciaBancaria2.toString().replace(/\./g, '')) : null,
                garantiaReferenciaBancaria2: data.garantiaReferenciaBancaria2,
                saldoReferenciaBancaria2: data.saldoReferenciaBancaria2 ? parseFloat(data.saldoReferenciaBancaria2.toString().replace(/\./g, '')) : null,
                entidadReferenciaBancaria3: data.entidadReferenciaBancaria3,
                telefonoReferenciaBancaria3: data.telefonoReferenciaBancaria3,
                cuotaReferenciaBancaria3: data.cuotaReferenciaBancaria3 ? parseFloat(data.cuotaReferenciaBancaria3.toString().replace(/\./g, '')) : null,
                garantiaReferenciaBancaria3: data.garantiaReferenciaBancaria3,
                saldoReferenciaBancaria3: data.saldoReferenciaBancaria3 ? parseFloat(data.saldoReferenciaBancaria3.toString().replace(/\./g, '')) : null,
                entidadReferenciaBancaria4: data.entidadReferenciaBancaria4,
                telefonoReferenciaBancaria4: data.telefonoReferenciaBancaria4,
                cuotaReferenciaBancaria4: data.cuotaReferenciaBancaria4 ? parseFloat(data.cuotaReferenciaBancaria4.toString().replace(/\./g, '')) : null,
                garantiaReferenciaBancaria4: data.garantiaReferenciaBancaria4,
                saldoReferenciaBancaria4: data.saldoReferenciaBancaria4 ? parseFloat(data.saldoReferenciaBancaria4.toString().replace(/\./g, '')) : null,
                nombreReferenciaPersonal1: data.nombreReferenciaPersonal1,
                direccionReferenciaPersonal1: data.direccionReferenciaPersonal1,
                telefonoReferenciaPersonal1: data.telefonoReferenciaPersonal1,
                nombreReferenciaPersonal2: data.nombreReferenciaPersonal2,
                direccionReferenciaPersonal2: data.direccionReferenciaPersonal2,
                telefonoReferenciaPersonal2: data.telefonoReferenciaPersonal2,
                nombreReferenciaPersonal3: data.nombreReferenciaPersonal3,
                direccionReferenciaPersonal3: data.direccionReferenciaPersonal3,
                telefonoReferenciaPersonal3: data.telefonoReferenciaPersonal3,
                tipoTarjetaCredito: data.tipoTarjetaCredito,
                plazo: data.plazo ? parseInt(data.plazo.toString().replace(/\./g, '')) : null,
                formula: data.formula,
                importeSolicitado: data.importeSolicitado ? parseFloat(data.importeSolicitado.toString().replace(/\./g, '')) : null,
                destinoCredito: data.destinoCredito,
                garantiaCredito: data.garantiaCredito,
            };
            return data;
        };

        $scope.formatAux = function (data) {
            if (data) {
                var num = data.toString().replace(/[^0-9]/g, '');
                if (!isNaN(num)) {
                    num = num.toString().split('').reverse().join('').replace(/(?=\d*\.?)(\d{3})/g, '$1.');
                    num = num.split('').reverse().join('').replace(/^[\.]/, '');
                    return num;
                }
            }
            return null;
        }

        $scope.formatearNumeros = function () {
            $scope.datosSocio.cantHijosMenores = $scope.formatAux($scope.datosSocio.cantHijosMenores);
            $scope.datosSocio.cantHijosMayores = $scope.formatAux($scope.datosSocio.cantHijosMayores);
            $scope.datosSocio.nisAnde = $scope.formatAux($scope.datosSocio.nisAnde);
            $scope.datosSocio.nroCasa = $scope.formatAux($scope.datosSocio.nroCasa);
            $scope.datosSocio.piso = $scope.formatAux($scope.datosSocio.piso);
            $scope.datosSocio.nroDpto = $scope.formatAux($scope.datosSocio.nroDpto);
            $scope.datosSocio.valorActual = $scope.formatAux($scope.datosSocio.valorActual);
            $scope.datosSocio.superficie = $scope.formatAux($scope.datosSocio.superficie);
            $scope.datosSocio.superficieConstruida = $scope.formatAux($scope.datosSocio.superficieConstruida);
            $scope.datosSocio.yearsResidencia = $scope.formatAux($scope.datosSocio.yearsResidencia);
            $scope.datosSocio.yearVehiculo = $scope.formatAux($scope.datosSocio.yearVehiculo);
            $scope.datosSocio.valorActualVehiculo = $scope.formatAux($scope.datosSocio.valorActualVehiculo);
            $scope.datosSocio.antiguedadTrabajo = $scope.formatAux($scope.datosSocio.antiguedadTrabajo);
            $scope.datosSocio.antiguedadCargoTrabajo = $scope.formatAux($scope.datosSocio.antiguedadCargoTrabajo);
            $scope.datosSocio.nroSocioConyuge = $scope.formatAux($scope.datosSocio.nroSocioConyuge);
            $scope.datosSocio.cedulaConyuge = $scope.formatAux($scope.datosSocio.cedulaConyuge);
            $scope.datosSocio.totalIngresosTitular = $scope.formatAux($scope.datosSocio.totalIngresosTitular);
            $scope.datosSocio.totalIngresosConyuge = $scope.formatAux($scope.datosSocio.totalIngresosConyuge);
            $scope.datosSocio.totalEgresos = $scope.formatAux($scope.datosSocio.totalEgresos);
            $scope.datosSocio.cuotaReferenciaBancaria1 = $scope.formatAux($scope.datosSocio.cuotaReferenciaBancaria1);
            $scope.datosSocio.saldoReferenciaBancaria1 = $scope.formatAux($scope.datosSocio.saldoReferenciaBancaria1);
            $scope.datosSocio.cuotaReferenciaBancaria2 = $scope.formatAux($scope.datosSocio.cuotaReferenciaBancaria2);
            $scope.datosSocio.saldoReferenciaBancaria2 = $scope.formatAux($scope.datosSocio.saldoReferenciaBancaria2);
            $scope.datosSocio.cuotaReferenciaBancaria3 = $scope.formatAux($scope.datosSocio.cuotaReferenciaBancaria3);
            $scope.datosSocio.saldoReferenciaBancaria3 = $scope.formatAux($scope.datosSocio.saldoReferenciaBancaria3);
            $scope.datosSocio.cuotaReferenciaBancaria4 = $scope.formatAux($scope.datosSocio.cuotaReferenciaBancaria4);
            $scope.datosSocio.saldoReferenciaBancaria4 = $scope.formatAux($scope.datosSocio.saldoReferenciaBancaria4);
            $scope.datosSocio.plazo = $scope.formatAux($scope.datosSocio.plazo);
            $scope.datosSocio.importeSolicitado = $scope.formatAux($scope.datosSocio.importeSolicitado);
        };

        $scope.confirmar = function (valid) {
            if (valid) {
                $scope.datosSocio = $scope.limpiarDatos($scope.datosSocio);
                console.log("DATOS SOCIO:", $scope.datosSocio);
                $('#cambioDatosModal').modal('show');
            }
        }

        $scope.cancelar = function () {
            $('#cambioDatosModal').modal('hide');
        }

        $scope.cambiarDatos = function () {
            blockUI();
            MainFactory.cargarDatosSocio($scope.datosSocio).then(function (response) {
                if (response.data.estado == 0) {
                    showNotification('info', response.data.mensaje);
                    $scope.paso = 200;
                    unBlockUI();
                } else {
                    $scope.paso = 1;
                    showNotification('alerta', response.data.mensaje);
                    unBlockUI();
                }
                $scope.formatearNumeros();
                $('#cambioDatosModal').modal('hide');
            });
        }

        $scope.toLogin = function () {
            $state.go('login');
        }

        $scope.addMarker = function (event) {
            var lat = event.latLng.lat();
            var lng = event.latLng.lng();

            if ($scope.paso == 3) {
                $scope.marker = $scope.markerPersonal;
            } else if ($scope.paso == 5) {
                $scope.marker = $scope.markerLaboral;
            }

            if ($scope.marker) {
                $scope.marker.setMap(null);
            }

            $scope.marker = new google.maps.Marker({
                title: ($scope.paso == 3 ? "Mi casa" : $scope.paso == 5 ? "Mi Trabajo" : "Ubicacion")
            });
            var latlng = new google.maps.LatLng(lat, lng);
            $scope.marker.setPosition(latlng);
            $scope.marker.setMap($scope.map);

            if ($scope.paso == 3) {
                $scope.markerPersonal = $scope.marker;
                $scope.datosSocio.latitud = '' + lat;
                $scope.datosSocio.longitud = '' + lng;
            } else if ($scope.paso == 5) {
                $scope.markerLaboral = $scope.marker;
                $scope.datosSocio.latitudLaboral = '' + lat;
                $scope.datosSocio.longLaboral = '' + lng;
            }
            $scope.marker = null;
        };

        $scope.patFunc = function (data) {
            return data ? data.replace(/((?<=\,\d*)\,|[a-zA-Z])/g, '') : data;
        }

        $scope.formatearSoloEntero = function (obj, key) {
            if (obj && obj[key]) {
                var val = obj[key].toString();
                obj[key] = val.replace(/[^0-9]+/g, "");
                // console.log(obj[key]," - ",typeof obj[key]);
            }
        };

        $scope.fixchange = function (obj, key) {
            if (obj && obj[key]) {
                var val = obj[key].toString();
                obj[key] = val;
                console.log("fixchange -> ", key, " = ", obj[key]);
            }
        };
    }
]);