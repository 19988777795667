app.controller('ManifestacionBienesConfirmacionCtrl', ['$scope', '$localStorage', 'MainFactory', '$state',
    function ($scope, $localStorage, MainFactory, $state) {
        $scope.ingresos = $localStorage.ingresos;
        $scope.egresos = $localStorage.egresos;
        $scope.request = {};
        unBlockUI();
        $scope.onSubmit = function (form) {
            blockUI();
            if (!form.$valid) {
                return;
            }

            if ($scope.tipo) {
                if ($scope.pin === '') {
                    showNotification('alerta', 'Ingrese su código de verificación');
                    unBlockUI();
                } else {
                    if ($scope.tipo === "PIN") {
                        $scope.actualizar();
                    } else {
                        $scope.verificarOtp();
                    }
                }
            } else {
                showNotification('alerta', 'Seleccione un método de verificación');
                unBlockUI();
            }
        }

        $scope.setTipoVerificacion = function (tipo) {
            $scope.pin = '';
            $scope.tipo = tipo;
        }

        $scope.verificarOtp = function () {
            blockUI();
            MainFactory.verifyOtp().then(
                function (response) {
                    if (response.data.estado === 0) {
                        $scope.actualizar();
                    } else {
                        unBlockUI();
                        showNotification('alerta', response.data.mensaje);
                    }
                },
                function (response) {
                    unBlockUI();
                    showNotification('alerta', "No se pudo realizar la actualización");
                });
        }

        $scope.actualizar = function () {
            let tipoAuth = {
                "tipo": $scope.tipo,
                "valor": $scope.pin
            };

            $scope.request.declaraciones = $localStorage.ingresos
            $scope.request.declaraciones.push(...$scope.egresos)
            $scope.request.tipoAuth = tipoAuth;

            blockUI();
            MainFactory.actualizarManifestacionBienes($scope.request).then(
                function (response) {
                    console.log(response);
                    if (response.data.estado === 0) {
                        unBlockUI();
                        $localStorage.comprobante = response;
                        $state.go('app.manifestacionBienesComprobante');
                    } else {
                        unBlockUI();
                        $state.go('app.manifestacionBienesConfirmacion');
                        showNotification('alerta', response.data.mensaje);
                    }
                },
                function (response) {
                    unBlockUI();
                    showNotification('alerta', "No se pudo realizar la operacion");
                });
        }

        $scope.cancelar = function () {
            $state.go('app.manifestacionBienes');
        }
    }]);
