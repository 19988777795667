app.controller('SimuladorAhorroCtrl', ['$scope', '$localStorage', 'MainFactory', '$state',
    function ($scope, $localStorage, MainFactory, $state) {

        $scope.tipoPagos = [];
        $scope.tipoAhorros = [];
        $scope.datos = {};


        blockUI();
        MainFactory.getParametrosSimuladorAhorro().then(function (response) {
            if (response.data.estado === 0) {
                $scope.tipoPagos = response.data.data.tipoPagos;
                $scope.tipoAhorros = response.data.data.tipoAhorros;
            } else {
                showNotification('alerta', response.data.mensaje);
            }
            unBlockUI();
        },
        function (response) {
            unBlockUI();
            showNotification('alerta', "No se pudo obtener datos");
        });


        $scope.confirmar = function (form) {
            if (!form.$valid) {
                return;
            }

            let json = {}

            json.tipoAhorro = $scope.datos.tipoAhorro.id;
            json.tipoCapital = $scope.datos.tipoPago.id;
            json.monto =  parseInt($scope.datos.monto.replace(/\./g, ''));
            json.plazo =  parseInt($scope.datos.plazo);

            blockUI();
            MainFactory.simuladorAhorros(json).then(function (response){
                if (response.data.estado === 0){
                    $localStorage.simulador = response.data.data
                    $state.go('app.simuladorAhorroDetalles');
                } else {
                    showNotification('alerta', response.data.mensaje);
                }
                unBlockUI();
            })
        }

        $scope.limpiarCampos = function () {
            $scope.datos = {};
        };

    }]
);
