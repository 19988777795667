
app.controller('SolicitudesCtrl', ["$scope", "$state", "$localStorage", "SolicitudFactory", function ($scope, $state, $localStorage, SolicitudFactory) {
    $scope.consultar = function () {
        blockUI();
        SolicitudFactory.listarSolicitudes()
            .then(function (response) {
                if (response.data.estado == 0) {
                    $scope.solicitudes = response.data.oLisSolicitudes;
                    unBlockUI();
                } else {
                    unBlockUI();
                    showNotification('alerta', response.data.mensaje);
                }
            });
    };

    $scope.consultar();

    $scope.verParametros = function (solicitud) {
        if (solicitud.nombre) {
            $localStorage.solicitud = solicitud;
        } else {
            var name = '*';
            $.each($scope.solicitudes, function (i, val) {
                if (val.idSolicitud == solicitud.idSolicitud) {
                    name = val.nombre;
                }
            });

            let datosSolicitud = {
                idSolicitud: solicitud.idSolicitud,
                nombre: name
            }
            $localStorage.solicitud = datosSolicitud;
        }

        if ($state.current.name == 'app.solicitudes-parametros') {
            $state.go('app.solicitudes-parametros-');
        } else {
            $state.go('app.solicitudes-parametros');
        }
    };

}]);

app.controller('SolicitudesParametrosCtrl', ["$scope", "$state", "$localStorage", "$timeout", "SolicitudFactory", function ($scope, $state, $localStorage,
    $timeout, SolicitudFactory) {

    $scope.parametros = [];
    $scope.solicitud = $localStorage.solicitud;
    blockUI();
    SolicitudFactory.listarParametrosSolicitud($scope.solicitud.idSolicitud)
        .then(function (response) {
            if (response.data.estado == 0) {
                $scope.parametros = response.data.oLisParametros;
                unBlockUI();
            } else {
                unBlockUI();
                showNotification('alerta', response.data.mensaje);
            }
        });

    $scope.initFecha = function (id) {
        $timeout(function () {
            $('#' + id).datepicker();
            $.datepicker.regional['es'];
        }, 1000);
    };

    $scope.enviar = function (form) {

        if (!form.$valid) {
            return;
        }

        var jsonN = {};
        jsonN.oLisParametros = $scope.parametros;

        var json = {};
        angular.copy(jsonN, json);

        var error = '';
        $.each(json.oLisParametros, function (i, val) {
            if (!val.valor && val.tipoDato != 9) {
                error = error + 'El parámetro ' + val.descripcion + ' es requerido';
                return;
            }
            if (val.tipoDato == 8) {
                val.valor = val.valor.base64;
            }
            if (val.tipoDato == 2) {
                val.valor = eliminarPuntos(val.valor);
            }
        });

        if (error != '') {
            showNotification('alerta', error);
        } else {
            blockUI();
            SolicitudFactory.realizarSolicitud(json)
                .then(function (response) {
                    if (response.data.estado == 0) {
                        showNotification('info', 'Solicitud enviada con éxito');
                        $state.reload();
                        unBlockUI();
                    } else {
                        unBlockUI();
                        showNotification('alerta', response.data.mensaje);
                    }
                });
        }
    };
}]);