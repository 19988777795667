app.controller('VerPdfCtrl', ['$scope', '$state', '$localStorage',
    function ($scope, $state, $localStorage) {

        $scope.atras = function () {
            $state.go($localStorage.atras)
        };

        $scope.url = $localStorage.url;
        $scope.titulo = $localStorage.titulo;

    }]
);
