app.controller('SolidaridadExtractoCtrl', ['$scope', 'MainFactory', '$state', '$sessionStorage', '$localStorage', '$rootScope', '$location', 'NgTableParams', '$filter',
    function ($scope, MainFactory, $state, $sessionStorage, $localStorage, $rootScope, $location, NgTableParams, $filter) {

        $localStorage.menuActive = 1;
        $scope.solidaridadExtracto;
        $scope.fechas = generarFechas();
        $scope.selected = {};
        $scope.selected.fecha = $scope.fechas[0];
        $scope.cabecera = $localStorage.cabeceraSol;
        $scope.fechas.push({
            'mes': '',
            'descripcion': 'TODOS'
        });
        $scope.cargarTabla = function () {

            parametros = {
                operacion: "74",
                producto: "18",
                entidad: "3",
                cliente: "1",
                presentador: "1",
                entrada: {
                    iIdSesion: $localStorage.identificadorSesion,
                    iTipExtracto: "SOL",
                    anho: getAnhoExtracto($scope.selected.fecha.mes),
                    mes: getMesExtracto($scope.selected.fecha.mes),
                    iCantidad: 20,
                    iRegInicial: 0
                }
            };

            blockUI();
            MainFactory.doRequest(parametros)
                    .then(function (response) {

                        if (response.data.estado == 0) {

                            $scope.solidaridadExtracto = response.data.data.oMovExtracto;

                            $scope.tableParams = new NgTableParams({
                                page: 1,
                                count: 8
                            }, {
                                counts: [],
                                getData: function (params) {
                                    var data = $scope.solidaridadExtracto;
                                    data = params.filter() ? $filter('filter')(data, params.filter()) : data;
                                    data = params.orderBy() ? $filter('orderBy')(data, params.orderBy()) : data;
                                    params.total(data.length);
                                    data = data.slice((params.page() - 1) * params.count(), params.page() * params.count());
                                    return data;
                                }
                                /*counts: [],
                                 total: $scope.solidaridadExtracto.length,
                                 getData: function (params) {
                                 var orderedData = params.sorting() ? $filter('orderBy')($scope.solidaridadExtracto, params.orderBy()) : $scope.solidaridadExtracto;
                                 return (orderedData.slice((params.page() - 1) * params.count(), params.page() * params.count()));
                                 }*/
                            });
                            unBlockUI();
                        } else {

                            showNotification('alerta', response.data.mensaje);
                            unBlockUI();
                        }
                    });

        };

        $scope.cargarTabla();

        $scope.update = function () {
            $scope.cargarTabla();
        };

        $scope.descargarPdf = function () {

            var detalle = [];
            var i = 0;

            $scope.solidaridadExtracto.forEach(function (data) {

                var nuevoDetalle = {
                    "fecha": "" + data.fecha,
                    "operacion": "",
                    "monto": "" + data.solidaridad,
                    "tipo": "",
                    "comprob": '' + data.comprobante,
                    "socTransf": "",
                    "sucursal": '' + data.sucursal
                }
                detalle[i] = nuevoDetalle;
                i++;
            });

            var json = {
                "nroSocio": '' + $localStorage.datosUsuario.nroSocio,
                "cedula": "" + $localStorage.usuario,
                "nombre": '' + $localStorage.datosUsuario.nombre,
                "anho": '' + getAnhoExtracto($scope.selected.fecha.mes),
                "mes": '' + getMesExtracto($scope.selected.fecha.mes),
                "fecIngreso": "" + $localStorage.datosUsuario.fecIngreso,
                "estado": '' + $scope.cabecera.wbPrdEstado,
                "suscrito": "",
                "integrado": "",
                "transferido": "",
                "aIntegrar": "",
                "saldoVencido": "" + $scope.cabecera.wbMtoVencido,
                "detalle": detalle
            }

            var parametros = {
                url: "/SwitcherSanCristobal-web/movimientoDeSolidaridad",
                method: "POST",
                data: json
            };

            blockUI();
            MainFactory.getPdf(parametros).then(
                    function (response) {
                        if (response.data.estado == 0) {
                            window.location.replace('/SwitcherSanCristobal-web/movimientoDeSolidaridad');
                            unBlockUI();
                        } else {
                            showNotification('alerta', response.data.mensaje);
                            unBlockUI();
                        }
                    },
                    function (response) {
                        unBlockUI();
                        $scope.mensaje = "Error en la operacion.";
                    }
            );
        }
    }]);