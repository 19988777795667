app.controller('CuentasCtrl', ['$scope', 'MainFactory', '$state', '$sessionStorage', '$localStorage', '$rootScope', '$location', 'NgTableParams', '$filter', 'MenuFactory', 'ngDialog',
    function ($scope, MainFactory, $state, $sessionStorage, $localStorage, $rootScope, $location, NgTableParams, $filter, MenuFactory, ngDialog) {

        $scope.cuentas;
        $scope.datosCuenta = $localStorage.editarCuenta;
        $scope.eliminar = $localStorage.eliminarCuenta;
        $scope.tableParams;
        $scope.editados = angular.copy($scope.datosCuenta);

        $scope.cargarTabla = function () {
            
            parametros = {
                operacion: "75",
                producto: "18",
                entidad: "3",
                cliente: "1",
                presentador: "1",
                entrada: {
                    iIdSesion: $localStorage.identificadorSesion,
                    iOpeCuenta: "OBT",
                    iCtaAlias: "",
                    iCtaNombre: "",
                    iCtaMail: "",
                    iCtaTelefono: "",
                    iCtaCi: "",
                    iCtaNumero: ""
                }
            }

            blockUI();
            //$scope.tableParams={};
            MainFactory.doRequest(parametros)
                .then(function (response) {
                    if (response.data.estado == 0) {
                        $scope.cuentas = response.data.data.oCtaFavorita;

                        $scope.tableParams = new NgTableParams({
                            page: 1,
                            count: 8,
                            sorting: {
                                name: 'ciPrincipal'
                            }
                        }, {
                                counts: [],
                                total: $scope.cuentas.length,
                                getData: function (params) {
                                    var orderedData = params.sorting() ? $filter('orderBy')($scope.cuentas, params.orderBy()) : $scope.cuentas;
                                    return (orderedData.slice((params.page() - 1) * params.count(), params.page() * params.count()));
                                }
                            });
                            
                        //$scope.tableParams.settings().$scope = $scope;
                            $scope.tableParams.reload();
                        unBlockUI();
                    } else {
                        $scope.cuentas = [];
                        showNotification('alerta', response.data.mensaje);
                        unBlockUI();
                    }
                });
        };

        $scope.cargarTabla();

        $scope.cancelar = function(){
            ngDialog.close('editarModal');   
        }

        $scope.agregarCuenta = function () {
            $state.go('app.autogestion.agregarCuenta');
        }

        $scope.editarCuenta = function (cuenta) {
            
            $localStorage.editarCuenta = cuenta;
            //$('#editarCuentaModal').modal('show');
            ngDialog.open({
                template: 'app/cuentas/editar.html',
                className: 'ngdialog-theme-default',
                width: 600, height: 500,
                id: 'editarModal',
                closeByNavigation: true,
                scope: $scope,
                controller: 'CuentasCtrl',
            });
        }

        $scope.eliminarCuenta = function (cuenta) {
            $localStorage.eliminarCuenta = cuenta;
            $scope.eliminar = cuenta;
            //$('#eliminarCuentaModal').modal('show');
            ngDialog.open({
                template: 'app/cuentas/eliminar.html',
                className: 'ngdialog-theme-default',
                width: 600, height: 200,
                id: 'eliminarModal',
                closeByNavigation: true,
                scope: $scope,
                controller: 'CuentasCtrl',
            });
        }

        

        $scope.aceptarEditar = function (form) {
            $scope.datosCuenta = angular.copy($scope.editados);
            if (!form.$valid) {
                return;
            }
            params = {
                operacion: "75",
                producto: "18",
                entidad: "3",
                cliente: "1",
                presentador: "1",
                entrada: {
                    iIdSesion: $localStorage.identificadorSesion,
                    iOpeCuenta: "MOD",
                    iCtaAlias: $scope.editados.alias,
                    iCtaNombre: $scope.editados.nombre,
                    iCtaMail: $scope.editados.mail,
                    iCtaTelefono: $scope.editados.telefono,
                    iCtaCi: $scope.editados.docCredito,
                    iCtaNumero: $scope.editados.cuenta
                }
            }
        
            blockUI();
            MainFactory.doRequest(params)
                .then(function (response) {
                    if (response.data.estado == 0) {
                        $scope.$parent.cargarTabla();
                        showNotification('info', response.data.data.oCtaFavorita[0].observacion);
                        //$('#editarCuentaModal').modal('hide');
                        ngDialog.close('editarModal');
                        unBlockUI();
                    } else {
                        showNotification('alerta', response.data.mensaje);
                        unBlockUI();
                    }
                });
        }

        $scope.cancelarEliminar = function () {
            //$('#eliminarCuentaModal').modal('hide');
            ngDialog.close('eliminarModal');
        }

        $scope.aceptarEliminar = function () {
            params = {
                operacion: "75",
                producto: "18",
                entidad: "3",
                cliente: "1",
                presentador: "1",
                entrada: {
                    iIdSesion: $localStorage.identificadorSesion,
                    iOpeCuenta: "ELI",
                    iCtaAlias: '',
                    iCtaNombre: '',
                    iCtaMail: '',
                    iCtaTelefono: '',
                    iCtaCi: $scope.eliminar.docCredito,
                    iCtaNumero: $scope.eliminar.cuenta
                }
            }

            blockUI();
            MainFactory.doRequest(params)
                .then(function (response) {                    
                    if (response.data.estado == 0) {
                        $scope.$parent.cargarTabla();
                        showNotification('info', 'La cuenta ha sido eliminada');
                        ngDialog.close('eliminarModal');
                        //$('#eliminarCuentaModal').modal('hide');
                        unBlockUI();
                    } else {
                        showNotification('alerta', response.data.mensaje);
                        unBlockUI();
                    }
                });
        }
    }]);

app.controller('AgregarCuentaCtrl', ['$scope', 'MainFactory', '$state', '$sessionStorage', '$localStorage', '$rootScope', '$location', 'NgTableParams', '$filter', 'MenuFactory',
    function ($scope, MainFactory, $state, $sessionStorage, $localStorage, $rootScope, $location, NgTableParams, $filter, MenuFactory) {

        $scope.cuenta = {};

        $scope.limpiarCampos = function () {
            $scope.cuenta = {};
        }

        $scope.confirmar = function (form) {

            if (!form.$valid) {
                return;
            }

            blockUI();
            $localStorage.cuentaFavorita = $scope.cuenta;
            $state.go('app.autogestion.confirmarCuenta');
            unBlockUI();

        }
    }]);

app.controller('ConfirmarCtaFavCtrl', ['$scope', 'MainFactory', '$state', '$sessionStorage', '$localStorage', '$rootScope', '$location', 'NgTableParams', '$filter', 'MenuFactory',
    function ($scope, MainFactory, $state, $sessionStorage, $localStorage, $rootScope, $location, NgTableParams, $filter, MenuFactory) {

        $localStorage.menuActive = 3;
        $scope.confirmacion = $localStorage.cuentaFavorita;

        $scope.cancelar = function () {
            $state.go('app.autogestion.agregarCuenta');
        }

        $scope.aceptar = function () {

            parametros = {
                operacion: "75",
                producto: "18",
                entidad: "3",
                cliente: "1",
                presentador: "1",
                entrada: {
                    iIdSesion: $localStorage.identificadorSesion,
                    iOpeCuenta: "INS",
                    iCtaAlias: $scope.confirmacion.alias,
                    iCtaNombre: $scope.confirmacion.nombre,
                    iCtaMail: $scope.confirmacion.email,
                    iCtaTelefono: $scope.confirmacion.telefono,
                    iCtaCi: $scope.confirmacion.ciRuc,
                    iCtaNumero: $scope.confirmacion.numero
                }
            }

            blockUI();
            MainFactory.doRequest(parametros)
                .then(function (response) {
                    if (response.data.estado == 0) {
                        showNotification('info', response.data.data.oCtaFavorita[0].observacion);
                        $state.go('app.autogestion.cuentas');
                        unBlockUI();
                    } else {
                        showNotification('alerta', response.data.mensaje);
                        $state.go('app.autogestion.cuentas');
                        unBlockUI();
                    }
                });
        }
    }]);