app.controller('DetallesAhorroCtrl', ['$scope', 'MainFactory', '$state', '$sessionStorage', '$localStorage', '$rootScope', '$location', 'NgTableParams', '$filter',
    function ($scope, MainFactory, $state, $sessionStorage, $localStorage, $rootScope, $location, NgTableParams, $filter) {

        $scope.cuentaAhorro = $localStorage.ahorro
        $scope.json = {
            nroProducto: $scope.cuentaAhorro.wbNumero
        };
        $scope.ahorroExtracto;

        blockUI();
        MainFactory.detalleAhorro($scope.json).then(function (response) {
            if (response.data.estado === 0) {
                $scope.ahorroExtracto = response.data.data;

                $scope.tableParams = new NgTableParams({
                    page: 1,
                    count: 8
                }, {
                    counts: [],
                    getData: function (params) {
                        var data = $scope.ahorroExtracto;
                        data = params.filter() ? $filter('filter')(data, params.filter()) : data;
                        data = params.orderBy() ? $filter('orderBy')(data, params.orderBy()) : data;
                        params.total(data.length);
                        data = data.slice((params.page() - 1) * params.count(), params.page() * params.count());
                        return data;
                    }
                });
                unBlockUI();
            } else {
                showNotification('alerta', response.data.mensaje);
                unBlockUI();
            }
        })

    }]);
