app.controller('AltaUsuarioCtrl', ["$scope", "$timeout", "$state", "MainFactory", "$filter", function ($scope, $timeout, $state, MainFactory, $filter) {
    $scope.marker;

    $scope.$on('mapInitialized', function (event, map) {
        $scope.map = map;
    });

    $scope.addMarker = function (event) {
        console.log('hoka ', event.latLng.lat());

        var lat = event.latLng.lat();
        var lng = event.latLng.lng();
        if ($scope.marker) {
            $scope.marker.setMap(null);
        }

        $scope.marker = new google.maps.Marker({
            title: "Mi casa"
        });
        var latlng = new google.maps.LatLng(lat, lng);
        $scope.marker.setPosition(latlng);
        $scope.marker.setMap($scope.map);
    };

    $scope.openModal = function () {
        console.log('openmodal');
        $('#modal-ayuda').modal('show');
    };

    $timeout(function () {
        $('#iFechaNacimiento').datepicker();
        $.datepicker.regional['es'];
    }, 1000);

    var b1 = false;
    var b2 = false;
    var b3 = false;
    var b4 = false;
    var b5 = false;
    var b6 = false;
    var b7 = false;
    var b8 = false;
    var b9 = false;

    $scope.cumple = true;
    $scope.objImg = {};

    blockUI();
    MainFactory.getParametro(1).then(function (response) {
        b1 = true;
        if (b1 && b2 && b3 && b4 && b5 && b6 && b6 && b7 && b8 && b9) {
            unBlockUI();
        }
        $scope.sucursales = response.data.data;
    }, function (response) {
        b1 = true;
        if (b1 && b2 && b3 && b4 && b5 && b6 && b6 && b7 && b8 && b9) {
            unBlockUI();
        }
        showNotification('alerta', "Ha ocurrido un error, intente nuevamente");
    });

    MainFactory.getParametro(2).then(function (response) {
        b2 = true;
        if (b1 && b2 && b3 && b4 && b5 && b6 && b6 && b7 && b8 && b9) {
            unBlockUI();
        }
        $scope.sexos = response.data.data;
    }, function (response) {
        b2 = true;
        if (b1 && b2 && b3 && b4 && b5 && b6 && b6 && b7 && b8 && b9) {
            unBlockUI();
        }
        showNotification('alerta', "Ha ocurrido un error, intente nuevamente");
    });

    MainFactory.getParametro(3).then(function (response) {
        b3 = true;
        if (b1 && b2 && b3 && b4 && b5 && b6 && b6 && b7 && b8 && b9) {
            unBlockUI();
        }
        $scope.nacionalidades = response.data.data;
    }, function (response) {
        b3 = true;
        if (b1 && b2 && b3 && b4 && b5 && b6 && b6 && b7 && b8 && b9) {
            unBlockUI();
        }
        showNotification('alerta', "Ha ocurrido un error, intente nuevamente");
    });

    MainFactory.getParametro(4).then(function (response) {
        b4 = true;
        if (b1 && b2 && b3 && b4 && b5 && b6 && b6 && b7 && b8 && b9) {
            unBlockUI();
        }
        $scope.estadosCiviles = response.data.data;
    }, function (response) {
        b4 = true;
        if (b1 && b2 && b3 && b4 && b5 && b6 && b6 && b7 && b8 && b9) {
            unBlockUI();
        }
        showNotification('alerta', "Ha ocurrido un error, intente nuevamente");
    });

    MainFactory.getParametro(5).then(function (response) {
        b5 = true;
        if (b1 && b2 && b3 && b4 && b5 && b6 && b6 && b7 && b8 && b9) {
            unBlockUI();
        }
        $scope.ciudades = response.data.data;
    }, function (response) {
        b5 = true;
        if (b1 && b2 && b3 && b4 && b5 && b6 && b6 && b7 && b8 && b9) {
            unBlockUI();
        }
        showNotification('alerta', "Ha ocurrido un error, intente nuevamente");
    });

    MainFactory.getParametro(6).then(function (response) {
        b6 = true;
        if (b1 && b2 && b3 && b4 && b5 && b6 && b6 && b7 && b8 && b9) {
            unBlockUI();
        }
        $scope.barriosOri = response.data.data;
    }, function (response) {
        b6 = true;
        if (b1 && b2 && b3 && b4 && b5 && b6 && b6 && b7 && b8 && b9) {
            unBlockUI();
        }
        showNotification('alerta', "Ha ocurrido un error, intente nuevamente");
    });

    MainFactory.getParametro(7).then(function (response) {
        b7 = true;
        if (b1 && b2 && b3 && b4 && b5 && b6 && b6 && b7 && b8 && b9) {
            unBlockUI();
        }
        $scope.profesiones = response.data.data;
    }, function (response) {
        b7 = true;
        if (b1 && b2 && b3 && b4 && b5 && b6 && b6 && b7 && b8 && b9) {
            unBlockUI();
        }
        showNotification('alerta', "Ha ocurrido un error, intente nuevamente");
    });

    MainFactory.getParametro(8).then(function (response) {
        b8 = true;
        if (b1 && b2 && b3 && b4 && b5 && b6 && b6 && b7 && b8 && b9) {
            unBlockUI();
        }
        $scope.ocupaciones = response.data.data;
    }, function (response) {
        b8 = true;
        if (b1 && b2 && b3 && b4 && b5 && b6 && b6 && b7 && b8 && b9) {
            unBlockUI();
        }
        showNotification('alerta', "Ha ocurrido un error, intente nuevamente");
    });

    MainFactory.getParametro(9).then(function (response) {
        b9 = true;
        if (b1 && b2 && b3 && b4 && b5 && b6 && b6 && b7 && b8 && b9) {
            unBlockUI();
        }
        $scope.grados = response.data.data;
    }, function (response) {
        b9 = true;
        if (b1 && b2 && b3 && b4 && b5 && b6 && b6 && b7 && b8 && b9) {
            unBlockUI();
        }
        showNotification('alerta', "Ha ocurrido un error, intente nuevamente");
    });

    function validateEmail(email) {
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    $scope.confirmar = function () {
        if (!$scope.marker) {
            showNotification('alerta', "No se pudo determinar su ubicación, inténtelo nuevamente");
            return;
        }
        if (!$scope.data.iCedula || !$scope.data.iNombre || !$scope.data.iApellido ||
                !$scope.data.iSexo || !$scope.data.iEstadoCivil ||
                !$scope.data.iFechaNacimiento ||
                !$scope.data.iNacionalidad || !$scope.data.iDireccion ||
                !$scope.data.iCiudad || !$scope.data.iBarrio || !$scope.data.iProfesion ||
                !$scope.data.iOcupacion || !$scope.data.iInstruccion ||
                !$scope.data.iCelular || !$scope.data.iMail || !$scope.data.iSucursal
                || !$scope.data.iRefNombre1 || !$scope.data.iRefNombre2
                || !$scope.data.iRefCelular1 || !$scope.data.iRefCelular2) {
            showNotification('alerta', 'Datos incompletos');
        } else {
            if (!validateEmail($scope.data.iMail)) {
                showNotification('alerta', 'El correo ingresado es incorrecto');
                return;
            } else {
                var lat = $scope.marker.position.lat();
                var lon = $scope.marker.position.lng();
                if (lat && lon) {
                    console.log(lat);
                    console.log(lon);
                    $scope.data.iDirLatitud = lat;
                    $scope.data.iDirLongitud = lon;

                    $scope.data.canal = 1;
                    if ($scope.objImg.ciFrente) {
                        $scope.data.ciFrente = $scope.objImg.ciFrente.base64;
                    } else {
                        showNotification('alerta', 'Debe enviar la foto del frente de su Cédula');
                        return;
                    }
                    if ($scope.objImg.ciDorso) {
                        $scope.data.ciDorso = $scope.objImg.ciDorso.base64;
                    } else {
                        showNotification('alerta', 'Debe enviar la foto del dorso de su Cédula');
                        return;
                    }
                    if ($scope.objImg.facturaServicio) {
                        $scope.data.facturaServicio = $scope.objImg.facturaServicio.base64;
                    } else {
                        showNotification('alerta', 'Debe enviar la foto de su factura de servicio básico');
                        return;
                    }
                    blockUI();
                    MainFactory.alta($scope.data).then(function (response) {
                        $scope.data = {};
                        delete $scope.iFechaNacimientoD;
                        unBlockUI();
                        if (response.data.estado == 0) {
                            showNotification('info', response.data.mensaje);
                        } else {
                            showNotification('alerta', response.data.mensaje);
                        }
                    }, function (response) {
                        unBlockUI();
                        showNotification('alerta', "Lo sentimos su operación no pudo ser procesada, inténtelo nuevamente");
                    });
                } else {
                    showNotification('alerta', "No se pudo determinar su ubicación, inténtelo nuevamente");
                }
            }
        }

    };

    $scope.verificaCedula = function () {
        console.log('verifica cedula ' + $scope.data.iCedula);
        blockUI();
        var json = {};
        json.iCedula = $scope.data.iCedula;
        MainFactory.verifica(json).then(function (response) {
            unBlockUI();
            if (response.data.estado != 0) {
                showNotification('alerta', response.data.mensaje);
                $scope.cumple = false;
            } else {
                $scope.cumple = true;
            }
        }, function (response) {
            unBlockUI();
            showNotification('alerta', "Lo sentimos su operación no pudo ser procesada, inténtelo nuevamente");
            $scope.cumple = false;
        });
    };
}]);