app.controller('AhorroProgramadoPagoCtrl', ['$scope', 'MainFactory', '$state', '$sessionStorage', '$localStorage', '$rootScope', '$location', 'MenuFactory', 'NgTableParams', '$filter',
    function ($scope, MainFactory, $state, $sessionStorage, $localStorage, $rootScope, $location, MenuFactory, NgTableParams, $filter) {

        $scope.cajasAhorro = [];
        $scope.datos = {};
        $scope.datos.cuenta = {};
        $scope.datos.cuentaAhoProg = {};
        $scope.datos.monto = "";
        $scope.nroSocio = $localStorage.datosUsuario.nroSocio;
        $scope.cuentasAhorProg;

        var cuentasAhorro = {
            operacion: "73",
            producto: "18",
            entidad: "3",
            cliente: "1",
            presentador: "1",
            entrada: {
                iIdSesion: $localStorage.identificadorSesion,
                iTipSaldo: "AHR"
            }
        };

        blockUI();
        MainFactory.doRequest(cuentasAhorro)
            .then(function (response) {

                if (response.data.estado == 0) {
                    $scope.cajasAhorro = response.data.data.oDatProducto;
                    $scope.cajasAhorro.forEach(element => {
                        if (element.wbCategoria == 3) {
                            $scope.cuentasAhorProg = true;
                        }
                    });
                    if (!$scope.cuentasAhorProg) {
                        $scope.cuentasAhorProg = false;
                    }
                } else {
                    showNotification('alerta', response.data.mensaje);
                }
                unBlockUI();
            },
                function (response) {
                    unBlockUI();
                    showNotification('alerta', "No se pudo obtener Cuentas de Ahorro");
                });

        $scope.obtPagAhrProg = function () {
            if (!$scope.datos.cuentaAhoProg) {
                $scope.datos.monto = "";
            } else {

                $scope.datos.monto = number_format($scope.datos.cuentaAhoProg.wbMtoCuota);
            }
        };

        $scope.limpiarCampos = function () {
            $scope.datos.cuenta = {};
            $scope.datos.cuentaAhoProg = {};
            $scope.datos.monto = {};
        };

        $scope.confirmar = function (form) {
            if (!form.$valid) {
                return;
            }

            blockUI();
            $scope.pagoAhoProg = {};
            $scope.pagoAhoProg.cuenta = $scope.datos.cuenta.wbNumero;
            $scope.pagoAhoProg.nroCuenta = $scope.nroSocio + '-' + $scope.datos.cuenta.wbCodigo;
            $scope.pagoAhoProg.monto = formatoMonto($scope.datos.monto);
            $scope.pagoAhoProg.cuentaSaldo = $scope.datos.cuenta.wbMtoActual;
            $scope.pagoAhoProg.cuentaDestino = $scope.datos.cuentaAhoProg.wbNumero;
            $scope.pagoAhoProg.fecha = getFecha();
            $scope.pagoAhoProg.nombre = $localStorage.datosUsuario.nombre;
            $localStorage.pagoAhoProg = $scope.pagoAhoProg;
            unBlockUI();
            $state.go('app.pagos.confirmacionPagoAhoProg');
        };
    }]);

app.controller('ConfirmacionPagoAhoProgCtrl', ['$scope', 'MainFactory', '$state', '$sessionStorage', '$localStorage', '$rootScope', '$location',
    function ($scope, MainFactory, $state, $sessionStorage, $localStorage, $rootScope, $location) {

        $scope.confirmacion = $localStorage.pagoAhoProg;
        $scope.pin;
        $scope.tipo;

        $scope.setTipoVerificacion = function (tipo) {
            $scope.pin = '';
            $scope.tipo = tipo;
        }

        $scope.verificarOtp = function () {
            blockUI();
            MainFactory.verifyOtp().then(
                function (response) {
                    if (response.data.estado == 0) {
                        $scope.pagarAhorro();
                    } else {
                        unBlockUI();
                        showNotification('alerta', response.data.mensaje);
                    }
                },
                function (response) {
                    unBlockUI();
                    showNotification('alerta', "No se pudo realizar el pago");
                });
        }

        $scope.pagar = function (form) {
            if (!form.$valid) {
                return;
            }

            if ($scope.tipo) {
                if ($scope.pin == '') {
                    showNotification('alerta', 'Ingrese su código de verificación');
                } else {
                    if ($scope.tipo == "PIN") {
                        $scope.pagarAhorro();
                    } else {
                        $scope.verificarOtp();
                    }
                }
            } else {
                showNotification('alerta', 'Seleccione un método de verificación');
            }
        }

        $scope.pagarAhorro = function () {
            blockUI();
            if ($scope.confirmacion.monto > $scope.confirmacion.cuentaSaldo) {
                showNotification('alerta', 'Saldo Insuficiente para completar la transacción');
                unBlockUI();
                $state.go('app.pagos.ahorroProgramado');
            } else {
                var parametros = {
                    operacion: "79",
                    producto: "18",
                    entidad: "3",
                    cliente: "1",
                    presentador: "1",
                    entrada: {
                        iIdSesion: $localStorage.identificadorSesion,
                        iPrdPago: "AHR",
                        iCtaDebito: $scope.confirmacion.cuenta,
                        iNroProducto: $scope.confirmacion.cuentaDestino,
                        iMtoPago: $scope.confirmacion.monto,
                        iCanCuota: null,
                        tipoAuth: {
                            tipo: $scope.tipo,
                            valor: $scope.pin
                        },
                        idSocio: $localStorage.usuario,
                        iCiOtrSocio: null
                    }
                }

                MainFactory.doRequest(parametros).then(
                    function (response) {
                        if (response.data.estado == 0) {
                            unBlockUI();
                            $localStorage.comprobante = response.data.data.oMovPago[0];
                            $localStorage.comprobante.datos = $scope.confirmacion;
                            $state.go('app.pagos.comprobante');
                        } else {
                            unBlockUI();
                            $state.go('app.pagos.ahorroProgramado');
                            showNotification('alerta', response.data.mensaje);
                        }
                    },
                    function (response) {
                        unBlockUI();
                        showNotification('alerta', "No se pudo realizar el pago");
                    });
            }
        }

        $scope.cancelar = function () {
            $state.go('app.pagos.ahorroProgramado');
        }
    }]);

app.controller('ComprobantePagoAhoProgCtrl', ['$scope', '$state', '$sessionStorage', '$localStorage', '$rootScope', '$location', 'MainFactory',
    function ($scope, $state, $sessionStorage, $localStorage, $rootScope, $location, MainFactory) {

        $scope.comprobante = $localStorage.comprobante;

        $scope.aceptar = function () {
            $state.go('app.pagos.ahorroProgramado');
        }

        $scope.descargarPdf = function () {

            var json = {
                "pagComprobante": $scope.comprobante.pagComprobante,
                "fecha": $scope.comprobante.fecha,
                "concepto": $scope.comprobante.concepto,
                "nombre": $scope.comprobante.datos.nombre,
                "nroCuenta": $scope.comprobante.datos.nroCuenta,
                "monto": $scope.comprobante.datos.monto,
                "cuentaDestino": $scope.comprobante.datos.cuentaDestino,
                "beneficiario": $scope.comprobante.datos.beneficiario,
                "tarjeta": $scope.comprobante.datos.tarjeta,
                "prestamo": $scope.comprobante.datos.prestamo,
                "nroOperacion": $scope.comprobante.nroOperacion,
                "fecOperacion": $scope.comprobante.fecOperacion,
                "socio": $scope.comprobante.socio,
                "cuentaOrigenDescripcion": $scope.comprobante.cuentaOrigenDescripcion,
                "importe": $scope.comprobante.importe,
                "cuentaDestinoDescripcion": $scope.comprobante.cuentaDestinoDescripcion
            }

            var parametros = {
                url: "/SwitcherSanCristobal-web/comprobante",
                method: "POST",
                data: json
            };

            blockUI();
            MainFactory.getPdf(parametros).then(
                function (response) {
                    if (response.data.estado == 0) {
                        window.location.replace('/SwitcherSanCristobal-web/comprobante');
                        unBlockUI();
                    } else {
                        showNotification('alerta', response.data.mensaje);
                        unBlockUI();
                    }
                },
                function (response) {
                    unBlockUI();
                    $scope.mensaje = "Error en la operacion.";
                });
        };
    }]);