app.controller('ComprobantesImpresionCtrl', ['$scope', 'MainFactory', '$state', '$sessionStorage', '$localStorage', '$rootScope', '$location', 'NgTableParams', '$filter', 'MenuFactory',
    function ($scope, MainFactory, $state, $sessionStorage, $localStorage, $rootScope, $location, NgTableParams, $filter, MenuFactory) {


        $scope.mensajeFactura = null;
        $scope.comprobantes;
        $scope.comprobantesDescargarDet = {};
        $scope.comprobantesDescargaCab = {};
        $scope.fechas = generarFechas();
        $scope.selected = {};
        $scope.selected.fecha = $scope.fechas[0];

        $scope.operaciones = [];

        $scope.operaciones.push({
            'tipo': 'PAG',
            'descripcion': 'Pagos'
        },
        {
            'tipo': 'TRA',
            'descripcion': 'Transferencia'
        },
        {
            'tipo': 'SIP',
            'descripcion': 'Transferencia Externa SIPAP'
        });

        $scope.selected.tipo = $scope.operaciones[0];

        $scope.obtenerFechaInicio = function (fecha) {
            return '01' + '/' + getMesExtracto(fecha) + '/' + getAnhoExtracto(fecha);
        };

        $scope.obtenerFechaFin = function (fecha) {
            today = new Date();
            mes = today.getMonth() + 1;
            if (mes == getMesExtracto(fecha)) {
                return today.getDate() + '/' + getMesExtracto(fecha) + '/' + getAnhoExtracto(fecha);
            } else {
                day = new Date(getAnhoExtracto(fecha), getMesExtracto(fecha), 0);
                return String(day).substring(8, 10) + '/' + getMesExtracto(fecha) + '/' + getAnhoExtracto(fecha);
            }
        };

        $scope.consultar = function () {

            parametros = {
                "operacion": "90",
                "producto": "18",
                "entidad": "3",
                "cliente": "1",
                "presentador": "1",
                "entrada": {
                    "iIdSesion": $localStorage.identificadorSesion,
                    "iObtLista": $scope.selected.tipo.tipo,
                    "iFecInicio": $scope.obtenerFechaInicio($scope.selected.fecha.mes),
                    "iFecFin": $scope.obtenerFechaFin($scope.selected.fecha.mes),
                    "iCantidad": 20,
                    "iRegInicial": 1
                }
            };

            blockUI();
            MainFactory.doRequest(parametros)
                .then(function (response) {
                    if (response.data.estado == 0) {
                        $scope.comprobantes = response.data.data.oLisOperaciones;
                        if ($scope.comprobantes.length == 0) {
                            $scope.mensaje = "No dispone de comprobantes en la fecha seleccionada";
                        }
                        $scope.tableParams = new NgTableParams({
                            page: 1,
                            count: 8
                        }, {
                                counts: [],
                                getData: function (params) {
                                    var data = $scope.comprobantes;
                                    data = params.filter() ? $filter('filter')(data, params.filter()) : data;
                                    data = params.orderBy() ? $filter('orderBy')(data, params.orderBy()) : data;
                                    params.total(data.length);
                                    data = data.slice((params.page() - 1) * params.count(), params.page() * params.count());
                                    return data;
                                }
                            });
                        unBlockUI();
                    } else {
                        unBlockUI();
                        //showNotification('alerta', response.data.mensaje);
                        $scope.mensaje = response.data.mensaje;
                    }
                });
        };

        $scope.consultar();

        $scope.update = function () {
            $scope.consultar();
        };

        $scope.descargar = function (comprobante) {
            parametrosImp = {
                "operacion": "91",
                "producto": "18",
                "entidad": "3",
                "cliente": "1",
                "presentador": "1",
                "entrada": {
                    "iIdSesion": $localStorage.identificadorSesion,
                    "iOpeNumero": comprobante.nroOperacion,
                    "iOpeFechaD": comprobante.fecha
                }
            };
              
            blockUI();
            MainFactory.doRequest(parametrosImp)
                .then(function (response) {
                    if (response.data.estado == 0) {
                        var parametrosDescar = {
                            url: "/SwitcherSanCristobal-web/ReimpresionServlet",
                            method: "POST",
                            //data: response.data.data.opeDatos[0]
                        };
                        if(response.data.data.opeDatos[0].trxNetel){
                            response.data.data.opeDatos[0].ticketNetel= '' + formatTicketComp(response.data.data.opeDatos[0].ticketNetel)
                        }
                        parametrosDescar.data= response.data.data.opeDatos[0];

                        MainFactory.getPdf(parametrosDescar).then(function (response) {
                            if (response.data.estado == 0) {
                                window.location.replace('/SwitcherSanCristobal-web/ReimpresionServlet');
                                unBlockUI();
                            } else {
                                unBlockUI();
                                showNotification('alerta', response.data.mensaje);
                            }
                        }, function (response) {
                            unBlockUI();
                            showNotification('alerta', "Error en la operacion.");
                        });
                    } else {
                        unBlockUI();
                        showNotification('alerta', response.data.mensaje);
                    }
                }, function (response) {
                    unBlockUI();
                    showNotification('alerta', "Error en la operacion.");
                });
        }


        $scope.descargarComprobanteSipap = function (comprobante) {   
            blockUI();
            let parametros = comprobante;
            parametros.fecha = formatoFechaLong(comprobante.fecha)
            MainFactory.getComprobanteSipap(parametros)
                .then(function (response) {
                    if (response.data.estado == 0) {
                        window.location.replace('/SwitcherSanCristobal-web/ComprobanteSipap');
                        unBlockUI();
                    } else {
                        unBlockUI();
                        showNotification('alerta', response.data.mensaje);
                    }
                }, function (response) {
                    unBlockUI();
                    showNotification('alerta', "Error en la operacion.");
                });
        }
    }]);