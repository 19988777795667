app.controller('RuedaAhorro', ['$scope', '$localStorage', 'MainFactory', '$state',
    function ($scope, $localStorage, MainFactory, $state) {

        $scope.ruedaAhorro = [
            // {
            //     codigo: 2072,
            //     nombreCirculo: "CIRCULO 100.000 36 M",
            //     plazo: 36,
            //     cuota: 100000,
            //     premio: 5000000
            // },
            // {
            //     codigo: 2073,
            //     nombreCirculo: "CIRCULO 200.000 18 M",
            //     plazo: 18,
            //     cuota: 100000,
            //     premio: 3000000
            // },
            // {
            //     codigo: 2074,
            //     nombreCirculo: "CIRCULO 300.000 24 M",
            //     plazo: 24,
            //     cuota: 100000,
            //     premio: 7000000
            // }
        ];
        $scope.datos = {};
        $scope.nroSocio = $localStorage.datosUsuario.nroSocio;
        $scope.cajasAhorro = [];
        $scope.mostrarContrato = false;
        $scope.nroContratoList = [];
        $scope.ruedaAhorroSelected = {};
        $scope.nroContratoSelected = {};

        var cuentasAhorro = {
            operacion: "73",
            producto: "18",
            entidad: "3",
            cliente: "1",
            presentador: "1",
            entrada: {
                iIdSesion: $localStorage.identificadorSesion,
                iTipSaldo: "AHR"
            }
        };

        blockUI();
        MainFactory.doRequest(cuentasAhorro).then(function (response) {
                console.log("resp", response);
                if (response.data.estado === 0) {
                    $scope.cajasAhorro = response.data.data.oDatProducto;
                } else {
                    showNotification('alerta', response.data.mensaje);
                }
                unBlockUI();
            },
            function (response) {
                unBlockUI();
                showNotification('alerta', "No se pudo obtener Cuentas de Ahorro");
            });

        blockUI();
        MainFactory.obtenerRuedasAhorro().then(function (response) {
            if (response.data.estado === 0) {
                $scope.ruedaAhorro = response.data.data;
                console.log($scope.ruedaAhorro);
            } else {
                showNotification('alerta', response.data.mensaje);
            }
            unBlockUI();
        });

        $scope.checkPlan = function (value) {
            if (value !== null) {
                $scope.mostrarContrato = true;
                $scope.ruedaAhorroSelected = value;

                let json = {
                    idProducto: value.codigo
                }

                blockUI();
                MainFactory.obtenerAhorroDisp(json).then(function (response) {
                    if (response.data.estado === 0) {
                        $scope.nroContratoList = response.data.data;
                        console.log($scope.nroContratoList);
                    } else {
                        showNotification('obtenerAhorroDisp', response.data.mensaje);
                    }
                    unBlockUI();
                })
            } else if (value == 'N') {
                $scope.mostrarContrato = false;
            }
        }

        $scope.onChangeNroContrato = function (value) {
            $scope.nroContratoSelected = value;
        }

        $scope.confirmar = function (form) {
            if (!form.$valid) {
                return;
            }

            blockUI();
            if ($scope.datos.cuenta && $scope.ruedaAhorroSelected && $scope.nroContratoSelected && $scope.mostrarContrato === true) {
                const request = {
                    "idSession": $localStorage.identificadorSesion,
                    "ctaDebito": $scope.datos.cuenta.wbNumero,
                    "idProducto": $scope.ruedaAhorroSelected.codigo,
                    "plazo": $scope.ruedaAhorroSelected.plazo,
                    "nroContrato": $scope.nroContratoSelected.numero,
                    "mtoPago": $scope.ruedaAhorroSelected.cuota
                };

                const datosConfirmacion = {
                    ruedaAhorro: $scope.ruedaAhorroSelected,
                    nroContrato: $scope.nroContratoSelected,
                    ctaDebito: $scope.datos.cuenta,
                    datos: $scope.datos,
                };

                $localStorage.datosConfirmacion = datosConfirmacion;
                $localStorage.datosRequest = request;
                console.log({request: request, datosConfirmacion: datosConfirmacion});

                $state.go('app.ruedaAhorroConfirmacion');
            } else {
                unBlockUI();
                showNotification('alerta', "Seleccione todos los campos");
            }
        }

        $scope.limpiarCampos = function () {
            $scope.mostrarContrato = false;
            $scope.ruedaAhorroSelected = {};
            $scope.datos = {};
            $scope.nroContratoList = [];
            $scope.nroContratoSelected = {};
        };

    }]
);