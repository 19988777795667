app.controller('TransferenciaExternaEstadoCtrl', ['$scope', 'TransferenciaExternaFactory', '$timeout', '$localStorage', 'NgTableParams', '$filter',
    function ($scope, TransferenciaExternaFactory, $timeout, $localStorage, NgTableParams, $filter) {

        $scope.datos = {};
        $scope.fechas = generarFechas();
        $scope.selected = {};
        $scope.selected.fecha = $scope.fechas[0];
        $localStorage.menuActive = 2;

        // lista los estados     
        blockUI();
        TransferenciaExternaFactory.listarValores(3)
            .then(function (response) {
                if (response.data.estado == 0) {
                    $scope.datos.estados = response.data.data;
                    $scope.datos.estados.push({
                        "descripcion": "Todos",
                        "identifica": "Todos"
                    });
                }
                unBlockUI();
            },
                function (response) {
                    unBlockUI();
                    showNotification('alerta', "No se pudo obtener los estados");
                });

        $scope.obtenerListado = function () {
            blockUI();
            $scope.datos.lista = null;
            $scope.datos.listadoCompleto = null;
            $scope.datos.mensaje = null;
            var year = getAnhoExtracto($scope.selected.fecha.mes);
            var month = getMesExtracto($scope.selected.fecha.mes);
            let params = {
                fechaInicio: '01/' + month + '/' + year,
                fechaFin: getLastDay(year, month) + "/" + month + '/' + year
            }
            TransferenciaExternaFactory.listarSipap(params)
                .then(function (response) {
                    if (response.data.estado == 0) {
                        $scope.selected.estado = $scope.datos.estados[$scope.datos.estados.length - 1];
                        $scope.datos.lista = response.data.data.listOperaciones;
                        $scope.datos.listadoCompleto = response.data.data.listOperaciones;
                        $scope.tableParams = new NgTableParams({
                            page: 1,
                            count: 8
                        }, {
                            counts: [],
                            getData: function (params) {
                                var data = $scope.datos.lista;
                                data = params.filter() ? $filter('filter')(data, params.filter()) : data;
                                data = params.orderBy() ? $filter('orderBy')(data, params.orderBy()) : data;
                                params.total(data.length);
                                data = data.slice((params.page() - 1) * params.count(), params.page() * params.count());
                                return data;
                            }
                        });
                        unBlockUI();
                    } else {
                        unBlockUI();
                        $scope.datos.mensaje = response.data.mensaje;
                    }
                },
                    function (response) {
                        unBlockUI();
                        showNotification('alerta', "No se pudo obtener los estados de las transferencias");
                    });
        }

        $scope.obtenerListado();

        $scope.updateEstado = function () {
            if ($scope.datos.listadoCompleto) {
                blockUI();
                if ($scope.selected.estado.descripcion != "Todos") {
                    $scope.datos.lista = $scope.datos.listadoCompleto.filter(filtrarListadoSipap($scope.selected.estado.descripcion));
                } else {
                    $scope.datos.lista = $scope.datos.listadoCompleto;
                }
                $scope.tableParams = new NgTableParams({
                    page: 1,
                    count: 8
                }, {
                    counts: [],
                    getData: function (params) {
                        var data = $scope.datos.lista;
                        data = params.filter() ? $filter('filter')(data, params.filter()) : data;
                        data = params.orderBy() ? $filter('orderBy')(data, params.orderBy()) : data;
                        params.total(data.length);
                        data = data.slice((params.page() - 1) * params.count(), params.page() * params.count());
                        return data;
                    }
                });
                unBlockUI();
            }
        }

        $scope.update = function () {
            $scope.obtenerListado();
        }

    }]);