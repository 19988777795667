app.controller('MenuCtrl', ['$scope', 'MainFactory', '$state', '$localStorage', 'Idle',
    function ($scope, MainFactory, $state, $localStorage, Idle) {

        $scope.isCollapsed = true;

        $scope.collapse = function () {
            $scope.isCollapsed = !$scope.isCollapsed;
            if ($scope.isCollapsed) {
                $("#el-nav").show();
            } else {
                $("#el-nav").hide();
            }
        };

        $scope.collapse();

        Idle.watch();
        $scope.$on('IdleStart', function () {
            showNotification('alerta', "Su sesi&oacute;n ha expirado!");
            $scope.salir();
        });

        $scope.datos = $localStorage.datosUsuario;

        $scope.fecha = getDate();

        $scope.mensajes = {};
        $scope.mensajes.inicio = "Accede a la página Inicial, aquí encontrarás el resumen de todos tus productos";
        $scope.mensajes.consulta = "En esta sección podrás realizar consultas de estados sobre tus productos";
        $scope.mensajes.consultaApo = "Consulta el estado y el extracto de tus movimientos de aporte";
        $scope.mensajes.consultaSol = "Consulta el estado y el extracto de tus movimientos de solidaridad";
        $scope.mensajes.consultaAhr = "Consulta el saldo de tu caja de ahorro";
        $scope.mensajes.consultaTcr = "Consulta las operaciones de tu tarjeta de crédito";
        $scope.mensajes.consultaPmo = "Consulta el movimiento de tu préstamo";
        $scope.mensajes.pago = "En esta sección podrás realizar pagos de tus productos";
        $scope.mensajes.pagoApo = "Ponete al día con tu aporte";
        $scope.mensajes.pagoSol = "Ponete al día con tu solidaridad";
        $scope.mensajes.pagoTcr = "Ponete al día con tu tarjeta de crédito";
        $scope.mensajes.pagoPmo = "Ponete al día con tu préstamo";
        $scope.mensajes.pagoAhr = "Ponete al día con tu ahorro programado";
        $scope.mensajes.pagoSer = "En esta sección podrás realizar pagos de servicios públicos y privados";
        $scope.mensajes.pagoRAr = "Ponete al día con tu rueda de ahorro";
        $scope.mensajes.transferencia = "En esta sección podrás realizar transferencias de dinero y ver tu historial de transferencias";
        $scope.mensajes.transferenciaRea = "En esta sección podrás realizar transferencias de dinero entre cajas de ahorros  propias o de otros socios";
        $scope.mensajes.transferenciaExterna = "En esta sección podrás realizar transferencias a otras entidades bancarias";
        $scope.mensajes.transferenciaHist = "En esta sección podrás ver tu historial de transferencias";
        $scope.mensajes.transferenciaExternaEstado = "En esta sección podrás ver el estado de tus transferencias externas";
        $scope.mensajes.autogestion = "Esta sección te permite gestionar tus cuentas favoritas, tus datos personales y cambiar tu contraseña de acceso";
        $scope.mensajes.autogestionCtaFav = "Agrega, modifica o elimina cuentas favoritas con quienes operas con frecuencia";
        $scope.mensajes.autogestionDatos = "Actualiza tus datos personales";
        $scope.mensajes.autogestionCambiarPass = "Modifica la contraseña (PIN) de ingreso al sitio";
        $scope.mensajes.salir = "Cierra tu sesión";
        $scope.mensajes.ahorroProgramado = "Pago de Ahorro Programado";
        $scope.mensajes.impresiones = "En esta sección puede imprimir sus comprobantes";
        $scope.mensajes.facturasImpresion = "Impresión de Facturas";
        $scope.mensajes.comprobantesImpresion = "Impresión de Comprobantes";
        $scope.mensajes.solicitudes = "Esta sección podrás solicitar los distintos productos de la Cooperativa";
        $scope.mensajes.solicitudesVer = "Visualiza el estado de tus solicitudes";
        $scope.mensajes.ruedaAhorro = "Esta sección podrás crear una Rueda Ahorro"
        $scope.mensajes.ahorro = "Esta sección podrás crear distintos ahorros en la Cooperativa";
        $scope.mensajes.simuladorAhorro = "En esta sección podrás realizar una simulación de Ahorros";
        $scope.mensajes.manifestacionBienes = "En esta sección podrás actualizar tu Manifestación de Bienes";
        $scope.mensajes.ahorroProgramado = "En esta sección podrás crear un nuevo Ahorro Programado";
        $scope.mensajes.otrosPagos = "Ponete al día con tus otros pagos";

        $scope.padronVisible = false;
        $scope.ruedaAhorroVisible = false;
        try {
            setTimeout(function () {
                $scope.padronVisible = $localStorage.datosPadron.data.visible === 'S';
                $scope.ruedaAhorroVisible = $localStorage.habilitacionCirculo.data.mensaje === 'S'
            }, 200);
        } catch (err) {
            console.log(err);
        }

        $scope.salir = function () {

            parametros = {
                "operacion": "76",
                "producto": "8",
                "entidad": "3",
                "cliente": "1",
                "presentador": "1",
                "entrada": {
                    "iIdSesion": $localStorage.identificadorSesion
                }
            };

            blockUI();
            MainFactory.doRequest(parametros)
                .then(function (response) {
                    $state.go('login');
                    localStorage.clear();
                    sessionStorage.clear();
                    unBlockUI();
                });
        };

        $scope.verBalance = function (){
                        $localStorage.url = '/SwitcherSanCristobal-web/webresources/memorias-balances';
                        $localStorage.urlRetorno = 'app.inicio';
                        $localStorage.titulo = 'MEMORIAS Y BALANCES';
                        console.log("DESCARGAR MENU");
                        $state.go('app.ver-pdf-pdfjs');
        }
    }]);
