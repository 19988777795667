app.controller('SolidaridadCtrl', ['$scope', '$state', '$sessionStorage','$localStorage', '$rootScope', '$location', 'MainFactory', 'NgTableParams', '$filter', 'MenuFactory',
    function ($scope, $state, $sessionStorage,$localStorage, $rootScope, $location, MainFactory, NgTableParams, $filter, MenuFactory) {

    $scope.solidaridad;
    $localStorage.menuActive= 1;
    
    parametros= {
            operacion: "73",
            producto: "18",
            entidad: "3",
            cliente: "1",
            presentador: "1",
            entrada: {
                iIdSesion: $localStorage.identificadorSesion,
                iTipSaldo : "SOL"
                    }
    };

    blockUI();
    MainFactory.doRequest(parametros)
           .then(function (response) {

        if (response.data.estado == 0) {

            $scope.solidaridad= response.data.data.oDatProducto;

            $scope.tableParams = new NgTableParams({
                page: 1,
                count: 10,
                sorting: {
                name: 'tipo'
                                 }
            }, {
                    counts:[],
                    total: $scope.solidaridad.length,
                    getData: function (params) {
                    var orderedData = params.sorting() ? $filter('orderBy')($scope.solidaridad, params.orderBy()) : $scope.solidaridad;
                    return (orderedData.slice((params.page() - 1) * params.count(), params.page() * params.count()));
            }
            });
            unBlockUI();
        }else{
            
            showNotification('alerta', response.data.mensaje);
            unBlockUI();
        }
    });

    $scope.irExtractoSolidaridad = function (dato) {
        $localStorage.cabeceraSol = dato;
        $state.go('app.consultas.solidaridadExtracto');
        $rootScope.options = MenuFactory.active($localStorage.menuActive);
    };

    $scope.irPagoSolidaridad = function (dato) {
        $localStorage.cabSolidaridad = dato;
        $rootScope.options = MenuFactory.active(4);
        $state.go('app.pagos.solidaridad');
    };

 }]);