app.controller('TarjetaCtrl', ['$scope', 'MainFactory', '$state', '$sessionStorage', '$localStorage', '$rootScope', '$location', 'NgTableParams', '$filter', 'MenuFactory',
    function ($scope, MainFactory, $state, $sessionStorage, $localStorage, $rootScope, $location, NgTableParams, $filter, MenuFactory) {

        $scope.tarjetas;
        $localStorage.menuActive = 1;

        parametros = {
            operacion: "73",
            producto: "18",
            entidad: "3",
            cliente: "1",
            presentador: "1",
            entrada: {
                iIdSesion: $localStorage.identificadorSesion,
                iTipSaldo: "TCR"
            }
        };

        blockUI();
        MainFactory.doRequest(parametros)
                .then(function (response) {

                    if (response.data.estado == 0) {

                        $scope.tarjetas = response.data.data.oDatProducto;

                        $scope.tableParams = new NgTableParams({
                            page: 1,
                            count: 10,
                            sorting: {
                                name: 'tipo'
                            }
                        }, {
                            counts: [],
                            total: $scope.tarjetas.length,
                            getData: function (params) {
                                var orderedData = params.sorting() ? $filter('orderBy')($scope.tarjetas, params.orderBy()) : $scope.tarjetas;
                                return (orderedData.slice((params.page() - 1) * params.count(), params.page() * params.count()));
                            }
                        });
                        unBlockUI();
                    } else {

                        showNotification('alerta', response.data.mensaje);
                        unBlockUI();
                    }
                });

        $scope.verExtractoTarjeta = function (tarjeta) {
            $localStorage.nroTarjeta = tarjeta;
            $state.go('app.consultas.tarjetaExtracto');
            $rootScope.options = MenuFactory.active($localStorage.menuActive);
        };
        
        $scope.verExtractoTarjetaCerrado = function (tarjeta) {
            $localStorage.nroTarjeta = tarjeta;
            $state.go('app.consultas.tarjetaExtractoCerrado');
            $rootScope.options = MenuFactory.active($localStorage.menuActive);
        };

        $scope.irPagoTarjeta = function (tarjeta) {
            $localStorage.cabTarjeta = tarjeta;
            $rootScope.options = MenuFactory.active(4);
            $state.go('app.pagos.tarjetas');
        };

    }]);